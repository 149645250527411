import {Component, Input, OnInit, AfterViewInit, Output, OnDestroy} from '@angular/core';
import {PagerDataObject} from "../../entities/pagerDataObject";
import { Subject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { CompanyService } from 'src/app/services/company.service';
import { Company } from 'src/app/entities/company';
import { IndicatorService } from 'src/app/services/indicator.service';
import { IndicatorSector } from 'src/app/entities/indicatorSector';
import { IndicatorSectorService } from 'src/app/services/indicatorSector.service';
import { SharedService } from 'src/app/services/sharedService';

@Component({
  selector: 'indicator-sectors',
  templateUrl: './indicator-sectors.component.html',
  styleUrls: ['./indicator-sectors.component.css']
})
export class IndicatorSectorsComponent implements OnInit, AfterViewInit {

 
  metaInfo: string[];
  columns: Array<any>;
  data: PagerDataObject;
  @Input()
  headers = [ 'Name', 'Description'];
  @Input()
  disableHeader = false;
  subscription: any;
  private dataSub$ = new Subject<any>();
  query: String;

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
    this.sharedService.loadMenuItems('admin');
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  constructor(private indicatorSectorService: IndicatorSectorService, private sharedService: SharedService) {
    this.subscription = this.dataSub$.pipe(switchMap(filter => {
      return this.indicatorSectorService.getData(filter);
    }))
      .subscribe(data => {
        this.data = data;
      });
    this.metaInfo = this.indicatorSectorService.getMetaInfo();
    this.columns = this.indicatorSectorService.getColumns();
  }

  onGridQuery(query) {
    this.query = query
    this.dataSub$.next(query);
  }

  deleteUser(entity){
    // let company = new Company();
    // company.id = entity;
    // this.indicatorSectorService.delete(company)
    //   .subscribe(service => {
    //     this.dataSub$.next(this.query);
    //   });
  }
  deleteIndicatorSector(event){

  }
}
