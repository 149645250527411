import {Component, Input, OnInit} from '@angular/core';
import { ProcessService } from 'src/app/services/process.service';
import { saveAs } from 'file-saver';
@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.css']
})
export class TableComponent implements OnInit {
  @Input() table;
  @Input() processId: any;
  @Input() taskKey: any;
  constructor(private processService: ProcessService) { }

  ngOnInit() {
  }

  onChange(rowIndex, columnIndex, key, value) {
    const obj = { key: key, value: value};
    this.table.value.rows[rowIndex][columnIndex] = obj
  }

  onComponentChange(rowIndex, columnIndex, key, value, rowElem) {
    const obj = { key: key, value: value, targets: rowElem.targets };
    if (rowElem.targets) {
      obj.targets = rowElem.targets;
    }
    this.table.value.rows[rowIndex][columnIndex] = obj;
  }

  findCol(columns, key ){

    if (columns !== undefined) {
      for (var c = 0; c<columns.length; c++) {
        for (var col = 0; col<columns[c].length; col++) {
          if (columns[c][col].key === key) {
            return  columns[c][col];
          }
        }
      }
    }
  }
  downloadTable(apiUrl) {
    this.processService.downloadTable(apiUrl+"/"+this.processId+"/"+this.taskKey+"/"+this.table.key).subscribe(table => {
          const blob = table;
          const file = new Blob([blob], {});
          saveAs(file, (this.table.title && this.table.title!==''?this.table.title:this.table.key) + '.xlsx');  });
  }
}
