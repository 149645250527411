import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { ConsoleService } from '@ng-select/ng-select/lib/console.service';
import { Observable } from "rxjs";
import { catchError, map, tap } from 'rxjs/operators';
import { SharedService } from "./sharedService";
import { UserService } from './user.service';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable()
export class ProcessService {
    updateValue(id: any, taskKey: any, itemIndex: any, element: any) {
        return this.http.put<any>(this.sharedService.apiUrl('api/control/process/action/updatevalue/' + id + '/' + taskKey, itemIndex), element,
        httpOptions).pipe(
            tap((process: any) => this.sharedService.log(`upadate value task w/ id=${process.id}`)),
            catchError(this.sharedService.handleError<any>('update value'))
        );
    }
    deleteTimesheetRecord(processId: any, id: any) {
        return this.http.delete<any>(this.sharedService.apiUrl('api/control/process/action/timesheet/' + processId)+id).pipe(
            tap((process: any) => this.sharedService.log(`timesheet delete w/ id=${process.id}`)),
            catchError(this.sharedService.handleError<any>('timesheet delete'))
        );
    }
    addTimesheet(processId: any, timesheet: any): Observable<any> {
        return this.http.put<any>(this.sharedService.apiUrl('api/control/process/action/timesheet/' + processId), timesheet,
            httpOptions).pipe(
                tap((process: any) => this.sharedService.log(`timesheet add w/ id=${process.id}`)),
                catchError(this.sharedService.handleError<any>('timesheet add'))
            );
    }
    submitTimesheet(processId: any): Observable<any> {
        return this.http.put<any>(this.sharedService.apiUrl('api/control/process/action/timesheet/' + processId+'/submit'), null,
            httpOptions).pipe(
                tap((process: any) => this.sharedService.log(`timesheet submit w/ id=${process.id}`)),
                catchError(this.sharedService.handleError<any>('timesheet submit'))
            );
    }
    approveTimesheet(processId: any, timesheetId: any): Observable<any> {
        return this.http.put<any>(this.sharedService.apiUrl('api/control/process/action/timesheet/' + processId+'/approve/'+timesheetId), null,
            httpOptions).pipe(
                tap((process: any) => this.sharedService.log(`timesheet approve w/ id=${process.id}`)),
                catchError(this.sharedService.handleError<any>('timesheet approve'))
            );
    }

    saveDocuments(processId: any, documents: any): Observable<any> {
        return this.http.put<any>(this.sharedService.apiUrl('api/control/process/action/document/' + processId), documents,
            httpOptions).pipe(
                tap((process: any) => this.sharedService.log(`timesheet add w/ id=${process.id}`)),
                catchError(this.sharedService.handleError<any>('timesheet add'))
            );
    }
    saveTimesheetCaseActivity(processId: any, caseActivity: any): Observable<any> {
        return this.http.put<any>(this.sharedService.apiUrl('api/control/process/action/caseactivity/' + processId), caseActivity,
            httpOptions).pipe(
                tap((process: any) => this.sharedService.log(`caseActivity save w/ id=${process.id}`)),
                catchError(this.sharedService.handleError<any>('caseActivity save'))
            );
    }
    uploadTimesheetDocument(processId: any, document: any): Observable<any> {
        return this.http.put<any>(this.sharedService.apiUrl('api/control/process/action/timesheet/document/' + processId), document,
            httpOptions).pipe(
                tap((process: any) => this.sharedService.log(`document add w/ id=${process.id}`)),
                catchError(this.sharedService.handleError<any>('document add'))
            );
    }
    constructor(private http: HttpClient, private sharedService: SharedService, private userService: UserService) {
    }

    addProcess(process: any): Observable<any> {
        return this.http.post<any>(this.sharedService.apiUrl('api/control/process'), process, httpOptions).pipe(
            tap((process: any) => this.sharedService.log(`added process w/ id=${process.id}`)),
            catchError(this.sharedService.handleError<any>('addProcess'))
        );
    }

    approveAll(query: string): Observable<any> {
        return this.http.post<any>(this.sharedService.apiUrl('api/control/process/action/approve-all', query), null, httpOptions).pipe(
            tap((process: any) => this.sharedService.log(`approve all process w/ ${process}`)),
            catchError(this.sharedService.handleError<any>('approve all'))
        );
    }

    getSteps(type): Observable<any[]> {
        const steps$ = this.http.get<any>(this.sharedService.apiUrl('api/control/process/step', 'list?type=' + type));
        return steps$;
    }

    getProcesses(step: any, teamId: any, type: any, from: any, size: any, status: any, query: any, risks: any[], 
        lastPeriodYear: any, submittedPeriodYear: any, semester: any, year: any, editor: boolean, ifus: any[], executionTask: any): Observable<any> {
        const q =  'type=' + (type === 'null' ? '' : type) +  (status ? '&status=' + status : '')
            + (query ? '&query=' + query : '') + (risks.length > 0 ? '&risks=' + risks : '') + (ifus.length > 0 ? '&ifus=' + ifus : '') + (lastPeriodYear && !isNaN(lastPeriodYear) ? '&lastPeriodYear=' + lastPeriodYear : '') + (submittedPeriodYear && !isNaN(submittedPeriodYear) ? '&submittedPeriodYear=' + submittedPeriodYear : '') +
             (semester && !isNaN(semester) ? '&semester=' + semester : '') + (year && !isNaN(year) ? '&year=' + year : '') + (executionTask ? '&executionTask=' + executionTask : '');;
        var queryParam  = step ? '?step=' + step + '&' + q : '?' + q;
        return this.http.get<any>(this.sharedService.apiUrl('api/control/process' + (editor?'/assigned':''), queryParam + '&from=' + from + '&size=' + size)).pipe(
            tap(data => data['query'] = queryParam),
            tap((process: any) => this.sharedService.log(`get processes w/ id=${process.id}`)),
            catchError(this.sharedService.handleError<any>('getProcesses'))
        );
    }

    getProcessesFile(api:any, step: any, teamId: any, type: any, from: any, size: any, status: any, query: any, risks: any, lastPeriodYear: any, submittedPeriodYear: any, 
        semester: any, year: any, editor: boolean, ifus: any[], executionTask: any): Observable<any> {
        const q ='type=' + (type === 'null' ? '' : type) + '&from=' + from + '&size=' + size + (status ? '&status=' + status : '')
            + (query ? '&query=' + query : '') + (risks ? '&risks=' + risks : '') +  (lastPeriodYear && !isNaN(lastPeriodYear) ? '&lastPeriodYear=' + lastPeriodYear : '') 
            + (submittedPeriodYear  && !isNaN(submittedPeriodYear) ? '&submittedPeriodYear=' + submittedPeriodYear : '') 
            + (semester && !isNaN(semester) ? '&semester=' + semester : '')
            + (ifus.length > 0 ? '&ifus=' + ifus : '')
            + (year && !isNaN(year) ? '&year=' + year : '');
            + (executionTask ? '&executionTask=' + executionTask : '')
        const httpOptionsPDF = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
            responseType: 'blob' as 'json',

        };
        const process$ = this.http.get<any>(this.sharedService.apiUrl(api, step ? '?step=' + step + '&' + q : '?' + q),
            httpOptionsPDF).pipe(
                map((res: Response) => res), catchError(this.sharedService.handleError<any>('download file'))
            );
        return process$;

    }

    downloadTable(api:any ): Observable<any>{
        const httpOptionsPDF = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
            responseType: 'blob' as 'json',

        };
        const table$ = this.http.get<any>(this.sharedService.apiUrl(api),
            httpOptionsPDF).pipe(
                map((res: Response) => res), catchError(this.sharedService.handleError<any>('download file'))
            );
        return table$;
    }

    getMyProposals(companyId: any, from: any, size: any, status: any, query: any): Observable<any> {
        const q = '?companyId=' + companyId + '&from=' + from + '&size=' + size + (status ? '&status=' + status : '') +
            (query ? '&query=' + query : '');
        return this.http.get<any>(this.sharedService.apiUrl('api/control/process/by-company', q)).pipe(
            tap((process: any) => this.sharedService.log(`get processes w/ id=${process.id}`)),
            catchError(this.sharedService.handleError<any>('getProcesses'))
        );
    }

    getAssignedControls(from: any, size: any, status: any, query: any): Observable<any> {
        const q = '?from=' + from + '&size=' + size + (status ? '&status=' + status : '') +
            (query ? '&query=' + query : '');
        return this.http.get<any>(this.sharedService.apiUrl('api/control/process/assigned', q)).pipe(
            tap((process: any) => this.sharedService.log(`get processes w/ id=${process.id}`)),
            catchError(this.sharedService.handleError<any>('getProcesses'))
        );
    }

    isAssigned(): Observable<any> {
        return this.http.get<any>(this.sharedService.apiUrl('api/control/process/is-assigned')).pipe(
            tap((process: any) => this.sharedService.log(`get processes w/ id=${process.id}`)),
            catchError(this.sharedService.handleError<any>('getProcesses'))
        );
    }

    getProcess(id: any): Observable<any> {
        return this.http.get<any>(this.sharedService.apiUrl('api/control/process', id)).pipe(
            tap((process: any) => this.sharedService.log(`get process w/ id=${process}`)),
            catchError(this.sharedService.handleError<any>('getProcess'))
        );
    }

    deleteControl(processId: any): Observable<any> {
        return this.http.delete<any>(this.sharedService.apiUrl('api/control/process/' + processId)).pipe(
            tap((process: any) => this.sharedService.log(`delete control w/ id=${process.id}`)),
            catchError(this.sharedService.handleError<any>('deleteControl'))
        );
    }

    saveTask(processId: any, taskKey: any, task: any): Observable<any> {
        return this.http.put<any>(this.sharedService.apiUrl('api/control/process/action/save/' + processId, taskKey), task.items,
            httpOptions).pipe(
                tap((process: any) => this.sharedService.log(`save task w/ id=${process.id}`)),
                catchError(this.sharedService.handleError<any>('save'))
            );
    }
    submitTask(processId: any, taskKey: any, task: any): Observable<any> {
        return this.http.put<any>(this.sharedService.apiUrl('api/control/process/action/submit/' + processId, taskKey), task.items,
            httpOptions).pipe(
                tap((process: any) => this.sharedService.log(`save task w/ id=${process.id}`)),
                catchError(this.sharedService.handleError<any>('save'))
            );
    }
    assignTeam(processId: any, taskKey: any, team: any): Observable<any> {
        return this.http.post<any>(this.sharedService.apiUrl('api/control/process/action/assign/' + processId, taskKey), team,
            httpOptions).pipe(
                tap((process: any) => this.sharedService.log(`save task w/ id=${process.id}`)),
                catchError(this.sharedService.handleError<any>('save'))
            );
    }


    suspendDeadline(processId: any, taskKey: any, days: any): Observable<any> {
        return this.http.put<any>(this.sharedService.apiUrl('api/control/process/action/suspend/' + processId, taskKey), null,
            httpOptions).pipe(
                tap((process: any) => this.sharedService.log(`suspend deadline w/ id=${process.id}`)),
                catchError(this.sharedService.handleError<any>('suspend'))
            );
    }

    requestDeadlineSuspend(processId: any, taskKey: any, days: any): Observable<any> {
        return this.http.put<any>(this.sharedService.apiUrl('api/control/process/action/suspend-request/' + processId, taskKey), {days: days},
            httpOptions).pipe(
                tap((process: any) => this.sharedService.log(`suspend deadline w/ id=${process.id}`)),
                catchError(this.sharedService.handleError<any>('suspend'))
            );
    }

    confirmExtention(processId: any, taskKey: any): Observable<any> {
        return this.http.put<any>(this.sharedService.apiUrl('api/control/process/action/suspend-approve/' + processId, taskKey), null,
            httpOptions).pipe(
                tap((process: any) => this.sharedService.log(`extend deadline approvew/ id=${process.id}`)),
                catchError(this.sharedService.handleError<any>('suspend'))
            );
    }

    rejectExtention(processId: any, taskKey: any): Observable<any> {
        return this.http.put<any>(this.sharedService.apiUrl('api/control/process/action/suspend-reject/' + processId, taskKey), null,
            httpOptions).pipe(
                tap((process: any) => this.sharedService.log(`extend deadline reject w/ id=${process.id}`)),
                catchError(this.sharedService.handleError<any>('suspend'))
            );
    }

    reactivateDeadline(processId: any, taskKey: any): Observable<any> {
        return this.http.put<any>(this.sharedService.apiUrl('api/control/process/action/reactivate/' + processId, taskKey), null,
            httpOptions).pipe(
                tap((process: any) => this.sharedService.log(`reactivate deadline w/ id=${process.id}`)),
                catchError(this.sharedService.handleError<any>('reactivate'))
            );
    }


    approveTask(processId: any, taskKey: any): Observable<any> {
        return this.http.put<any>(this.sharedService.apiUrl('api/control/process/action/approve/' + processId, taskKey), null,
            httpOptions).pipe(
                tap((process: any) => this.sharedService.log(`approve task w/ id=${process.id}`)),
                catchError(this.sharedService.handleError<any>('approve'))
            );
    }

    validateTask(processId: any, taskKey: any): Observable<any> {
        return this.http.put<any>(this.sharedService.apiUrl('api/control/process/action/validate/' + processId, taskKey), null,
            httpOptions).pipe(
                tap((process: any) => this.sharedService.log(`validate task w/ id=${process.id}`)),
                catchError(this.sharedService.handleError<any>('validate'))
            );
    }

    rejectTask(processId: any, taskKey: any): Observable<any> {
        return this.http.put<any>(this.sharedService.apiUrl('api/control/process/action/reject/' + processId, taskKey), null,
            httpOptions).pipe(
                tap((process: any) => this.sharedService.log(`save task w/ id=${process.id}`)),
                catchError(this.sharedService.handleError<any>('save'))
            );
    }

    comment(processId: any, taskKey: any, comment: any): Observable<any> {
        return this.http.post<any>(this.sharedService.apiUrl('api/control/process/action/comment/' + processId, taskKey), comment,
            httpOptions).pipe(
                tap((process: any) => this.sharedService.log(`add comment w/ id=${process.id}`)),
                catchError(this.sharedService.handleError<any>('comment'))
            );
    }

    deleteComment(processId: any, commentKey: any): Observable<any> {
        return this.http.delete<any>(this.sharedService.apiUrl('api/control/process/action/comment/' + processId, commentKey)).pipe(
            tap((process: any) => this.sharedService.log(`delete comment w/ id=${process.id}`)),
            catchError(this.sharedService.handleError<any>('deleteComment'))
        );
    }

    downloadFile(key: any): Observable<Blob> {
        const httpOptionsPDF = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
            responseType: 'blob' as 'json',

        };
        const process$ = this.http.get<any>(this.sharedService.apiUrl('api/control/process/file/' + encodeURIComponent(key)),
            httpOptionsPDF).pipe(
                map((res: Response) => res), catchError(this.sharedService.handleError<any>('download file'))
            );
        return process$;
    }

    downloadTask(processId: any, key: any): Observable<Blob> {
        const httpOptionsPDF = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
            responseType: 'blob' as 'json',

        };
        const process$ = this.http.get<any>(this.sharedService.apiUrl('api/control/report/task-export/' + processId + "/" + encodeURIComponent(key)),
            httpOptionsPDF).pipe(
                map((res: Response) => res), catchError(this.sharedService.handleError<any>('download file'))
            );
        return process$;
    }

    downloadAssetFile(type: any, key: any): Observable<Blob> {

        if (type === 'file-static') {
            return this.downloadFile(key);
        }

        const httpOptionsPDF = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
            responseType: 'blob' as 'json',

        };
        const process$ = this.http.get<any>(this.sharedService.apiUrl('api/control/process/file/' + encodeURIComponent(key),
            '?type=template'), httpOptionsPDF).pipe(
                map((res: Response) => res), catchError(this.sharedService.handleError<any>('download file'))
            );
        return process$;

    }

    deleteFile(key: any): Observable<Blob> {
        const httpOptions = {

        };
        const file$ = this.http.delete<any>(this.sharedService.apiUrl('api/control/process/file/' +
            encodeURIComponent(key)), httpOptions).pipe(
                map((res: Response) => res), catchError(this.sharedService.handleError<any>('delete docflow file'))
            );
        return file$;

    }

    setFileTemplate(id: any, key: any, global: any, format) {
        const httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
            responseType: 'blob' as 'json',
        };
        if (global){
            httpOptions['params'] = new HttpParams().set('global', global)
        }
        if (format){
            httpOptions['params'] = new HttpParams().set('format', format)
        }
        return this.http.get<any>(this.sharedService.apiUrl('api/control/process/file/template/' + id, encodeURIComponent(key)), httpOptions).pipe(
            tap((process: any) => this.sharedService.log(`setFileTemplate w/ id=${process.id}`)),
            catchError(this.sharedService.handleError<any>('setFileTemplate'))
        );
    }

    getControlTypeList(query: string): Observable<any> {
        const controlTypes$ = this.http.get<any[]>(this.sharedService.apiUrl('api/control/process/type/list', '?query=' + query));
        return controlTypes$;
    }
}
