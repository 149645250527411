import {Injectable} from '@angular/core';
import {SharedService} from "./sharedService";
import {PagerDataObject} from "../entities/pagerDataObject";
import {catchError, map, tap} from 'rxjs/operators';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import { Observable, of } from 'rxjs';
import { PayeeSector } from '../entities/payeeSector';

const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};

@Injectable()
export class PayeeSectorService {

  constructor(private http: HttpClient, private sharedService: SharedService) {
  }

  getData(query: string): Observable<PagerDataObject> {
    if (query !== null)
    var currencies$ = this.http.get<PagerDataObject>(this.sharedService.apiUrl('api/payee_sectors', query !== null?("/" + query):""))

    return currencies$;
  }

  add(payeeSector: PayeeSector): Observable<PayeeSector> {
    return this.http.post<PayeeSector>(this.sharedService.apiUrl('api/payee_sectors/add'), payeeSector, httpOptions).pipe(
      tap((payeeSector: PayeeSector) => this.log(`added payeeSector w/ id=${payeeSector.id}`)),
      catchError(this.handleError<PayeeSector>('add'))
    );
  }

  getPayeeSector(id: string): Observable<PayeeSector> {
    let payeeSector$ = this.http.get<PayeeSector>(this.sharedService.apiUrl('api/payee_sectors/' + id))
    return payeeSector$;
  }

  delete(payeeSector: PayeeSector): Observable<PayeeSector> {
    return this.http.post<PayeeSector>(this.sharedService.apiUrl('api/ind_sectors/delete'), payeeSector, httpOptions).pipe(
      tap((payeeSector: PayeeSector) => this.sharedService.log(`deleted payeeSector w/ id=${payeeSector.id}`)),
      catchError(this.sharedService.handleError<any>('deletePayeeSector'))
    );
  }

  columns: Array<any> = [
    {key: 'view', style: {'flex': '0 0 100px', 'width': '1%'}, button: true},
    {name: 'Name', tag: 'PAYEE-SECTOR.NAME', key: 'name', filtering: true, sorting: true, sortingOrder: 'asc'},
    {name: 'Code', tag: 'PAYEE-SECTOR.CODE', key: 'code', filtering: true, sorting: true, sortingOrder: 'asc'},
    {name: 'Higher Sector', tag: 'PAYEE-SECTOR.HIGHER_SECTOR', key: 'higherSector', filtering: true, sorting: true, sortingOrder: 'asc'}
    // {name: 'Indicator Sector', tag: 'PAYEE-SECTOR.INDICATOR_SECTOR', key: 'indicatorSector', filtering: true, sorting: true}
  ];

  getMetaInfo(): string[] {
    return ['name', 'code', 'higherSector'];
  }

  getColumns(): string[] {
    return this.columns;
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  private log(message: string) {
    //this.messageService.add('HeroService: ' + message);
  }
}
