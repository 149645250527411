import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-indicator-sector-add-edit',
  templateUrl: './indicator-sector-add-edit.component.html',
  styleUrls: ['./indicator-sector-add-edit.component.css']
})
export class IndicatorSectorAddEditComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
