import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { SharedService } from "./sharedService";
import { Observable } from "rxjs";
import { PagerDataObject } from "../entities/pagerDataObject";

@Injectable()
export class TasService {
  getTasTableData(declarationId: any, stageKey: any, blockKey: any,  fileFormatId: any, fileFormatName:any, serviceId:any, serviceName:any): Observable<any> {
    let submittedData$ = this.http.get<any>(this.sharedService.apiUrl('api/tas/reports/submittedData/'+declarationId+'/'+stageKey+'/'+blockKey+'/'+fileFormatId+'/'+fileFormatName+'/'+serviceId+'/'+serviceName))
    return submittedData$;
  }


  constructor(private http: HttpClient, private sharedService: SharedService) {
  }


  getData(): Observable<any> {
    let reports$ = this.http.get<any>(this.sharedService.apiUrl('api/tas/reports'))
    return reports$;
  }
  getStages(id: string): Observable<any> {
    let stages$ = this.http.get<any>(this.sharedService.apiUrl('api/tas/reports/stages/'+id))
    return stages$;
  }
  getBlocks(decId: string, stageKey: string): Observable<any> {
    let blocks$ = this.http.get<any>(this.sharedService.apiUrl('api/tas/reports/blocks/'+decId+'/'+stageKey))
    return blocks$;
  }
}