import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Observable, Subject } from 'rxjs';
import { ProcessService } from 'src/app/services/process.service';
import { SharedService } from '../../services/sharedService';
import { ControlType } from '../../entities/controlType';
import { RiskService } from 'src/app/services/riskService';
import { NgSelectComponent } from '@ng-select/ng-select';
import { Company } from 'src/app/entities/company';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-new-preprocess',
  templateUrl: './new-preprocess.component.html',
  styleUrls: ['./new-preprocess.component.css']
})
export class NewPreprocessComponent implements OnInit {
  @Input()
  modalRef?: BsModalRef;
  creating = false;
  @Input()
  company?: Company;

  risks$: any[] = [];
  risksLoading = false;
  risksInput$ = new Subject<string>();
  selectedCompany: any;
  controlActivity: any;
  controlexecutive: any;
  dernPeriod: any;
  soumisePeriod: any;
  controlProgSource: any;

  controlTypes$: any[];
  controlTypesLoading = false;
  controlTypesInput$ = new Subject<string>();
  selectedControl: any;
  controlName: any;
  activeRisks: any[];
  process = {}
  @ViewChild('riskMulti', { static: false }) public riskMulti: NgSelectComponent;

  constructor(
    private riskService: RiskService,
    private processService: ProcessService, private sharedService: SharedService,
    private spinner: NgxSpinnerService) { }

  ngOnInit() {
    this.riskService.getTotalRiskList().subscribe(risks => {
      this.risks$ = risks;
    })
    this.processService.getControlTypeList('').subscribe(resp => {
        this.controlTypes$ = resp;
    });
  }
 
  onSubmit(): void {
    this.spinner.show();
    this.creating = true;
    this.process['type'] = {}
    this.process['company'] = {};
    this.process['company']['id'] = this.company.id;
    this.process['company']['name'] = this.company.company_name;
    this.process['company']['tax_id'] = this.company.tax_id;
    this.process['type']['key'] = this.selectedControl;
    this.process['type']['name'] = this.controlTypes$.find(elem => elem['key']===this.selectedControl).name;
    this.process['risks'] = this.riskMulti['activeItems']

    this.process['activity'] = this.controlActivity
    this.process['performer'] = this.controlexecutive
    // if (this.dernPeriod)
    // this.process['lastPeriod'] = this.getNgbDatepickerIsoDate(this.dernPeriod)

    // if (this.soumisePeriod)
    // this.process['submittedPeriod'] = this.getNgbDatepickerIsoDate(this.soumisePeriod)
    this.process['source'] = this.controlProgSource
    this.processService.addProcess(this.process).subscribe(res => {
      this.modalRef.hide();
      this.spinner.hide()
      setTimeout(()=>{
        this.sharedService.addProcess();
      }, 1000)
      
    });
  }

  getNgbDatepickerIsoDate(date: any){
     let dateFromDbPicker = new Date(Date.UTC(date['year'], date['month']-1,  date['day'], 0, 0, 0, 0))
     return dateFromDbPicker;
  }
  setDates(res){
    this.process[res.key] = {}
    this.process[res.key]['fromDate'] = res.fromDate
    this.process[res.key]['toDate'] = res.toDate

  }

}
