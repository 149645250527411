
export class BatchProcess {
  id: number;
  fileName: string;
  serviceId: string;
  fileFormatId: string;
  serviceName: string;
  fileFormatName: string;
  processStartTime: string;
  processEndTime: string;
  status: number;
  numberOfRecords: number;
  processedRecords: number;
}
