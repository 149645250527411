import {Injectable} from '@angular/core';
import {Service} from "../entities/service";
import {SharedService} from "./sharedService";


import {PagerDataObject} from "../entities/pagerDataObject";
import {catchError, tap} from 'rxjs/operators';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import { Subject, Observable } from 'rxjs';
import { User } from '../entities/user';
import { Company } from '../entities/company';


const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};

@Injectable()
export class CompanyService {

  private serviceUpdate = new Subject<User>();
  serviceUpdated$ = this.serviceUpdate.asObservable();

  constructor(private http: HttpClient, private sharedService: SharedService) {
  }

  getData(query: string): Observable<PagerDataObject> {
    let companies$ = this.http.get<PagerDataObject>(this.sharedService.apiUrl('api/payees', "/" + query))
    return companies$;
  }

  getTotalCompanyList(query: string): Observable<Company[]> {
    let companies$ = this.http.get<Company[]>(this.sharedService.apiUrl('api/payees'))
    return companies$;
  }

  getCompanyList(query: string): Observable<any> {
    let companies$ = this.http.get<any[]>(this.sharedService.apiUrl('api/payees/list', "?query="+query))
    return companies$;
  }

  getCompany(id: string): Observable<Company> {
    let company$ = this.http.get<Company>(this.sharedService.apiUrl('api/payees/' + id))
    return company$;
  }

  getDatasets(id: string): Observable<any> {
    let datasets$ = this.http.get<any>(this.sharedService.apiUrl('api/payees/datasets' )+id)
    return datasets$;
  }

  getCompanyById(id: string): Observable<User> {
    let user$ = this.http.get<User>(this.sharedService.apiUrl('api/payees/byId/' + id))
    return user$;
  }

  add(company: Company): Observable<Company> {
    return this.http.post<Company>(this.sharedService.apiUrl('api/payees/add'), company, httpOptions).pipe(
      tap((company: Company) => this.sharedService.log(`added company w/ id=${company.id}`)),
      catchError(this.sharedService.handleError<Company>('addCompany'))
    );
  }

  delete(company: Company): Observable<Company> {
    return this.http.post<Company>(this.sharedService.apiUrl('api/payees/delete'), company, httpOptions).pipe(
      tap((company: Company) => this.sharedService.log(`deleted user w/ id=${company}`)),
      catchError(this.sharedService.handleError<Company>('deleteCompany'))
    );
  }
 

  columns: Array<any> = [
    {key: 'view', style: {'flex': '0 0 100px', 'width': '1%'}, button: true},
    {name: 'Name', tag: 'COMPANY.NAME', key: 'company_name', filtering: true, sorting: true, sortingOrder: 'asc'},
    {name: 'TaxID', tag: 'COMPANY.TAXID', key: 'tax_id', filtering: true, sorting: true},
    {name: 'First Name', tag: 'COMPANY.FIRSTNAME', key: 'first_name', filtering: true, sorting: true},
    {name: 'Last Name', tag: 'COMPANY.LASTNAME', key: 'last_name', filtering: true, sorting: true}
  ];

  getMetaInfo(): string[] {
    return ['company_name','tax_id', 'first_name', 'last_name'];
  }

  getColumns(): string[] {
    return this.columns;
  }
}
