import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { PagerDataObject } from 'src/app/entities/pagerDataObject';
import { Subject } from 'rxjs';
import { DashboardService } from 'src/app/services/dashboard.service';
import { switchMap } from 'rxjs/operators';
import { Dashboard } from 'src/app/entities/dashboard';
import { SharedService } from 'src/app/services/sharedService';

@Component({
  selector: 'app-dashboard-config',
  templateUrl: './dashboard-config.component.html',
  styleUrls: ['./dashboard-config.component.css']
})
export class DashboardConfigComponent implements OnInit, AfterViewInit {
 
  metaInfo: string[];
  columns: Array<any>;
  data: PagerDataObject;
  @Input()
  headers = [' ', 'Name', 'Code', 'Type'];
  @Input()
  disableHeader = false;
  subscription: any;
  private dataSub$ = new Subject<any>();
  query: String;

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
    this.sharedService.loadMenuItems('admin');
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  constructor(private dashboardService: DashboardService, private sharedService: SharedService) {
    this.subscription = this.dataSub$.pipe(switchMap(filter => {
      return this.dashboardService.getData(filter);
    }))
      .subscribe(data => {
        this.data = data;
      });
    this.metaInfo = this.dashboardService.getMetaInfo();
    this.columns = this.dashboardService.getColumns();
  }

  onGridQuery(query) {
    this.query = query
    this.dataSub$.next(query);
  }

  deleteDashboard(entity){
    let dashboard = new Dashboard();
    dashboard.id = entity;
    this.dashboardService.delete(dashboard)
      .subscribe(service => {
        this.dataSub$.next(this.query);
      });
  }

}
