import { Component, OnInit, ViewChild } from '@angular/core';
import { User } from 'src/app/entities/user';
import { switchMap } from 'rxjs/operators';
import { ParamMap, Router, ActivatedRoute } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { TaxDivisionService } from 'src/app/services/taxDivision.service';
import { NgSelectComponent } from '@ng-select/ng-select';
import { AuthService } from 'src/app/services/auth.service';
import { ProcessService } from 'src/app/services/process.service';

@Component({
  selector: 'app-user-add-edit',
  templateUrl: './user-add-edit.component.html',
  styleUrls: ['./user-add-edit.component.css']
})
export class UserAddEditComponent implements OnInit {

  user: User = new User();
  submitted = false;
  wrongCurrentPassword = false;
  usernameExists = false;
  pass1 = ""
  pass2 = ""
  currentPassword: string;
  pass1Wrong = false;
  pass2Wrong = false;
  noPass = false;
  changePass = false;
  rattachementList: any[] = [];
  controlTypesList: any[] = [];
  isAdmin = false;
  roles = ""
  @ViewChild('rattachementListSelect', {static:false}) public rattachementListSelect: NgSelectComponent;
  @ViewChild('controlTypesListSelect', {static:false}) public controlTypesListSelect: NgSelectComponent;
  ngOnInit(): void {
    this.taxDivisionService.getList('tax-divisions/grouped').subscribe(cdiCenters => {
      this.rattachementList = cdiCenters.map(({abrev}) => abrev)
      this.processService.getControlTypeList('').subscribe(resp => {
        this.controlTypesList = resp.map(x => x.key);
      });
      this.user = new User();
      this.route.paramMap.pipe(
        switchMap((params: ParamMap) => this.userService.getUser(params.get('id'))))
        .subscribe(user => {
          if (user !== null) {
            this.user = user;
            if (this.user.roles && this.user.roles.includes('ADMIN'))
            {
              this.isAdmin = true;
            }else{
              if (this.user.roles){
                this.user.roles.forEach((elem,index)=>{
                  var rl  = elem.toLowerCase().replace(/_/g," ")
                  this.roles += rl.charAt(0).toUpperCase() +  rl.slice(1)
                  if (index<this.roles.length-2){
                    this.roles +=", "
                  }
                })
              }
            }
          }
        })
          this.auth.getCurrentUser().subscribe(user=>{
            if (user.roles.includes("ADMIN"))
            this.isAdmin = true
          })
    });
  }


  constructor(public userService: UserService,
    private router: Router, private route: ActivatedRoute, private spinner: NgxSpinnerService,
    private processService: ProcessService,
    private taxDivisionService: TaxDivisionService, private auth: AuthService) {

  }

  onSubmit() {
    this.user['user_update'] = true
    if (!this.user.id) {
      this.user['password'] = this.pass1
    }
    if (this.rattachementListSelect['activeItems'])
    this.user.rattachementList = this.rattachementListSelect['activeItems'];
    
    if (this.controlTypesListSelect['activeItems']){
      this.user.controlTypesList = this.controlTypesListSelect['activeItems'];
    }

    this.userService.add(this.user)
      .subscribe(user => {
        if (user['error'] && user['error'] === 'USERNAME_EXISTS') {
          this.usernameExists = true;
        }
        else {
          this.submitted = true;

          this.goToList();
        }

      });
  }

  goToList() {
    this.router.navigateByUrl('/user-list');
  }

  changePassBtnClick() {
    this.changePass = true
  }


  updatePassword() {
    this.spinner.show();
    this.user['currentPassword'] = this.currentPassword
    this.user['password'] = this.pass1
    this.userService.add(this.user).subscribe(
      data => {
        this.spinner.hide();
        if (data['error'] && data['error'] === 'WRONG_PASSWORD') {
          this.wrongCurrentPassword = true;
        } else {
          this.wrongCurrentPassword = false;
          this.currentPassword = "";
          this.pass1 = "";
          this.pass2 = "";
          this.changePass = false;
          this.noPass = false;
        }
      },
      err => {
      }
    )

  }

  setPassword(firstTime) {
    if (firstTime) {
      this.noPass = true;
    }
    if (!firstTime) {
      this.changePass = true;
    }
  }

  onCheckboxChange(event,role) {
    if (this.user.roles===undefined)
    this.user.roles = [];
    if (event.target.checked){
      this.user.roles.push(role)
    }
    else{
      this.user.roles.splice(this.user.roles.indexOf(role),1)
    }
  }
  onChangestatus(event) {
    if (event.target.checked){
      this.user.status = 'ENABLED';
    }
    else{
      this.user.status = 'DISABLED';
    }
  }
}
