import { Component, OnInit, Input } from '@angular/core';
import { Service } from 'src/app/entities/service';
import { ServiceService } from 'src/app/services/service.service';
import { AuthService } from 'src/app/services/auth.service';
import { NavigationStart, Event as NavigationEvent, Router } from '@angular/router';
import { SharedService } from 'src/app/services/sharedService';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from 'src/app/services/user.service';
import { NotificationService } from 'src/app/services/notification.service';
import {ControlTeamService} from '../../../services/controlTeam.service';
import {ProcessService} from '../../../services/process.service';

@Component({
  selector: 'app-control-module-nav-header',
  templateUrl: './control-module-header.component.html',
  styleUrls: ['./control-module-header.component.css']
})

export class ControlModuleHeaderComponent implements OnInit {
  leftMenuItems = [];
  leftMenuSubscription: any;
  @Input() contextMenu: string[];
  lang: string;
  username: string;
  initials: string;
  menuItems: string[][];
  notifications: any[];
  dropDownNotifications: any[];
  teams: any[];
  controlTypes: any[];
  user;
  services: Service[];
  loginSubscription: any;
  unseenNotifications = 0;
  teamName: any;
  controlTypeName: any;
  currentTeamId: any;
  currentControlType: any;
  constructor(private translate: TranslateService, private serviceService: ServiceService,
              private userService: UserService, private controlTeamService: ControlTeamService,
              public auth: AuthService, private router: Router, private sharedService: SharedService,
              private processService: ProcessService,
              private notificationService: NotificationService) {
    this.auth.loggedIn.subscribe(val => {
      if (val) {
        this.loadData();
      }
    });
  }

  loadData() {
        this.userService.getCurrentUser().subscribe(user => {
          if (user !== null) {
            this.user = user;
            this.getServices();
          }
        });
  }

  ngOnInit() {
    this.translate.setDefaultLang('en');
    this.setLang(localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en');
    this.auth.loggedIn.subscribe(val => {
      if (val) {
       // this.loadData();
        this.controlTeamService.getMyControlTeams().subscribe(teams => {
          this.teams = teams;
          this.currentTeamId = localStorage.getItem('currentTeamId');
          if (this.currentTeamId) {
            const currentTeamEl = this.teams.find(elem => elem.id === this.currentTeamId);
            if (currentTeamEl) {
              this.teamName = currentTeamEl.name;
            }
          } else if (this.teams.length > 0) {
            this.teamName = this.teams[0].name;
            localStorage.setItem('currentTeamId', this.teams[0].id);
            localStorage.setItem('currentTeamName', this.teamName);
            this.sharedService.changeTeam(this.teams[0].id);
          }

          this.processService.getControlTypeList('').subscribe(res => {
            this.controlTypes = []
            if (this.user.controlTypesList===undefined || this.user.controlTypesList.length===0)
            this.controlTypes = res;
            else{ 
              this.user.controlTypesList.forEach(uC => {
                res.forEach(rc => { 
                  if (rc.key === uC){
                    this.controlTypes.push(rc) 
                  }
                })
              })
            }
            this.controlTypes.unshift({key:null,name:"ALL"})
            this.currentControlType = localStorage.getItem('currentControlType');
            if (this.currentControlType) {
            
              const currentControlTypeEl = this.controlTypes.find(elem => elem.key === this.currentControlType);
              if (currentControlTypeEl) {
                this.controlTypeName = currentControlTypeEl.name;
              }
            } else {
              this.controlTypeName = this.controlTypes[0].name;
              localStorage.setItem('currentControlType', this.controlTypes[0].key);
              localStorage.setItem('currentControlTypeName', this.controlTypeName);
              this.sharedService.changeControlType(this.controlTypes[0].key);
            }
          });

        });
      }
    });

    this.leftMenuSubscription = this.sharedService.menuItemsEmitter.subscribe(menuItems => {
      this.setupMenu(menuItems);
    });

    this.getUnseenNotifications();
    setInterval(() => this.getUnseenNotifications(), 60000);


    this.router.events
      .subscribe(
        (event: NavigationEvent) => {
          if (event instanceof NavigationStart) {
            // this.setupMenu(this.sharedService.getMenuFromUrl(event.url));
          }
        });
  }

  setSeenAll() {
    this.unseenNotifications = 0;

    this.notificationService.setSeenAll().subscribe(result => {
      this.notificationService.getNotificationList().subscribe(res => {
        this.dropDownNotifications = res;
       });
    });
  }

  getServices() {
    this.serviceService.getTotalServiceList('').subscribe(services => {
      this.services = services;
    });
  }

  logout() {
    this.username = '';
    this.auth.logout().subscribe(res => {
      this.router.navigate(['login']);
      this.auth.setLoginStatus(false);
    });

  }

  userAccount() {
    this.router.navigate(['user-add-edit', this.user.id]);
  }

  setupMenu(menuItems) {
    if (menuItems !== 'services') {
      this.leftMenuItems = menuItems;
    } else {
      this.serviceService.getTotalServiceList('').subscribe(services => {
        this.leftMenuItems = [];
        services.forEach(service => {
          this.leftMenuItems.push({ title: service.name, link: '/csv-list/' + service.id });
        });
      });
    }
  }

  setLang(lang) {
    this.sharedService.setLan(lang);
    this.lang = lang;
    this.translate.use(lang);
  }

  navigateToNotifications() {
    this.router.navigate(['notifications']);
  }
  navigateToNotification(value) {
    const route = value.referenceObject ? '/process/' + value.referenceObject.id + '/' + value.referenceObject.key : 'notifications';
    this.router.navigate([route]);
  }


  getUnseenNotifications() {
    this.notificationService.getUnseenCount().subscribe(count => {
      this.unseenNotifications = count['count'];
    });
  }

  onChange(event) {
    this.teamName = this.teams.find(elem => elem.id === event).name;
    localStorage.setItem('currentTeamId', event);
    localStorage.setItem('currentTeamName', this.teamName);
    this.sharedService.changeTeam(event);
  }

  onChangeType(event) {
    this.controlTypeName = this.controlTypes.find(elem => 
        (event === '' && elem.key === null) ||  elem.key === event).name;
    localStorage.setItem('currentControlType', event);
    localStorage.setItem('currentControlTypeName', this.controlTypeName);
    this.sharedService.changeControlType(event);
  }
}
