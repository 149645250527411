export class Service {
  id: number;
  name: string;
  code: string;
  type: string;
  defaultFileFormat: string;
  defaultCurrency: string;
  defaultBankAccount: string;
  mcasURL : string;
  canCreateCompanies: boolean;
  fileFormats: object[];
}
