import {Component, Input, OnInit} from '@angular/core';
import { ProcessService } from 'src/app/services/process.service';
import { saveAs } from 'file-saver';
import { UserService } from 'src/app/services/user.service';
import { Form } from '@angular/forms';
@Component({
  selector: 'app-dynamic-table',
  templateUrl: './dynamic-table.component.html',
  styleUrls: ['./dynamic-table.component.css']
})
export class DynamicTableComponent implements OnInit {
  @Input() table;
  @Input() processId: any;
  @Input() form: any;
  @Input() taskKey: any;
  @Input() createDate: any;
  user: any
  constructor(private processService: ProcessService, private userService: UserService) { }

  ngOnInit() {
    this.userService.getCurrentUser().subscribe(user => {
      this.user = user
    })
  }

  onChange(rowIndex, columnIndex, key, value) {
    const obj = { key: key, value: value};
    this.table.value.rows[rowIndex][columnIndex] = obj
  }

  onComponentChange(rowIndex, columnIndex, key, value, rowElem) {
    const obj = { key: key, value: value, targets: rowElem.targets };
    if (rowElem.targets) {
      obj.targets = rowElem.targets;
    }
    this.table.value.rows[rowIndex][columnIndex] = obj;
  }




  AddRowToTable() {
    var rowDefs = [];
    this.table.value.rows[0].forEach( col =>
        rowDefs.push({key: col.key})
    );
    this.table.value.rows.push(rowDefs);
    this.adjustAutoincrement()
  }

  deleteRow(rowIndex) {
    this.table.value.rows.splice(rowIndex, 1);
    this.adjustAutoincrement()
  }

  adjustAutoincrement(){

    var autoincrementElement = null
    for (let cIndex = 0; cIndex < this.table.value.columns.length; cIndex++){
      var colElement = this.table.value.columns[cIndex]
      autoincrementElement = colElement.find(el => el.type = 'autoincrement')
      if (autoincrementElement)
        break;
    }
    
    if (autoincrementElement){
      for (let rIndex = 0; rIndex < this.table.value.rows.length-1; rIndex++){
        var rowElement = this.table.value.rows[rIndex]
        var autoinc = rowElement.find(el => el.key===autoincrementElement.key)
        if (autoinc)
        autoinc.value = rIndex+1;
      }
    }
  }

  getCloneValue(cloneKey, rowIndex){
    var tblKey = cloneKey.split('.')
    if (tblKey.length>1){
      var formEl = this.form.elements.find(element => element.key === tblKey[0])
      if (formEl){
        for (let rIndex = 0; rIndex < formEl.value.rows.length-1; rIndex++){
          var rowElement = formEl.value.rows[rIndex]
          var valClone = rowElement.find(el => el.key===tblKey[1])
          if (valClone && rIndex === rowIndex){
            return valClone.value;
          }
          
        }
      }
    }
    else{
      return ''
    }
  }

  getYear(order){
    var date = new Date(this.createDate)
    if (date instanceof Date && isFinite(date.getTime()))
    return date.getFullYear() - order
    return (new Date().getFullYear())-order
  }

  findCol(columns, key ){
    if (columns !== undefined) {
      for (var c = 0; c<columns.length; c++) {
        for (var col = 0; col<columns[c].length; col++) {
          if (columns[c][col].key === key) {
            return  columns[c][col];
          }
        }
      }
    }
  }

  downloadTable(apiUrl) {
    this.processService.downloadTable(apiUrl+"/"+this.processId+"/"+this.taskKey+"/"+this.table.key).subscribe(table => {
          const blob = table;
          const file = new Blob([blob], {});
          saveAs(file, (this.table.title && this.table.title!==''?this.table.title:this.table.key) + '.xlsx'); })
  }

}
