import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-radio',
  templateUrl: './radio.component.html',
  styleUrls: ['./radio.component.css']
})
export class RadioComponent implements OnInit {
  @Input() radio;
  @Output() onValueSelected = new EventEmitter<any>();
  @Input() inTable = false;
  @Input() targets = [];
  @Input() rowKeyIndex;
  
  constructor() { }

  ngOnInit() {
    
  }

  onChange(event) {

    this.radio.value = event;
    this.onValueSelected.emit(event);
  }

}
