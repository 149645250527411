import {Component, Input, OnInit, AfterViewInit, Output, OnDestroy} from '@angular/core';
import {PagerDataObject} from "../../entities/pagerDataObject";
import { Subject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { PayeeSectorService } from 'src/app/services/payeeSector.service';
import { SharedService } from 'src/app/services/sharedService';

@Component({
  selector: 'payee-sectors',
  templateUrl: './payee-sectors.component.html',
  styleUrls: ['./payee-sectors.component.css']
})
export class PayeeSectorsComponent implements OnInit, AfterViewInit {

 
  metaInfo: string[];
  columns: Array<any>;
  data: PagerDataObject;
  @Input()
  headers = [' ', 'Name', 'Code', 'Higher Sector'];
  @Input()
  disableHeader = false;
  subscription: any;
  private dataSub$ = new Subject<any>();
  query: String;

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
    this.sharedService.loadMenuItems('admin');
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  constructor(private payeeSectorService: PayeeSectorService, private sharedService: SharedService) {
    this.subscription = this.dataSub$.pipe(switchMap(filter => {
      return this.payeeSectorService.getData(filter);
    }))
      .subscribe(data => {
        this.data = data;
      });
    this.metaInfo = this.payeeSectorService.getMetaInfo();
    this.columns = this.payeeSectorService.getColumns();
  }

  onGridQuery(query) {
    this.query = query
    this.dataSub$.next(query);
  }

  deleteUser(entity){
    // let company = new Company();
    // company.id = entity;
    // this.indicatorSectorService.delete(company)
    //   .subscribe(service => {
    //     this.dataSub$.next(this.query);
    //   });
  }

  deletePayeeSector(event){

  }

}
