/**
 * Created by aleco on 9/26/2017.
 */

import {Component, Input} from '@angular/core';
import {ServiceComponent} from "./service.component";
import {Service} from "../../../entities/service";

@Component({
  selector: '<ntr-service-mcas>',
  templateUrl: './service-mcas.component.html'
})
export class ServiceMcasComponent implements ServiceComponent{
  @Input() currencies: any[];
  bankAccounts: any[];
  @Input() service: Service;

  constructor() {

  }

}
