import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-rich-text-element',
  templateUrl: './rich-text-element.component.html',
  styleUrls: ['./rich-text-element.component.css']
})
export class RichTextElementComponent implements OnInit {

  @Input() richtext;
  @Output() onRichTextChange = new EventEmitter<any>();
  constructor() { }

  ngOnInit() {
  }

  showValue(event) {
    this.onRichTextChange.emit(event.editor.getContent());
  }

}
