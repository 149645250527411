import { Injectable, Output, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError, of, BehaviorSubject, ReplaySubject } from 'rxjs';

import { map, catchError, share } from 'rxjs/operators';
import { SharedService } from './sharedService';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Role } from '../entities/role';
import { User } from '../entities/user';
import { UserService } from './user.service';
import { environment } from 'src/environments/environment';


@Injectable()
export class AuthService {
  private username$ = new BehaviorSubject<string>(undefined);
  private userRole$ = new BehaviorSubject<string>(undefined);

  private authChangedSource = new ReplaySubject<boolean>(1);
  authChanged = this.authChangedSource.asObservable();
  accessToken: string
  constructor(private http: HttpClient, private sharedService: SharedService, private userService:UserService, public decoder: JwtHelperService) {  this.accessToken = sharedService.getAccessToken() }
 
  login(username: string, password: string): Observable<boolean> {
    return this.http.post<{ token: string }>(this.sharedService.apiUrl('auth/login'), { username: username, password: password })
      .pipe(
        map(result => {
         // if (result['statusCode'] === 200) {
            localStorage.setItem(this.accessToken, result.token);
            this.setUsername(username)
            this.setUserRole$(result['role'])
            return true;
        //  }
        }), catchError(
          
          (err) => throwError(err)
          
          )
      );
  }

  async requestToken(token) {
    let login$ = this.http.get<any>(environment.apihost+'/web/sso_auth/renewToken/' + token)
    const res = await login$.toPromise().then(res => res);
    return res.usrToken;
  }
  // logout() {
  // }

  logout() {
    let accessToken = localStorage.getItem('access_token');
    localStorage.removeItem('access_token');
    return this.http.get<any>(environment.apihost+'/web/logout'+ (accessToken ? "/?localToken=" + accessToken : ""))
  }

  public get isloggedIn(): boolean {
    return (localStorage.getItem('access_token') !== null);
  }

  @Output() loggedIn: EventEmitter<boolean> = new EventEmitter();
  setLoginStatus(obj) {
    this.loggedIn.emit(obj)
    if (!obj){
      this.clearLocalStorage();
    }
  }

  @Output() isUserActive: EventEmitter<boolean> = new EventEmitter();
  setUserEnabled(obj) {
    this.isUserActive.emit(obj)
  }

  clearLocalStorage(){
    localStorage.removeItem(this.accessToken)
    localStorage.removeItem('username')
    localStorage.removeItem('userrole')
  }

  setUsername(obj) {
    this.username$.next(obj);
  }

  setUserRole$(obj) {
    this.userRole$.next(obj);
  }

  getUsername$(): Observable<any> {
    return this.username$.asObservable();
  }

  getUserRole$(): Observable<any> {
    return this.userRole$.asObservable();
  }

  getDecodedToken(){
    return this.decoder.decodeToken(this.decoder.tokenGetter())
  }

  getCurrentUser(): Observable<User>{
      return this.userService.getCurrentUser()
  }

 
  getTokenFromNonce(nonce): Observable<boolean> {
    let token$ = this.http.get<any>(this.sharedService.apiUrlLogin('web/sso_auth') + "/?nonce=" + nonce)
    return token$;
  }

  getRedirectLink(): Observable<boolean> {
    return this.http.get<any>(this.sharedService.apiUrlLogin('web/sso_auth')).pipe(catchError(this.handleError<any>('getRedirectLink')))
  }

  handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);
      return throwError(error.error || 'Server error');
    };
  }

  log(message: string) {
    //this.messageService.add('HeroService: ' + message);
  }

}
