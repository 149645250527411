import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { share, switchMap } from 'rxjs/operators';
import { User } from 'src/app/entities/user';
import { ProcessService } from 'src/app/services/process.service';
import { SharedService } from 'src/app/services/sharedService';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-timesheet',
  templateUrl: './timesheet.component.html',
  styleUrls: ['./timesheet.component.css']
})
export class TimesheetComponent implements OnInit {
  process: any;
  @Input() user: User;
  caseActivity: any;
  uploadElement = {
    "type": "upload",
    "key": "file_upload",
    "title": null
  }
  constructor(private route: ActivatedRoute, private processService: ProcessService, private sharedService: SharedService) {
  }
  ngOnInit() {
    if (this.route.snapshot.paramMap.get('id')) {
      this.route.paramMap.pipe(
        switchMap((params: ParamMap) => this.processService.getProcess(params.get('id'))))
        .subscribe(process => {
          setTimeout(() => {
            this.process = process;
            this.caseActivity = this.process.timesheet.caseActivity
          }, 200);
        });
    }
  }

  fetch() {
    this.processService.getProcess(this.process.id)
      .subscribe(process => {
        setTimeout(() => {
          this.process = process;
          this.caseActivity = this.process.timesheet.caseActivity
        }, 200);
      });
  }

  contentChanged(event) {
    this.caseActivity = event.item
  }

  saveCaseActivity() {
    this.processService.saveTimesheetCaseActivity(this.process.id, this.caseActivity).subscribe(result => {
    })
  }

  onFileUpload(key, file) {
    this.process.timesheet.upload = { key: key, value: file, type: "upload", title: this.uploadElement.title }
    this.processService.uploadTimesheetDocument(this.process.id, this.process.timesheet.upload).subscribe(res => {

    })
  }

  deleteFromProcess() {
    this.process.timesheet.upload = null
    this.processService.uploadTimesheetDocument(this.process.id, this.process.timesheet.upload).subscribe(res => {

    })
  }
  submitSheets() {
    this.processService.submitTimesheet(this.process.id).subscribe(res => {
      this.fetch()
    })
  }
  getTab(name) {
    switch (name) {
      case 'active':
        break;
      case 'submitted':
        this.sharedService.submitTimesheet(this.process.timesheet)
        break;
      case 'approved':
        this.sharedService.submitTimesheet(this.process.timesheet)
        break;

      default:
        break;
    }
  }
  isEmpty(obj) {
    for (var prop in obj) {
      if (obj.hasOwnProperty(prop))
        return false;
    }

    return true;
  }

}
