import {Injectable} from '@angular/core';
import {Service} from "../entities/service";
import {SharedService} from "./sharedService";


import {PagerDataObject} from "../entities/pagerDataObject";
import {catchError, tap, map} from 'rxjs/operators';
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import { Subject, Observable } from 'rxjs';


const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};

@Injectable()
export class ServiceService {

  private serviceUpdate = new Subject<Service>();
  serviceUpdated$ = this.serviceUpdate.asObservable();

  constructor(private http: HttpClient, private sharedService: SharedService) {
  }

  // getServices(): Promise<Service[]> {
  //
  //   return this.http.get( this.sharedService.apiUrl('api/services', ""))
  //     .toPromise()
  //     .then(response => response.json() as Service[])
  //     .catch(this.handleError);
  //
  // //  return Promise.resolve(SERVICES);
  // }


  getData(query: string): Observable<PagerDataObject> {
    let services$ = this.http.get<PagerDataObject>(this.sharedService.apiUrl('api/services', "/" + query))
    return services$;
  }

  getTotalServiceList(query: string): Observable<Service[]> {
    let services$ = this.http.get<Service[]>(this.sharedService.apiUrl('api/services'))
    return services$;
  }

  getService(id: string): Observable<Service> {
    let service$ = this.http.get<Service>(this.sharedService.apiUrl('api/services/' + id))
    return service$;
  }

  add(service: Service): Observable<Service> {
    return this.http.post<Service>(this.sharedService.apiUrl('api/services/add'), service, httpOptions).pipe(
      tap((service: Service) => this.sharedService.log(`added service w/ id=${service.id}`)),
      catchError(this.sharedService.handleError<Service>('addService'))
    );
  }

  delete(service: Service): Observable<Service> {
    return this.http.post<Service>(this.sharedService.apiUrl('api/services/delete'), service, httpOptions).pipe(
      tap((service: Service) => this.sharedService.log(`deleted service w/ id=${service}`)),
      catchError(this.sharedService.handleError<any>('deleteService'))
    );
  }

  addFileFormat(fileFormat: any): Observable<any> {
    return this.http.post<any>(this.sharedService.apiUrl('api/services/addFileFormat'), fileFormat, httpOptions).pipe(
      tap(() => this.sharedService.log(`added file format`)),
      catchError(this.sharedService.handleError<any>('addFileFormat'))
    );
  }

  deleteFileFormat(fileFormat: any): Observable<any> {
    return this.http.post<any>(this.sharedService.apiUrl('api/services/deleteFileFormat'), fileFormat, httpOptions).pipe(
      tap(() => this.sharedService.log(`deleted file format`)),
      catchError(this.sharedService.handleError<any>('add'))
    );
  }

  updateService(Service: Service) {
    this.serviceUpdate.next(Service);
  }

  // getServicesSlowly(): Promise<Service[]> {
  //   return new Promise(resolve => {
  //     // Simulate server latency with 2 second delay
  //     setTimeout(() => resolve(this.getData()), 2000);
  //   });
  // }

  // getService(id: number): Promise<Service> {
  //   return this.getServices()
  //     .map(heroes => heroes.find(hero => hero.id === id));
  // }
  // getService(id: number): Observable<PagerDataObject> {
  //    return this.getData()
  //   //   .map(heroes => heroes.find(hero => hero.id === id));
  // }
  columns: Array<any> = [
    {key: 'view', style: {'flex': '0 0 100px', 'width': '1%'}, button: true},
    {name: 'Name', tag: 'SERVICE.NAME', key: 'name', filtering: true, sorting: true, sortingOrder: 'asc'},
    {name: 'Code', tag: 'SERVICE.CODE', key: 'code', filtering: true, sorting: true},
    //{name: 'Type', tag: 'SERVICE.TYPE', key: 'type', filtering: true, sorting: true}
  ];

  getMetaInfo(): string[] {
    return ['name', 'code'];
  }

  getColumns(): string[] {
    return this.columns;
  }

  downloadExcel(serviceId: any, fileFormatId: any, query): Observable<Blob> {

    const httpOptionsExcel = {
      headers: new HttpHeaders({ 'Accept': 'application/vnd.ms-excel', 'Content-Type': 'application/vnd.ms-excel' }),
      responseType: 'blob' as 'json',
      params: new HttpParams().set('serviceId', serviceId).set('fileFormatId', fileFormatId)
    };
    let process$ = this.http.get<any>(this.sharedService.apiUrl('api/services/excel/'+query), httpOptionsExcel).pipe(
      map((res: Response) => res), catchError(this.sharedService.handleError<any>('download excel'))
    )
    return process$;

  }

}
