import { Component, OnInit, TemplateRef } from '@angular/core';
import { switchMap } from 'rxjs/operators';
import { ParamMap, Router, ActivatedRoute } from '@angular/router';
import { Company } from 'src/app/entities/company';
import { CompanyService } from 'src/app/services/company.service';
import { ServiceService } from 'src/app/services/service.service';
import { CsvListService } from 'src/app/services/csvList.service';
import { Service } from 'src/app/entities/service';
import { Subject } from 'rxjs';
import { PagerDataObject } from 'src/app/entities/pagerDataObject';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ProcessService } from 'src/app/services/process.service';
import { FormControl, FormGroup } from '@angular/forms';
import { SharedService } from 'src/app/services/sharedService';

@Component({
    selector: 'app-company-add-edit',
    templateUrl: './company-add-edit.component.html',
    styleUrls: ['./company-add-edit.component.css']
})
export class CompanyAddEditComponent implements OnInit {
    filterForm: FormGroup;
    from = 0;
    to = 0;
    bigTotalItems = 0;
    bigCurrentPage = 1;
    currentPage = 1;
    maxSize = 10;
    company: Company = new Company();
    submitted = false;
    wrongCurrentPassword = false;
    usernameExists = false;
    pass1 = ""
    pass2 = ""
    currentPassword: string;
    pass1Wrong = false;
    pass2Wrong = false;
    noPass = false;
    changePass = false;
    datasets;
    subscription: any;
    currentFileFormatId: any;
    service: Service = new Service();
    metaInfo: string[];
    columns: Array<any>;
    startLoading: boolean = false;
    modalRef?: BsModalRef;
    data: PagerDataObject;
    query: String;
    processes
    totalCount = 0;
    respProcesses: any;
    private dataSub$ = new Subject<any>();
    taxIdColumn

    ngOnInit(): void {
        this.company = new Company();
        this.route.paramMap.pipe(
            switchMap((params: ParamMap) => this.companyService.getCompany(params.get('id'))))
            .subscribe(company => {
                if (company !== null) {
                    this.company = company;
                    this.companyService.getDatasets(this.company.tax_id).subscribe(datasets => {
                        this.datasets = datasets
                        if (datasets && datasets.length > 0) {
                            var serviceId = datasets[0]._id
                            var fileFormatId = datasets[0].file_format_id
                            this.serviceService.getService(serviceId)
                                .subscribe(service => {
                                    this.columns = [];
                                    this.service = service;
                                    if (this.service.fileFormats && this.service.fileFormats.length > 0) {
                                        this.currentFileFormatId = fileFormatId;
                                        var currentFileFormat = this.service.fileFormats.find(item => item['id'] == this.currentFileFormatId)
                                        currentFileFormat['mappingObjects'].forEach((mo) => {
                                            if (mo.type === '7') {
                                                this.taxIdColumn = mo.name
                                            }
                                            if (mo.type && mo.type !== 1)
                                                this.columns.push({
                                                    name: mo.name,
                                                    tag: mo.name,
                                                    key: mo.name,
                                                    description: mo.description,
                                                    filtering: mo.type === '7' ? false : true,
                                                    sorting: true,
                                                    sortingOrder: 'asc',
                                                    type: mo.type,
                                                    isNumber: mo.type === "3"
                                                });
                                        })
                                    }

                                    if (this.subscription) {
                                        this.dataSub$.next(this.query);
                                        this.subscription.unsubscribe();
                                    }
                                    this.subscription = this.dataSub$.pipe(switchMap(filter => {
                                        return this.csvListService.getData(filter + '&serviceId=' + this.service.id + ((this.currentFileFormatId === undefined) ? "" : '&fileFormatId=' + this.currentFileFormatId));
                                    }))
                                        .subscribe(data => {
                                            this.data = data;
                                            this.startLoading = false;
                                            if (this.service.fileFormats) {
                                                if (data.records.length > 0)
                                                    this.metaInfo = Object.keys(data.records[0])
                                            }
                                        });
                                });
                        }
                        this.processService.getMyProposals(this.company.id, this.from, this.maxSize,
                            null, this.filterForm.value.search).subscribe(processes => {
                                this.to = processes.records.length;
                                this.respProcesses = processes
                                this.processes = processes.records;
                                this.totalCount = processes.total;
                                this.setupPaginator();
                            });
                    }
                    )

                }
            });

        this.sharedService.addProcessEventEmitter.subscribe(teamId => {
            this.fireQueryEvent();
        });
    }
    setupPaginator(): void {
        this.bigTotalItems = this.totalCount = this.respProcesses.total;
        if (this.currentPage === 1) {
            if (this.respProcesses.total > 0) {

            }
            this.to = this.respProcesses.total < 11 ? this.respProcesses.total : 10;
        }
    }

    constructor(public companyService: CompanyService, private router: Router, private route: ActivatedRoute,
        private serviceService: ServiceService,
        private csvListService: CsvListService,
        private modalService: BsModalService,
        private sharedService: SharedService,
        private processService: ProcessService) {
        this.metaInfo = [];
        this.columns = [];
        this.filterForm = new FormGroup({
            search: new FormControl()
        });
    }
    onGridQuery(query) {
        this.query = query
        this.dataSub$.next(query + "&" + this.taxIdColumn + "=" + this.company.tax_id);
    }
    loadFormat(serviceId, fileFormatId) {

        this.serviceService.getService(serviceId)
            .subscribe(service => {
                this.service = service
                this.startLoading = true;
                this.currentFileFormatId = fileFormatId;
                this.columns = [];
                this.service.fileFormats.find(item => item['id'] === this.currentFileFormatId)['mappingObjects'].forEach((mo) => {
                    if (mo.type === '7') {
                        this.taxIdColumn = mo.name
                    }
                    if (mo.type && mo.type !== 1) {
                        this.columns.push({
                            name: mo.name,
                            tag: mo.name,
                            key: mo.name,
                            description: mo.description,
                            filtering: mo.type === '7' ? false : true,
                            sorting: true,
                            sortingOrder: 'asc',
                            type: mo.type
                        });
                    }

                });
                this.dataSub$.next(this.query);
            })
    }

    onSubmit() {
        this.company['user_update'] = true
        if (!this.company.id) {
            this.company['password'] = this.pass1
        }
        this.companyService.add(this.company)
            .subscribe(user => {
                if (user['error'] && user['error'] === 'USERNAME_EXISTS') {
                    this.usernameExists = true;
                }
                else {
                    this.submitted = true;

                    this.goToList();
                }

            });
    }

    goToList() {
        this.router.navigateByUrl('/company');
    }

    newProcess(template: TemplateRef<any>) {
        this.modalRef = this.modalService.show(template);
    }

    changePage(event) {
        if (!isNaN(event)) {
            this.from = (event - 1) * this.maxSize;
            this.to = this.maxSize
            this.setPage(event);
        }
    }

    setPage(pageNo: number): void {

        this.currentPage = pageNo;
        this.fireQueryEvent();
    }

    fireQueryEvent() {
        this.processService.
            getMyProposals(this.company.id, this.from, this.maxSize,
                null, this.filterForm.value.search)
            .subscribe(processes => {
                this.to = processes.records.length;

                this.respProcesses = processes;
                this.processes = processes.records;
                this.totalCount = processes.total;
                this.setupPaginator();
            });

    }

}
