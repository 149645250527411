import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { ProcessService } from '../../../services/process.service';
import { UserService } from '../../../services/user.service';
import { User } from '../../../entities/user';
import { ToastService } from '../../../services/toast-service';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-step',
  templateUrl: './step.component.html',
  styleUrls: ['./step.component.css'],
})
export class StepComponent implements OnInit {


 allowances = [
    {
      processStatus: 'PROPOSAL',
      step: "PROGRAMMATION",
      statuses: [
        {
          name: "ONGOING",
          actions: [
            { name: "SUBMIT", role: "CREATOR" },
            { name: "SAVE", role: "ALL" },
            { name: "APPROVE", role: "CONTROLLER" },
            { name: "APPROVE", role: "DIRECTOR_APPROVER" },
            { name: "APPROVE", role: "DIRECTOR_GENERAL" },
            { name: "REJECT", role: "CONTROLLER" },
            { name: "REJECT", role: "DIRECTOR_APPROVER" },
            { name: "REJECT", role: "DIRECTOR_GENERAL" }
          ]
        },
        {
          name: "SUBMITTED",
          actions: [
            { name: "APPROVE", role: "CHEF" },
            { name: "REJECT", role: "CHEF" }
          ]
        },
        {
          name: "REJECTED",
          actions: [
            { name: "SUBMIT", role: "CREATOR" },
            { name: "SAVE", role: "ALL" }
          ]
        },
        {
          name: "APPROVED",
          actions: [
            { name: "APPROVE", role: "COORDINATOR", isSingle: true },
            { name: "APPROVE", role: "CONTROLLER" },
            { name: "APPROVE", role: "DIRECTOR_APPROVER" },
            { name: "APPROVE", role: "DIRECTOR_GENERAL" },
            { name: "REJECT", role: "COORDINATOR" },
            { name: "REJECT", role: "CONTROLLER", },
            { name: "REJECT", role: "DIRECTOR_APPROVER" },
            { name: "REJECT", role: "DIRECTOR_GENERAL" },
          ]
        }
      ]
    },
    {
      processStatus: 'ONGOING',
      step: "EXECUTION",
      statuses: [
      {
        name: "ONGOING",
        actions: [
          { name:"ASSIGN", role:"CHEF"},
          { name: "SUBMIT", role: "TEAMMEMBER" },
          { name: "SAVE", role: "ALL" }
        ]
      },
      {
        name :"ASSIGNED",
        actions: [
        { name: "SUBMIT", role: "TEAMMEMBER" },
        { name: "SAVE", role: "ALL" }
        ]
      },
      {
        name: "SUBMITTED",
        actions: [
          { name: "VALIDATE", role: "CHEF" },
          { name: "REJECT", role: "CHEF" }
        ]
      },
      {
        name: "VALIDATED",
        actions: [
          { name: "APPROVE", role: "COORDINATOR" },
          { name: "REJECT", role: "COORDINATOR" }
        ]
      }
    
    
    ]
    }
  ]





  @Input()
  step: any;
  @Input()
  tasks: any;
  @Input()
  process: any;
  @Input()
  activeTaskKey: any;
  taskKey: any;
  user: User;
  stepTask;
  actionText: string;
  confirmAction: string;
  teamAssign;
  modalRef?: BsModalRef;
  modalTeamRef?: BsModalRef;
  modalDeadlineRef?: BsModalRef;
  userList: User[];
  deadlineDays
  @Output() onReloadProcess = new EventEmitter<any>();
  constructor(private processService: ProcessService, private userService: UserService,
    private modalService: BsModalService,
    public toastService: ToastService, private translateService: TranslateService) { }

  ngOnInit() {
    this.reloadProc(this.process.id)
    this.userService.getCurrentUser().subscribe(user => {
      this.user = user;
      this.userService.getTotalUserFlatList().subscribe(users => {
        this.userList = users;

        if (!this.activeTaskKey) {
          this.activeTaskKey = this.process.task.key;
        }
      });
    });
  }

  saveTask(taskKey) {
    this.taskKey = taskKey;
    this.stepTask = this.step.tasks.find(task => task.key === this.taskKey);
    this.processService.saveTask(this.process.id, this.taskKey, this.stepTask).subscribe(result => {
      this.showSuccess('CONTROL-MODULE.SAVED_TOAST');
      this.reloadProc(this.process.id);
    });
  }

  submitTask(taskKey) {
    this.taskKey = taskKey;
    const stepTask = this.step.tasks.find(task => task.key === this.taskKey);
    this.processService.submitTask(this.process.id, this.taskKey, stepTask).subscribe(result => {
      this.showSuccess('CONTROL-MODULE.SUBMITTED_TOAST');
      this.reloadProc(this.process.id);
    });
  }

  validateTask(taskKey) {
    this.taskKey = taskKey;
    this.processService.validateTask(this.process.id, this.taskKey).subscribe(result => {
      this.showSuccess('CONTROL-MODULE.VALIDATED_TOAST');
      this.reloadProc(this.process.id);
    });
  }

  approveTask(taskKey) {
    this.taskKey = taskKey;
    this.processService.approveTask(this.process.id, this.taskKey).subscribe(result => {
      this.showSuccess('CONTROL-MODULE.APPROVED_TOAST');
      this.reloadProc(this.process.id);
    });
  }

  rejectTask(taskKey) {
    this.taskKey = taskKey;
    this.processService.rejectTask(this.process.id, this.taskKey).subscribe(result => {
      this.showSuccess('CONTROL-MODULE.REJECTED_TOAST');
      this.reloadProc(this.process.id);
    });
  }

  assignTeam(taskKey, team) {
    this.taskKey = taskKey;
    this.processService.assignTeam(this.process.id, this.taskKey, team).subscribe(result => {
      this.showSuccess('CONTROL-MODULE.SUBMITTED_TOAST');
      this.reloadProc(this.process.id);
    });
  }

  alterDeadline(taskKey, action) {

    if (action === 'SUSPEND_DEADLINE')
      this.processService.requestDeadlineSuspend(this.process.id, taskKey, this.deadlineDays).subscribe(result => {
        this.showSuccess('CONTROL-MODULE.DEADLINE_SUSPENDED');
        this.reloadProc(this.process.id);
      });

    if (action === 'CONFIRM_SUSPEND')
      this.processService.confirmExtention(this.process.id, taskKey).subscribe(result => {
        this.showSuccess('CONTROL-MODULE.DEADLINE_EXTENTION_CONFIRMED');
        this.reloadProc(this.process.id);
      });

    if (action === 'REJECT_SUSPEND')
      this.processService.rejectExtention(this.process.id, taskKey).subscribe(result => {
        this.showSuccess('CONTROL-MODULE.DEADLINE_EXTENTION_REJECTED');
        this.reloadProc(this.process.id);
      });

  }


  reloadProc(id) {
    this.processService.getProcess(id).subscribe(pr => {
      this.onReloadProcess.emit(id);
      this.step.tasks = pr.tasks.filter(elem => elem.step === this.step.key);
    });
  }

  comment(comment, taskKey) {
    this.taskKey = taskKey;
    this.processService.comment(this.process.id, this.taskKey, comment).subscribe(result => {
      const stepTask = this.step.tasks.find(task => task.key === this.taskKey);
      const stepResTask = result.tasks.find(task => task.key === this.taskKey);
      stepTask.comments = stepResTask.comments;
    });
  }

  deleteComment(comment, taskKey) {
    this.taskKey = taskKey;
    this.processService.deleteComment(this.process.id, comment.key).subscribe(result => {
      const stepTask = this.step.tasks.find(task => task.key === this.taskKey);
      const stepResTask = result.tasks.find(task => task.key === this.taskKey);
      stepTask.comments = stepResTask.comments;

    });
  }

  exportTask(task) {
    this.processService.downloadTask(this.process.id, task.key).subscribe
      (data => {
        const blob = data;
        const file = new Blob([blob], {});
        let taskName = task.name
        if (this.process.uniqueId) {
          taskName = this.process.uniqueId + "_" + taskName
        }
        saveAs(file, taskName + '.pdf');
      });
  }


  contentChanged(event) {
    this.taskKey = event.taskKey;
    if (event.element.type === 'upload') {
      this.processService.updateValue(this.process.id, this.taskKey, event.itemIndex, event.element).subscribe(result => {
        //this.reloadProc(this.process.id);
      });
    }
  }

  showSuccess(text) {
    this.toastService.show(this.translateService.instant(text), { classname: 'bg-success text-light', delay: 2000 });
  }


  openModal(template: TemplateRef<any>, key, action) {
    this.confirmAction = action;
    switch (this.confirmAction) {
      case 'SUBMIT': {
        this.actionText = 'SURE_TO_SUBMIT_TASK';
        break;
      }
      case 'VALIDATE': {
        this.actionText = 'SURE_TO_VALIDATE_TASK';
        break;
      }
      case 'APPROVE': {
        this.actionText = 'SURE_TO_SUBMIT_APPROVE';
        break;
      }
      case 'REJECT': {
        this.actionText = 'SURE_TO_SUBMIT_REJECT';
        break;
      }
      default: {
        break;
      }
    }
    this.modalRef = this.modalService.show(template, { class: 'modal-sm', initialState: key });
  }

  openModalDeadline(template: TemplateRef<any>, key, action) {
    this.confirmAction = action;
    switch (this.confirmAction) {

      case 'SUSPEND_DEADLINE': {
        this.deadlineDays = 1;
        this.actionText = 'SUSPEND_DAYS';
        //   this.rejectTask(key);
        break;
      }

      case 'SUSPEND_DEADLINE_RESPONSE': {
        this.actionText = 'SUSPEND_DEADLINE_RESPONSE';
        //   this.rejectTask(key);
        break;
      }


      case 'REACTIVATE_DEADLINE': {
        this.actionText = 'SURE_TO_REACTIVATE_DEADLINE';
        //   this.rejectTask(key);
        break;
      }
      default: {
        break;
      }
    }
    this.modalDeadlineRef = this.modalService.show(template, { class: 'modal-sm', initialState: key });
  }

  openModalAssign(template: TemplateRef<any>, key, action) {
    if (key === null)
      key = this.process.tasks[1]['key']
    this.confirmAction = action;
    this.teamAssign = key;
    switch (this.confirmAction) {
      case 'SUBMIT': {
        this.actionText = 'SURE_TO_SUBMIT_TASK';
        // this.saveTask(key);
        break;
      }
      case 'VALIDATE': {
        this.actionText = 'SURE_TO_VALIDATE_TASK';
        // this.saveTask(key);
        break;
      }
      case 'APPROVE': {
        this.actionText = 'SURE_TO_SUBMIT_APPROVE';
        //  this.approveTask(key);
        break;
      }
      case 'REJECT': {
        this.actionText = 'SURE_TO_SUBMIT_REJECT';
        //   this.rejectTask(key);
        break;
      }
      case 'ASSIGN': {
        this.actionText = 'CONTROL_TEAM';
        //   this.rejectTask(key);
        break;
      }
      default: {
        break;
      }
    }
    this.modalTeamRef = this.modalService.show(template, { class: 'modal-sm', initialState: key });
  }
  missingMandatories(task) {
    let mandatories = false;
    for (let itIdx = 0; itIdx < task.items.length; itIdx++) {
      let item = task.items[itIdx]
      for (let elIdx = 0; elIdx < item.elements.length; elIdx++) {
        let element = item.elements[elIdx]
        if (element.mandatory && (!element.value || this.isEmpty(element.value))) {
          mandatories = true;
        }
      }
    }
    return mandatories
  }

  isEmpty(obj) {
    for (var prop in obj) {
      if (obj.hasOwnProperty(prop))
        return false;
    }

    return true;
  }

  isSavable(user, task) {

    return (user && ((!user.roles.includes('CONTROLLER') &&
      !user.roles.includes('COORDINATOR') &&
      !user.roles.includes('CHEF') &&
      !user.roles.includes('DIRECTOR_GENERAL') &&
      !user.roles.includes('DIRECTOR_APPROVER') && !task.active && task.mode === 'SAVABLE_WHEN_NOT_ACTIVE') ||
      (
        user.roles.includes('COORDINATOR') ||
        user.roles.includes('CHEF') ||
        user.roles.includes('DIRECTOR_GENERAL') ||
        user.roles.includes('DIRECTOR_APPROVER')
      )));
  }


  isStatusOk(roles, task, action): any {
   
    if (roles.length === 1 && roles.includes('INSPECTOR')) {
      return false;
    }
    if (task===null){
      if (action === 'ASSIGN') {
          return this.process.status !== 'PROPOSAL' && this.process.assignees && roles.includes('CHEF');
      }
    }
    
    const step = this.allowances.find(elem => elem.step === task.step && this.process.status===elem.processStatus)
    if (step) {
      const status = step.statuses.find(st => st.name === task.status)
      if (status) {
        const actions = status.actions.filter(act => act.name === action)
        if (actions) {
          for (var act = 0; act < actions.length; act++) {
            var allowanceAct = actions[act];
            if (allowanceAct.name === action && task.action){
              const roleAction = task === null ? null : task.action.acts.find(elem => elem.key === action);
              if (roleAction){
                if (allowanceAct.role === 'CREATOR' && this.user.username === this.process.createdBy){
                  return true;
                }
                if (allowanceAct.role === 'TEAMMEMBER' && this.process.assignees && this.process.assignees.includes(this.user.username)){
                  return true;
                }
                if (roleAction.roles){
                  for (var rr = 0; rr <  roleAction.roles.length; rr++) {
                    var element = roleAction.roles[rr]
                    if (element === allowanceAct.role && roles.includes(element) && roleAction.key===action){
                      return true
                    }
                  }
         
                }
              }else{
                if (allowanceAct.role === 'ALL'){
                  return true;
                }
              }
            }
          }


          // if (roleAction) {
          //   if (!roleAction.roles){
          //     const localRoleCreator = actions.find(elem => ['CREATOR'].includes(elem.role) && elem.name === roleAction.key )
          //     if (localRoleCreator && this.user.username === this.process.createdBy)
          //     return true;
          //     const localRoleSave = actions.find(elem => ['ALL'].includes(elem.role) && elem.name === roleAction.key )
          //     if (localRoleSave)
          //     return true;
          //   }
            
            // if (roleAction.roles) {
            //   for (var r = 0; r < roleAction.roles.length; r++) {
            //     var crtAct = roleAction.roles[r];
            //     for (var act = 0; act < actions.length; act++) {
            //       var allowanceAct = actions[act];
            //       if (allowanceAct.role === crtAct)
            //       return true;
            //     }
            //   }
            // } else {
              
            // }
          //}
          // const currentPerson = (roleAction && roleAction.roles) ? roles.some(item => roleAction.roles.includes(item)) : undefined
          // return currentPerson
        }

      }
    }

    return false;
  }

  isProposal() {
    return this.process.status === 'PROPOSAL'
  }


  isOngoing() {
    return this.process.status === 'ONGOING'
  }

  canValidate(roles) {
    return (roles.includes('CHEF'))
  }

  canApprove(roles) {

  return (roles.includes('CHEF') || 
          roles.includes('CONTROLLER') ||
          roles.includes('COORDINATOR') ||
          roles.includes('DIRECTOR_GENERAL') ||
          roles.includes('DIRECTOR_APPROVER'))
  }

  canSave(roles) {
    return (roles.includes('CONTROLLER') ||
      roles.includes('CHEF') ||
      roles.includes('COORDINATOR') ||
      roles.includes('DIRECTOR_GENERAL') ||
      roles.includes('DIRECTOR_APPROVER'))
  }

  canSubmit(roles) {
    return !(roles.includes('CONTROLLER') ||
      roles.includes('CHEF') ||
      roles.includes('COORDINATOR') ||
      roles.includes('DIRECTOR_GENERAL') ||
      roles.includes('DIRECTOR_APPROVER'))
  }


  isStatusOkXS(roles, task, action) {
    if (roles.length === 1 && roles.includes('INSPECTOR')) {
      return false;
    }
    if (task && task.action && task.active) {
      const actionSubmit = task.action.acts.find(elem => elem.key === action);
      if (action === 'VALIDATE') {
        if (actionSubmit && this.process.status === 'ONGOING' && task.status === 'SUBMITTED')
          return (roles.includes('CHEF'))
        return false
      }

      if (['SAVE', 'SUBMIT'].includes(action)) {
        if ((this.process.status === 'PROPOSAL' || this.process.status === 'ONGOING') && actionSubmit) {
          if (this.process.status === 'ONGOING' && (task.step === 'EXECUTION')) {
            return (!roles.includes('CONTROLLER') && !roles.includes('CHEF') &&
              !roles.includes('COORDINATOR') &&
              !roles.includes('DIRECTOR_GENERAL') &&
              !roles.includes('DIRECTOR_APPROVER'));
          }
          if (this.process.status === 'ONGOING' && (task.step === 'EVALUATION')) {

          }
          else {

            return (this.user.username === this.process.createdBy || (!roles.includes('CONTROLLER') &&
              !roles.includes('CHEF') &&
              !roles.includes('COORDINATOR') &&
              !roles.includes('DIRECTOR_GENERAL') &&
              !roles.includes('DIRECTOR_APPROVER')));
          }

        } else {
          if (this.process.status === 'ONGOING' && task.status !== 'SUBMITTED') {
            if (task.step === 'EXECUTION') {
              if (action === 'SUBMIT' && !actionSubmit) {
                return false; ``
              }
              return (!roles.includes('CONTROLLER') &&
                !roles.includes('COORDINATOR') &&
                !roles.includes('CHEF') &&
                !roles.includes('DIRECTOR_GENERAL') &&
                !roles.includes('DIRECTOR_APPROVER'));
            }
          }
        }
      }

      if (task.status !== 'REJECTED') {
        if (action === 'APPROVE') {
          const actionApprove = task.action.acts.find(elem => elem.key === action);
          if (actionApprove) {
            return (!actionApprove.roles || roles.some(item => actionApprove.roles.includes(item)));
          } else {

          }
        }
        if (action === 'REJECT') {
          const actionReject = task.action.acts.find(elem => elem.key === action);
          if (actionReject) {
            if (task.status === 'VALIDATED')
              return false;
            return (roles.some(item => actionReject.roles.includes(item)));
          } else {

          }
        }
      }
      if (action === 'ASSIGN') {
        const actionAssign = task.action.acts.find(elem => elem.key === action);
        if (this.process.status === 'ONGOING' && actionAssign) {
          return (roles.some(item => actionAssign.roles.includes(item)));
        } else {
        }

      }
    } else {
      if (action === 'ASSIGN') {
        if (task) {
          return false;
        }
        else {
          return this.process.status !== 'PROPOSAL' && this.process.assignees;
        }
      }
    }

    return false;



    // const actionSubmit = task.action.acts.find(elem => elem.key === action);
    // const actionApprove = task.action.acts.find(elem => elem.key === action);
    // if (actionSubmit) {
    //   if (this.process.status === 'PROPOSAL') {
    //     return(!roles.includes('CONTROLLER') &&
    //         !roles.includes('COORDINATOR') &&
    //         !roles.includes('DIRECTOR_GENERAL') &&
    //         !roles.includes('DIRECTOR_AAPROVER'));
    //
    //   } else {
    //   }
    // }
    // if (actionApprove) {
    //   if (this.process.status === 'PROPOSAL') {
    //     return (roles.some(item => actionApprove.roles.includes(item)));
    //   }
    //
    // }
    // if (roles.includes('CONTROLLER')) {
    //
    // }


    // if (this.user.roles.includes('CONTROLLER')) {
    //   return ['ONGOING', 'REJECTED'].includes(status);
    // } else {
    //   return ['SUBMITTED'].includes(status);
    // }
  }

  confirm() {
    switch (this.confirmAction) {

      case 'SUBMIT': {
        this.submitTask(this.modalService.config.initialState);
        this.modalRef.hide();
        break;
      }
      case 'VALIDATE': {
        this.validateTask(this.modalService.config.initialState);
        this.modalRef.hide();
        break;
      }
      case 'APPROVE': {
        this.approveTask(this.modalService.config.initialState);
        this.modalRef.hide();
        break;
      }
      case 'REJECT': {
        this.rejectTask(this.modalService.config.initialState);
        this.modalRef.hide();
        break;
      }
      default: {
        break;
      }
    }
  }

  confirmTeam(usersListSelect) {
    this.assignTeam(this.modalService.config.initialState, usersListSelect['activeItems']||[]);
    this.modalTeamRef.hide();
  }

  requestDeadlineExtention() {
    this.alterDeadline(this.modalService.config.initialState, this.confirmAction)
    this.modalDeadlineRef.hide();
  }

  alterDeadlineExtention(confirmation) {
    this.alterDeadline(this.modalService.config.initialState, confirmation ? "CONFIRM_SUSPEND" : "REJECT_SUSPEND")
    this.modalDeadlineRef.hide();
  }


}
