import {Injectable} from '@angular/core';
import {SharedService} from "./sharedService";
import {PagerDataObject} from "../entities/pagerDataObject";
import {catchError, map, tap} from 'rxjs/operators';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {ControlTeam} from "../entities/controlTeam";
import { Observable, of } from 'rxjs';

const httpOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/json'})
};

@Injectable()
export class CommentService {

    constructor(private http: HttpClient, private sharedService: SharedService) {
    }

    getData(query: string): Observable<PagerDataObject> {
        if (query !== null)
            var controlTeams$ = this.http.get<PagerDataObject>(this.sharedService.apiUrl('api/control-team', query !== null?("/" + query):""))
        return controlTeams$;
    }

    add(controlTeam: ControlTeam): Observable<ControlTeam> {
        if (controlTeam.id){
            return this.http.put<ControlTeam>(this.sharedService.apiUrl('api/control-team/'+controlTeam.id), controlTeam, httpOptions).pipe(
                tap((controlTeam: ControlTeam) => this.log(`added controlTeam w/ id=${controlTeam.id}`)),
                catchError(this.handleError<ControlTeam>('add'))
            );
        }
        return this.http.post<ControlTeam>(this.sharedService.apiUrl('api/control-team'), controlTeam, httpOptions).pipe(
            tap((controlTeam: ControlTeam) => this.log(`added controlTeam w/ id=${controlTeam.id}`)),
            catchError(this.handleError<ControlTeam>('add'))
        );

    }

    getControlTeam(id: string): Observable<ControlTeam> {
        let controlTeam$ = this.http.get<ControlTeam>(this.sharedService.apiUrl('api/control-team/' + id))
        return controlTeam$;
    }

    getMyControlTeams(): Observable<any> {
        let myControlTeams$ = this.http.get<any>(this.sharedService.apiUrl('api/control/team/my-teams'))
        return myControlTeams$;
    }

    delete(controlTeam: ControlTeam): Observable<ControlTeam> {
        return this.http.delete<any>(this.sharedService.apiUrl('api/control-team/'+ controlTeam.id)).pipe(
            tap((controlTeam: ControlTeam) => this.sharedService.log(`deleted batch process w/ id=${controlTeam.id}`)),
            catchError(this.sharedService.handleError<any>('deleteControlTeam'))
        );
    }





    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {

            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead

            // TODO: better job of transforming error for user consumption
            this.log(`${operation} failed: ${error.message}`);

            // Let the app keep running by returning an empty result.
            return of(result as T);
        };
    }

    private log(message: string) {
        //this.messageService.add('HeroService: ' + message);
    }
}
