import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable, of} from 'rxjs';
import {SharedService} from '../services/sharedService';
import {AuthService} from '../services/auth.service';
import { ControlTeamService } from '../services/controlTeam.service';
import { ProcessService } from '../services/process.service';

@Injectable({
    providedIn: 'root'
})

export class ControlModuleGuard implements CanActivate {
    constructor(private authService: AuthService, private router: Router, private processService: ProcessService) {
    }
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):Observable<boolean> {
        const roles = ['ADMIN','CONTROLLER', 'DIRECTOR_APPROVER', 'DIRECTOR_GENERAL', 'COORDINATOR', 'INSPECTOR', 'CHEF'];
        this.authService.getCurrentUser().subscribe(usr => {
          if (roles.some(item => usr.roles.includes(item))) {
              return of(true);
          } else {
                this.processService.isAssigned().subscribe(res => {
                    if (res.is_assigned){
                        return of(true);
                    }else{
                        this.router.navigate(['/']);
                        return of(false);
                    }
                })
             
          }

        });
        return of(true);
    }
}
