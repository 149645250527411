/**
 * Created by aleco on 9/26/2017.
 */

import {AfterViewInit, Component, Input, OnDestroy} from '@angular/core';
import {PagerDataObject} from '../../entities/pagerDataObject';
import {BatchProcessService} from '../../services/batchProcess.service';
import {BatchProcess} from '../../entities/batchProcess';
import { Subject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { SharedService } from 'src/app/services/sharedService';

@Component({
  selector: 'app-batch-process-list',
  templateUrl: './batch-process-list.component.html'
})
export class BatchProcessListComponent implements OnDestroy, AfterViewInit {

  constructor(private batchProcessService: BatchProcessService, private sharedService: SharedService) {
    this.subscription = this.dataSub$.pipe(switchMap(filter => {
      return this.batchProcessService.getData(filter);
    }))
      .subscribe(data => {
        this.data = data;
      });
    this.metaInfo = this.batchProcessService.getMetaInfo();
    this.columns = this.batchProcessService.getColumns();
  }
  metaInfo: string[];
  columns: Array<any>;
  data: PagerDataObject;
  @Input()
  headers = [' ', 'Service', 'Currency Code'];
  @Input()
  disableHeader = false;
  subscription: any;
  private dataSub$ = new Subject<any>();
  query: string;

  ngOnDestroy(): void {

    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
  ngAfterViewInit(): void {
    this.sharedService.loadMenuItems('admin');
  }

  fieldChanged(e) {
  }

  onGridQuery(query) {
    this.query = query;
    this.dataSub$.next(query);
  }
  deleteBatchProcess(entity) {
    const batchProcess = new BatchProcess();
    batchProcess.id = entity;
    this.batchProcessService.delete(batchProcess)
      .subscribe(() => {
        this.dataSub$.next(this.query);
      });
  }
}
