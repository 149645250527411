import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
@Injectable()
export class ExcelService {
constructor() { }
public exportAsExcelFile(json: any[], excelFileName: string): void {


  let objectMaxLength = []; 
  for (let i = 0; i < json.length; i++) {
		let value = <any>Object.values(json[i]);
		for (let j = 0; j < value.length; j++) {
			if (typeof value[j] == "number") {
				objectMaxLength[j] = 16;
			} else {
				objectMaxLength[j] =
					objectMaxLength[j] >= value[j].length
						? objectMaxLength[j]
						: value[j].length;
			}
		}
		let key = <any>Object.keys(json[i]);
		for (let j = 0; j < key.length; j++) {
			objectMaxLength[j] =
				objectMaxLength[j] >= key[j].length
					? objectMaxLength[j]
					: key[j].length;
		}
	}
  var wscols = []
  objectMaxLength.forEach(wid=>{
    wscols.push({wch:wid});
  })

  const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
  worksheet["!cols"] = wscols;
  const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
  const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
  this.saveAsExcelFile(excelBuffer, excelFileName);
}
private saveAsExcelFile(buffer: any, fileName: string): void {
   const data: Blob = new Blob([buffer], {type: EXCEL_TYPE});
   FileSaver.saveAs(data, fileName + '_export_' + new  Date().getTime() + EXCEL_EXTENSION);
}
}