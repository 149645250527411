import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.css']
})
export class CheckboxComponent implements OnInit {
  @Input() checkbox;
  selectedValues: any[];
  @Output() onValueSelected = new EventEmitter<any>();
  @Input() inTable = false;
  @Input() targets = [];
  constructor() {
  }

  ngOnInit() {
    if (!this.inTable){
      this.targets = this.checkbox.targets
    }
    this.selectedValues = this.checkbox.value ? this.checkbox.value :this.targets.map(el  => { return {key:el.key, value:false} });
  }

  onChange(event, key) {
    this.selectedValues.find(el => el.key === key).value = event.target.checked;
    this.checkbox.value = this.selectedValues;
    this.onValueSelected.emit(this.checkbox.value);
  }

  isChecked(key){
    return this.selectedValues.find(el => el.key === key).value;
  }
}
