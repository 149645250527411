


export class CsvMappingFields {
  IGNORE = 1
  CUSTOM = 2
  AMOUNT= 3
  DATE= 4
  CURRENCY= 5
  REVENUE_CODE= 6
  TAX_ID= 7
  COMPANY_NAME= 8
  COMPANY_CUSTOM_PROPERTY= 9
  LINK= 10
  dropDownList = [{"value": this.IGNORE, "name": "Ignore"}, {"value": this.CUSTOM, "name": "Custom"},
    {"value": this.AMOUNT, "name": "Amount"}, {"value": this.DATE, "name": "Date", format: ""},
    {"value": this.CURRENCY, "name": "Currency"},
    {"value": this.LINK, "name": "Link"},
    {"value": this.REVENUE_CODE, "name": "Revenue Code"},{"value":this.TAX_ID,"name":"Tax Id"},
    {"value": this.COMPANY_NAME, "name": "Company Name"}, {"value":this.COMPANY_CUSTOM_PROPERTY, "name": "Company Custom Property"}];
}
