import {Component, Input, OnInit } from '@angular/core';
import {PagerDataObject} from "../../entities/pagerDataObject";
import { Subject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { CompanyService } from 'src/app/services/company.service';
import { Company } from 'src/app/entities/company';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.css']
})
export class CompanyComponent implements OnInit {

 
  metaInfo: string[];
  columns: Array<any>;
  data: PagerDataObject;
  @Input()
  headers = [' ', 'Name', 'TaxId', 'First Name', 'Last Name'];
  @Input()
  disableHeader = false;
  subscription: any;
  private dataSub$ = new Subject<any>();
  query: String;

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {

  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  constructor(private companyService: CompanyService, private spinner: NgxSpinnerService) {
    this.spinner.show();
    this.subscription = this.dataSub$.pipe(switchMap(filter => {
      return this.companyService.getData(filter);
    }))
      .subscribe(data => {
        this.data = data;
        this.spinner.hide();
      });
    this.metaInfo = this.companyService.getMetaInfo();
    this.columns = this.companyService.getColumns();
  }

  onGridQuery(query) {
    this.query = query
    this.dataSub$.next(query);
  }

  deleteUser(entity){
    let company = new Company();
    company.id = entity;
    this.companyService.delete(company)
      .subscribe(service => {
        this.dataSub$.next(this.query);
      });
  }

}
