import { Injectable, Output, EventEmitter } from "@angular/core";
import { environment } from "../../environments/environment";
import { Observable, of, Subject, throwError } from "rxjs";
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';


@Injectable()
export class SharedService {

  private lan$ = new Subject<any>();
  public high_roles = ['DIRECTOR_APPROVER', 'DIRECTOR_GENERAL', 'CONTROLLER', 'COORDINATOR', 'CHEF']
  control_api_prf = "api/control"

  apiUrl(pathToAPI: string, parameters = ''): string {
    return environment.apihost + '/' + pathToAPI + '/' + parameters;
  }

  apiUrlLogin(pathToAPI: string, parameters = ''): string {
    return environment.apihost + '/' + pathToAPI
  }

  @Output() updateToken: EventEmitter<boolean> = new EventEmitter();
  updateAccessToken(){
    this.updateToken.emit(true)
  }

  @Output() changeFileFormat: EventEmitter<any> = new EventEmitter();
  // apiUrlLogin(pathToAPI: string, parameters = ''): string {
  //   return 'http://' + environment.apihost + '/' + pathToAPI
  // }


  @Output() menuItemsEmitter: EventEmitter<any> = new EventEmitter();

  @Output() teamChangedEventEmitter: EventEmitter<any> = new EventEmitter();
  changeTeam(teamId) {
    this.teamChangedEventEmitter.emit(teamId);
  }

  @Output() controlTypeChangedEventEmitter: EventEmitter<any> = new EventEmitter();
  changeControlType(type) {
    this.controlTypeChangedEventEmitter.emit(type);
  }

  @Output() addProcessEventEmitter: EventEmitter<any> = new EventEmitter();
  addProcess() {
    this.addProcessEventEmitter.emit();
  }

  @Output() childSelectPopulateEventEmitter: EventEmitter<any> = new EventEmitter();
  parentSelectValueChanged(value) {
    this.childSelectPopulateEventEmitter.emit(value);
  }


  @Output() reloadProcessEventEmitter: EventEmitter<any> = new EventEmitter();
  reloadProcess(value) {
    this.reloadProcessEventEmitter.emit(value);
  }

  @Output() submitTimesheetEmitter: EventEmitter<any> = new EventEmitter();
  submitTimesheet(value) {
    this.submitTimesheetEmitter.emit(value);
  }

  allMenuItems = {
    'indicator': [{ title: 'INDICATOR.TITLE', link: '/indicator-list' },
    { title: 'INDICATOR.RISK_MANAGEMENT', link: '/risk-results' }],
    // { title: 'INDICATOR.RISK_MAN_DASHBOARD', link: '/indicators' }]

    'admin': [{ title: 'HEADER.SERVICE_MANAGER', link: '/service-list' },
    { title: 'HEADER.USER_MANAGER', link: '/user-list' },
    { title: 'HEADER.BATCH_PROCESS_MANAGER', link: '/batch-process-list' },
    { title: 'HEADER.DASHBOARD_CONFIGURATION', link: '/dashboard-config' },
    { title: 'HEADER.CURRENCY_MANAGER', link: '/currency-list' },
    { title: 'HEADER.PAYEE_SECTORS', link: '/payee-sectors' },
    { title: 'HEADER.REGIONAL_OFFICER_SETUP', link: '/regional-officer' },
    { title: 'HEADER.INDICATOR_SECTORS', link: '/indicator-sectors' },
    { title: 'HEADER.CONTROL_TEAMS', link: '/control-team-list' },
    { title: 'HEADER.ADMIN', link: '/admin' }],
    "services": "services"
  }
  loadMenuItems(menuItems) {
    this.menuItemsEmitter.emit(this.allMenuItems[menuItems]);
  }

  changeFileFormatEmit(fileFormat) {
    this.changeFileFormat.emit(fileFormat);
  }

  getMenuFromUrl(url: string) {
    for (var key in this.allMenuItems) {
      var items = this.allMenuItems[key];
      if (typeof items === "string") {
        if (url.startsWith('/csv-list/')) {
          return "services";
        }
      } else {
        for (var x = 0; x < items.length; x++) {
          var item = items[x];
          if (item.link === url) {
            return this.allMenuItems[key]
          }
        }
      }
    }
    // this.allMenuItems.forEach(item => {

    // })
  }

  constructor(private http: HttpClient) {

  }

  handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);
      return throwError(error.error || 'Server error');
    };
  }

  // getAccessToken() {
  //   return environment.accessToken;
  // }


  log(message: string) {
    //this.messageService.add('HeroService: ' + message);
  }

  getLan$(): Observable<any> {
    return this.lan$.asObservable();
  }

  getLan() {
    return localStorage.getItem('lang');
  }

  setLan(lan) {
    localStorage.setItem('lang', lan);
    this.lan$.next(lan);
  }

  getAccessToken() {
    return environment.accessToken;
  }

  getSSOLink(): Observable<any> {
    let services$ = this.http.get<any>(this.apiUrl('web/sso_auth'))
    return services$;
  }

  @Output() notifyLoginEmmiter: EventEmitter<any> = new EventEmitter();
  notifyLogin(username) {
    this.notifyLoginEmmiter.emit(username)
  }


  downloadFromS3(key: any): Observable<Blob> {

    const httpOptionsExcel = {
      headers: new HttpHeaders({ 'Accept': 'application/vnd.ms-excel', 'Content-Type': 'application/vnd.ms-excel' }),
      responseType: 'blob' as 'json',
      params: new HttpParams().set('key', key)
    };
    let process$ = this.http.get<any>(this.apiUrl('api/contract/s3'), httpOptionsExcel).pipe(
      map((res: Response) => res), catchError(this.handleError<any>('download excel'))
    )
    return process$;

  }



}
