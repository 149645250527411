import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { BarChartComponent } from './components/charts/bar-chart/bar-chart.component';
import { DoughnutChartComponent } from './components/charts/doughnut-chart/doughnut-chart.component';
import { RadarChartComponent } from './components/charts/radar-chart/radar-chart.component';
import { PieChartComponent } from './components/charts/pie-chart/pie-chart.component';
import { ServiceListComponent } from './components/service/service-list.component';
import { ServiceEditComponent } from './components/service/service-edit/service-edit.component';
import { CurrencyListComponent } from './components/currency/currency-list.component';
import { CurrencyAddEditComponent } from './components/currency/currency-add-edit.component';
import { CsvListComponent } from './components/csvlist/csvlist.component';
import { DashboardConfigComponent } from './components/dashboard-config/dashboard-config.component';
import { DashboardEditComponent } from './components/dashboard-config/dashboard-edit/dashboard-edit.component';
import { BatchProcessListComponent } from './components/batch-process-manager/batch-process-list.component';
import { BatchProcessViewComponent } from './components/batch-process-manager/batch-process-view.component';
import { LoginComponent } from './components/login/login.component';
import { AuthGuard } from './guards/auth.guard';
import { UserListComponent } from './components/user/user-list.component';
import { UserAddEditComponent } from './components/user/user-add-edit/user-add-edit.component';
import { LandingPageComponent } from './components/landing-page/landing-page.component';
import { AdminComponent } from './components/admin/admin.component';
import { IndicatorListComponent } from './components/indicator/indicator-list.component';
import { IndicatorAddEditComponent } from './components/indicator/indicator-add-edit.component';
import { CompanyComponent } from './components/company/company.component';
import { CompanyAddEditComponent } from './components/company/company-add-edit/company-add-edit.component';
import { IndicatorSectorsComponent } from './components/indicator-sectors/indicator-sectors.component';
import { PayeeSectorsComponent } from './components/payee-sectors/payee-sectors.component';
import { PayeeSectorAddEditComponent } from './components/payee-sectors/payee-sector-add-edit/payee-sector-add-edit.component';
import { RiskResultsComponent } from './components/indicator/risk-results/risk-results.component';
import { RegionalOfficerComponent } from './components/regional-officer/regional-officer.component';
import { ControlTeamListComponent } from './components/control-team/control-team-list.component';
import { ControlTeamAddEditComponent } from './components/control-team/control-team-add-edit/control-team-add-edit.component';
import { ControlModuleComponent } from './components/control-module/control-module.component';
import { NotificationsComponent } from './components/control-module/notifications/notifications.component';
import { ProcessComponent } from './components/control-module/process/process.component';
import {TimelineComponent} from './components/control-module/timeline/timeline.component';
import {ControlModuleGuard} from './guards/control.module.guard';
import { ProposalComponent } from './components/proposal/proposal.component';
import { TimesheetComponent } from './components/control-module/timesheet/timesheet.component';

const routes: Routes = [
  { path: '', redirectTo: 'csv-list', pathMatch: 'full' },
  { path: 'll_onf',  component: LandingPageComponent },
  //fusion routes
  { path: 'admin',  component: AdminComponent, canActivate: [AuthGuard] },
  { path: 'bar-chart', component: BarChartComponent, canActivate: [AuthGuard]},
  { path: 'batch-process-list', component: BatchProcessListComponent, canActivate: [AuthGuard]},
  { path: 'batch-process-view/:id', component: BatchProcessViewComponent, canActivate: [AuthGuard]},
  { path: 'company',  component: CompanyComponent, canActivate: [AuthGuard]},
  { path: 'company-add-edit', component: CompanyAddEditComponent, canActivate: [AuthGuard]},
  { path: 'company-add-edit/:id', component: CompanyAddEditComponent, canActivate: [AuthGuard]},
  { path: 'control-team-add-edit', component: ControlTeamAddEditComponent, canActivate: [AuthGuard] },
  { path: 'control-team-add-edit/:id', component: ControlTeamAddEditComponent, canActivate: [AuthGuard] },
  { path: 'control-team-list', component: ControlTeamListComponent, canActivate: [AuthGuard] },
  { path: 'csv-list', component: CsvListComponent, canActivate: [AuthGuard], runGuardsAndResolvers: 'always' },
  { path: 'csv-list/:id', component: CsvListComponent, canActivate: [AuthGuard]},
  { path: 'currency-add-edit', component: CurrencyAddEditComponent, canActivate: [AuthGuard]},
  { path: 'currency-add-edit/:id', component: CurrencyAddEditComponent, canActivate: [AuthGuard]},
  { path: 'currency-list', component: CurrencyListComponent, canActivate: [AuthGuard]},
  { path: 'dashboard-add-edit', component: DashboardEditComponent, canActivate: [AuthGuard]},
  { path: 'dashboard-add-edit/:id', component: DashboardEditComponent, canActivate: [AuthGuard]},
  { path: 'dashboard-config', component: DashboardConfigComponent, canActivate: [AuthGuard]},
  { path: 'doughnut-chart', component: DoughnutChartComponent, canActivate: [AuthGuard]},
  { path: 'home',  component: HomeComponent, canActivate: [AuthGuard] },
  { path: 'indicator-add-edit', component: IndicatorAddEditComponent, canActivate: [AuthGuard] },
  { path: 'indicator-add-edit/:id', component: IndicatorAddEditComponent, canActivate: [AuthGuard] },
  { path: 'indicator-list', component: IndicatorListComponent, canActivate: [AuthGuard] },
  { path: 'indicator-sectors', component: IndicatorSectorsComponent, canActivate: [AuthGuard] },
  { path: 'login', component: LoginComponent },
  { path: 'notifications', component: NotificationsComponent, canActivate: [AuthGuard]},
  { path: 'payee-sectors', component: PayeeSectorsComponent, canActivate: [AuthGuard] },
  { path: 'payee-sector-add-edit/:id', component: PayeeSectorAddEditComponent, canActivate: [AuthGuard] },
  { path: 'pie-chart', component: PieChartComponent, canActivate: [AuthGuard]},
  { path: 'proposal/:id/:task/:companyId', component: ProposalComponent, canActivate: [AuthGuard]},
  { path: 'proposal/:id/:task', component: ProposalComponent, canActivate: [AuthGuard]},
  { path: 'radar-chart', component: RadarChartComponent, canActivate: [AuthGuard]},
  { path: 'regional-officer', component: RegionalOfficerComponent, canActivate: [AuthGuard] },
  { path: 'risk-results', component: RiskResultsComponent, canActivate: [AuthGuard] },
  { path: 'service-add-edit', component: ServiceEditComponent, canActivate: [AuthGuard]},
  { path: 'service-add-edit/:id', component: ServiceEditComponent, canActivate: [AuthGuard]},
  { path: 'service-list',  component: ServiceListComponent, canActivate: [AuthGuard]},
  { path: 'user-add-edit/:id', component: UserAddEditComponent, canActivate: [AuthGuard]},
  { path: 'user-list',  component: UserListComponent, canActivate: [AuthGuard]},

  //control module routes
  { path: 'control-module',  component: ControlModuleComponent, canActivate: [AuthGuard, ControlModuleGuard], data: {app:'cm'}},
  
  { path: 'process', component: ProcessComponent, canActivate: [AuthGuard, ControlModuleGuard], data: {app:'cm'}},
  { path: 'process/:id', component: ProcessComponent, canActivate: [AuthGuard, ControlModuleGuard], data: {app:'cm'}},
  { path: 'process/:id/:task', component: ProcessComponent, canActivate: [AuthGuard, ControlModuleGuard], data: {app:'cm'}},
  { path: 'process/:id/:task/:companyId', component: ProcessComponent, canActivate: [AuthGuard, ControlModuleGuard], data: {app:'cm'}},
  { path: 'timeline/:id', component: TimelineComponent, canActivate: [AuthGuard, ControlModuleGuard], data: {app:'cm'}},
  { path: 'timesheet/:id', component: TimesheetComponent, canActivate: [AuthGuard, ControlModuleGuard], data: {app:'cm'}}

  // { path: 'contract',  component: ContractComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    onSameUrlNavigation: 'reload',
    useHash: false,
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
