import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ProcessService } from 'src/app/services/process.service';
import { saveAs } from 'file-saver';
import { UserService } from 'src/app/services/user.service';
import { SharedService } from 'src/app/services/sharedService';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
@Component({
  selector: 'app-timesheet-table',
  templateUrl: './timesheet-table.component.html',
  styleUrls: ['./timesheet-table.component.css']
})
export class TimesheetTableComponent implements OnInit {
  @Input() table;
  @Input() processId: any;
  @Input() taskKey: any;
  @Input() editable = true;
  @Output() rowEdited = new EventEmitter<any>();
  skipColumns = ['col0'];
  editingIndex = -1
  user: any

  modalRef?: BsModalRef;
  constructor(private processService: ProcessService, private modalService: BsModalService,  private userService: UserService, private sharedService: SharedService) { }

  ngOnInit() {
    this.userService.getCurrentUser().subscribe(user => {
      this.user = user
      for (let ur = 0; ur < this.user.roles.length; ur++) {
        var userRole = this.user.roles[ur];
        if (this.sharedService.high_roles.includes(userRole)) {
          this.skipColumns = []
          break;
        }
      }
    })
  }

  onChange(rowIndex, columnIndex, key, value) {
    const obj = { key: key, value: value };
    this.table.rows[rowIndex][columnIndex] = obj
  }

  onComponentChange(rowIndex, columnIndex, key, value, rowElem) {
    const obj = { key: key, value: value, targets: rowElem.targets };
    if (rowElem.targets) {
      obj.targets = rowElem.targets;
    }
    this.table.rows[rowIndex][columnIndex] = obj;
  }

  editRow(editIndex){
    this.editingIndex = editIndex
  }

  saveRow(rowIndex){
    this.processService.addTimesheet(this.processId,this.table.rows[rowIndex]).subscribe(response => {
      this.processService.getProcess(this.processId).subscribe(pr => {
        this.table = pr.timesheet
        this.editingIndex = -1;
      
      })
    })
  }

  AddRowToTable() {
    var rowDefs = [];
    this.table.rows[0].forEach(col => {
      if (col.key)
        rowDefs.push({ key: col.key })
      if (col.user)
        rowDefs.push({ user: 'user' })
    }
    );
    this.processService.addTimesheet(this.processId,this.table.rows[this.table.rows.length-1]).subscribe(response => {
      this.processService.getProcess(this.processId).subscribe(pr => {
        this.table = pr.timesheet
        this.rowEdited.emit(null)
      })
     })
  }

  deleteRow() {
    this.modalRef.hide();
    let recToDelete = this.table.rows[parseInt(this.modalService.config.initialState.toString())].find(elem=>elem.id)
    if (recToDelete){
     this.processService.deleteTimesheetRecord(this.processId,recToDelete.id).subscribe(response => {
         this.processService.getProcess(this.processId).subscribe(pr => {
          this.table = pr.timesheet
          this.rowEdited.emit(null)
        })
     })
    }
  }

 

  openModal(template, rowIndex){
    this.modalRef = this.modalService.show(template, { class: 'modal-sm', initialState: rowIndex });
  }

  findCol(columns, key) {
    if (columns !== undefined) {
      for (var c = 0; c < columns.length; c++) {
        for (var col = 0; col < columns[c].length; col++) {
          if (columns[c][col].key === key) {
            return columns[c][col];
          }
        }
      }
    }
  }

  downloadTable(apiUrl) {
    this.processService.downloadTable(apiUrl + "/" + this.processId + "/timesheet").subscribe(table => {
      const blob = table;
      const file = new Blob([blob], {});
      saveAs(file, 'control_timesheets.xlsx');
    })
  }

  getWorktype(key) {
    var type = this.table.columns[0][1].targets.find(elem => elem.key === key[0])
    return type ? type.name : null
  }

}
