import {Component, Input, OnInit, ViewChild, ElementRef} from '@angular/core';
import {FileUploader} from "ng2-file-upload";


import {PagerDataObject} from "../../entities/pagerDataObject";

import { Subject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ContractService } from 'src/app/services/contract.service';
import { Contract } from 'src/app/entities/contract';
import { SharedService } from 'src/app/services/sharedService';
import { AuthService } from 'src/app/services/auth.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-contract-list',
  templateUrl: './contract.component.html',
  styleUrls: ['./contract.component.css']
})
export class ContractComponent implements OnInit {
  public uploader: FileUploader;
  @ViewChild("fileUpload", { static: false }) 
  uploadInput: ElementRef; 
  metaInfo: string[];
  columns: Array<any>;
  data: PagerDataObject;
  @Input()
  headers = [' ', 'Company', 'Description'];
  @Input()
  disableHeader = false;
  subscription: any;
  private dataSub$ = new Subject<any>();
  query: String;
  contract: Contract;

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {

  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  constructor(private contractService: ContractService, private sharedService: SharedService, public auth: AuthService,private spinner: NgxSpinnerService) {
    this.spinner.show();
    this.contract = new Contract();
    this.uploader = new FileUploader({url: sharedService.apiUrl('api/contract')+'upload', itemAlias:"contract"});
    this.uploader.onAfterAddingFile = (fileItem) => {
     
    }

    this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      this.spinner.show();
      this.uploader.clearQueue();
      this.uploadInput.nativeElement.value = "";
      this.contract = new Contract();
      this.contract.company = ""
      this.contract.description = ""
      this.dataSub$.next(this.query); 
    };

    this.subscription = this.dataSub$.pipe(switchMap(filter => {
      return this.contractService.getData(filter);
    }))
      .subscribe(data => {
        this.data = data;
        this.spinner.hide();
      });
    this.metaInfo = this.contractService.getMetaInfo();
    this.columns = this.contractService.getColumns();
  }

  onGridQuery(query) {
    this.query = query
    this.dataSub$.next(query);
  }

  deleteContract(entity){
    this.spinner.show();
    let contract = new Contract();
    contract.id = entity;
    this.contractService.delete(contract)
      .subscribe(service => {
        this.dataSub$.next(this.query);
      });
  }

  upload(uploader){
    this.spinner.show();
    this.uploader.options.headers =[]; 
    this.uploader.options.headers.push({ name: 'company', value: this.contract.company }); 
    this.uploader.options.headers.push({ name: 'description', value: this.contract.description }); 
    this.uploader.setOptions(this.uploader);
    uploader.uploadAll()
  }

}

