import { AfterViewInit, Component, OnInit } from '@angular/core';
import { AdminService } from 'src/app/services/admin.service';
import { SharedService } from 'src/app/services/sharedService';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit, AfterViewInit {
  admin: any;
  constructor(private adminService: AdminService, private sharedService: SharedService) {
    this.admin = {};
   }
   ngAfterViewInit(): void {
    this.sharedService.loadMenuItems('admin');
  }

  ngOnInit() {
    this.adminService.getAdminSettings()
    .subscribe(settings => {
      if (settings && settings.length > 0) {
      this.admin = settings[0];
      }

    });
  }


  onSubmit() {
    this.adminService.saveAdminSettings(this.admin)
      .subscribe(settings => {
        this.admin = settings;
      });
  }

}
