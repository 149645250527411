import {Injectable} from '@angular/core';
import {SharedService} from "./sharedService";
import {PagerDataObject} from "../entities/pagerDataObject";
import {catchError, tap} from 'rxjs/operators';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Indicator} from "../entities/indicator";
import { Observable, of } from 'rxjs';

const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};

@Injectable()
export class IndicatorService{

  constructor(private http: HttpClient, private sharedService: SharedService) {
  }

  getData(query: string): Observable<PagerDataObject> {
    let indicators$ = this.http.get<PagerDataObject>(this.sharedService.apiUrl('api/indicators', query !== null?("/" + query):""))
    return indicators$;
  }
  getDataRisks(query: string): Observable<PagerDataObject> {
    let indicators$ = this.http.get<PagerDataObject>(this.sharedService.apiUrl('api/risk', query !== null?(query):""))
    return indicators$;
  }

  getYears(): Observable<any> {
    let indicators$ = this.http.get<PagerDataObject>(this.sharedService.apiUrl('api/indicators/years',""))
    return indicators$;
  }

  add(indicator: Indicator): Observable<Indicator> {
    return this.http.post<Indicator>(this.sharedService.apiUrl('api/indicators/add'), indicator, httpOptions).pipe(
      tap((indicator: Indicator) => this.log(`added indicator w/ id=${indicator.id}`)),
      catchError(this.handleError<Indicator>('add'))
    );
  }

  getIndicatorResult(query: string): Observable<PagerDataObject> {
    let indicators$ = this.http.get<PagerDataObject>(this.sharedService.apiUrl('api/indicators/indicator_result', query !== null?(query):""))
    return indicators$;
  }

  getIndicator(id: string): Observable<Indicator> {
    let indicator$ = this.http.get<Indicator>(this.sharedService.apiUrl('api/indicators/' + id))
    return indicator$;
  }

  runIndicator(indicator: Indicator): Observable<Indicator> {
    return this.http.post<Indicator>(this.sharedService.apiUrl('api/indicators/run_indicator'), indicator, httpOptions).pipe(
      tap((indicator: Indicator) => this.log(`indicator executed w/ id=${indicator.id}`)),
      catchError(this.handleError<Indicator>('indicator'))
    );
  }

  delete(indicator: Indicator): Observable<Indicator> {
    return this.http.post<Indicator>(this.sharedService.apiUrl('api/indicators/delete'), indicator, httpOptions).pipe(
      tap((currency: Indicator) => this.sharedService.log(`deleted indicator w/ id=${indicator.id}`)),
      catchError(this.sharedService.handleError<any>('deleteIndicator'))
    );
  }

  columns: Array<any> = [
    {key: 'view', style: {'flex': '0 0 100px', 'width': '1%'}, button: true},
    {name: 'Indicator Name', tag: 'INDICATOR.NAME', key: 'name', filtering: true, sorting: true, sortingOrder: 'asc'},
  ];

  columnsResults: Array<any> = [
    {name: 'Tax id', tag: 'RISK-RESULT.TAX_ID', key: 'match', filtering: true, sorting: true, sortingOrder: 'asc'},
    {name: 'Company Name', tag: 'RISK-RESULT.COMPANY_NAME', key: 'companyName', filtering: true, sorting: true, sortingOrder: 'asc'},
    {name: 'Score', tag: 'RISK-RESULT.SCORE', key: 'score', filtering: true, sorting: true, sortingOrder: 'asc', isNumber:true, coloring: [{3:"#3ba52d"},{8:"#f6c945"},{100:"#f60e12"}]},
  ]; 

  columnsIndicatorRun: Array<any> = [
    {name: 'Match', tag: 'INDICATOR.MATCH', key: 'match', filtering: true, sorting: true, sortingOrder: 'asc'},
    {name: 'Company Name', tag: 'INDICATOR.COMPANY_NAME', key: 'companyName', filtering: true, sorting: true, sortingOrder: 'asc'},
    {name: 'Value1', tag: 'INDICATOR.VALUE1', key: 'value1', filtering: true, sorting: true, sortingOrder: 'asc', isNumber:true},
    {name: 'Value2', tag: 'INDICATOR.VALUE2', key: 'value2', filtering: true, sorting: true, sortingOrder: 'asc', isNumber:true},
    {name: 'Difference', tag: 'INDICATOR.DIFFERENCE', key: 'difference', filtering: true, sorting: true, sortingOrder: 'asc', isNumber:true},
    {name: 'Difference Percentage', tag: 'INDICATOR.DIFFERENCE_PERCENTAGE', key: 'percentage', filtering: true, sorting: true, sortingOrder: 'asc', isNumber:true},
  ];


  getMetaInfo(): string[] {
    return ['name'];
  }

  getColumns(): string[] {
    return this.columns;
  }


  getMetaResultsInfo(): string[] {
    return ['match','companyName','score'];
  }

  getColumnsResults(): string[] {
    return this.columnsResults;
  }


  getMetaIndicatorRunInfo(): string[] {
    return ['match','companyName','value1','value2','difference','percentage'];
  }

  getColumnsIndicatorRun(): string[] {
    return this.columnsIndicatorRun;
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  private log(message: string) {
    //this.messageService.add('HeroService: ' + message);
  }
}
