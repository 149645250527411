import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {NgbDate, NgbCalendar, NgbDateParserFormatter} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.css']
})
export class DateRangePickerComponent implements OnInit {
  @Output() valueChange = new EventEmitter();
  @Input() key;
  hoveredDate: NgbDate | null = null;

  fromDate: NgbDate | null;
  toDate: NgbDate | null;
  fromDateStr
  toDateStr

  constructor(private calendar: NgbCalendar, public formatter: NgbDateParserFormatter) {
    this.fromDate = calendar.getToday();
    //this.toDate = calendar.getNext(calendar.getToday(), 'd', 10);
  }

  onDateSelection(date: NgbDate) {
 
    if (!this.fromDate && !this.toDate) {
      this.fromDateStr = new Date(Date.UTC(date['year'], date['month']-1,  date['day'], 0, 0, 0, 0))
     
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
      this.toDateStr = new Date(Date.UTC(date['year'], date['month']-1,  date['day'], 0, 0, 0, 0))
      this.toDate = date;
    } else {
      this.toDate = null;
      this.toDateStr = null
      this.fromDate = date;
      this.fromDateStr = new Date(Date.UTC(date['year'], date['month']-1,  date['day'], 0, 0, 0, 0))
    }
    let res = {}
    res['fromDate'] = this.fromDateStr
    res['toDate'] = this.toDateStr
    res['key'] = this.key
    this.valueChange.emit(res)
  }
  ngOnInit(): void {

  }

  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date);
  }

  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }
}
