export enum CsvMappingFieldTypes {
  IGNORE = 1,
  CUSTOM = 2,
  AMOUNT = 3,
  DATE = 4,
  CURRENCY = 5,
  REVENUE_CODE = 6,
  TAX_ID = 7,
  COMPANY_NAME = 8,
  COMPANY_CUSTOM_PROPERTY = 9,
}
