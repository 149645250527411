import {Component, OnInit, TemplateRef} from '@angular/core';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {StepperEnum} from 'src/app/entities/stepperEnum';
import {ControlTeamService} from 'src/app/services/controlTeam.service';
import {ProcessService} from 'src/app/services/process.service';
import {User} from '../../entities/user';
import {UserService} from '../../services/user.service';
import {SharedService} from '../../services/sharedService';

@Component({
    selector: 'app-control-module',
    templateUrl: './control-module.component.html',
    styleUrls: ['./control-module.component.css']
})
export class ControlModuleComponent implements OnInit {
    stepperType = StepperEnum.List;
    teams: any[];
    steps = [];
    modalRef?: BsModalRef;
    user: User;

    constructor(private controlTeamService: ControlTeamService, private processService: ProcessService, private modalService: BsModalService,
                private userService: UserService, private sharedService: SharedService) {
    }

    ngOnInit() {
        this.userService.getCurrentUser().subscribe(user => {
            this.user = user;

            this.getSteps();
            this.controlTeamService.getMyControlTeams().subscribe(teams => {
                this.teams = teams;
            });
        });

        this.sharedService.controlTypeChangedEventEmitter.subscribe(controlType => {
            this.getSteps();
        });
    }

    getSteps(){
        setTimeout(() => {
            this.processService.getSteps(localStorage.getItem('currentControlType' )).subscribe(steps => {
                this.steps = steps;
                this.steps.unshift({
                    'name': 'All',
                    'key': 'ALL'
                });
                this.steps.push({
                    'name': 'Done',
                    'key': 'DONE'
                })
                if (this.user.roles.includes('DIRECTOR_APPROVER') ||
                this.user.roles.includes('DIRECTOR_GENERAL')){
                    this.steps.push({
                        'name': 'To Approve',
                        'key': 'TOAPPROVE'
                    })
                }
            });
        }, 1000)
       
    }

    newProcess(template: TemplateRef<any>) {
        this.modalRef = this.modalService.show(template);
    }
}
