import {Injectable} from '@angular/core';
import {SharedService} from "./sharedService";
import {PagerDataObject} from "../entities/pagerDataObject";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import { Observable } from 'rxjs';
import { Company } from '../entities/company';


const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};

@Injectable()
export class IfuService {

  constructor(private http: HttpClient, private sharedService: SharedService) {
  }

  getData(query: string): Observable<PagerDataObject> {
    let risks$ = this.http.get<PagerDataObject>(this.sharedService.apiUrl('api/ifus', "/" + query))
    return risks$;
  }

  getTotalIfuList(): Observable<any[]> {
    let risks$ = this.http.get<any[]>(this.sharedService.apiUrl(this.sharedService.control_api_prf+'/process/ifu/list'))
    return risks$;
  }

  getRiskList(query: string): Observable<any> {
    let risks$ = this.http.get<any[]>(this.sharedService.apiUrl('api/ifus/list', "?query="+query))
    return risks$;
  }
 
}
