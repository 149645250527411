import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { concat, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, map, switchMap, tap } from 'rxjs/operators';
import { Company } from 'src/app/entities/company';
import { CompanyService } from 'src/app/services/company.service';
import { ProcessService } from 'src/app/services/process.service';
import {SharedService} from '../../../services/sharedService';
import {ControlType} from '../../../entities/controlType';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-new-process',
  templateUrl: './new-process.component.html',
  styleUrls: ['./new-process.component.css']
})
export class NewProcessComponent implements OnInit {
  @Input()
  modalRef?: BsModalRef;

  companies$: Observable<Company[]>;
  companiesLoading = false;
  companiesInput$ = new Subject<string>();
  selectedCompany: any;

  controlTypes$: Observable<ControlType[]>;
  controlTypesLoading = false;
  controlTypesInput$ = new Subject<string>();
  selectedControl: any;
  creating = false;
  minLengthTerm = 3;

  constructor(private companyService: CompanyService, private processService: ProcessService, private sharedService: SharedService,
    private spinner: NgxSpinnerService) { }

  ngOnInit() {
    this.loadCompanies();
      this.processService.getControlTypeList('').subscribe(resp => {
          this.controlTypes$ = resp;
      });
  }

  loadCompanies() {
    this.companies$ = concat(
      of([]), // default items
      this.companiesInput$.pipe(
        filter(res => {
          return res !== null && res.length >= this.minLengthTerm
        }),
        distinctUntilChanged(),
        debounceTime(800),
        tap(() => this.companiesLoading = true),
        switchMap(term => {
          return this.getCompanies(term).pipe(
            catchError(() => of([])), // empty list on error
            tap(() => this.companiesLoading = false)
          );
        })
      )
    );
  }

  getCompanies(term: string = null): Observable<any> {
    return this.companyService.getCompanyList(term)
      .pipe(map(resp => {
        if (resp.Error) {
          throwError(resp.Error);
        } else {
          return resp.map(resp => { resp.fullName = resp.company_name + ' ' + resp.tax_id; return resp; });
        }
      },
      )
      );
  }
  
  onSubmit(): void {
    this.spinner.show();
    this.creating = true;
    var process = {};
    process['team'] = {};
    process['company'] = {};
    process['type'] = {}
    process['team']['id'] = localStorage.getItem('currentTeamId');
    process['team']['name'] = localStorage.getItem('currentTeamName');
    process['company']['id'] = this.selectedCompany.id;
    process['company']['name'] = this.selectedCompany.company_name;
    process['company']['tax_id'] = this.selectedCompany.tax_id;
    process['type']['key'] = localStorage.getItem("currentControlType");
    process['type']['name'] = localStorage.getItem("currentControlTypeName");
    this.processService.addProcess(process).subscribe(res => {
      this.modalRef.hide();
      this.spinner.hide()
      setTimeout(()=>{
        this.sharedService.addProcess();
      }, 1000)
      
    });
 
  }


}
