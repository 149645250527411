import { Component, OnInit } from '@angular/core';
import { ServiceService } from 'src/app/services/service.service';
import { AdminService } from 'src/app/services/admin.service';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.css']
})
export class LandingPageComponent implements OnInit {

  constructor(public serviceService: ServiceService, private adminService: AdminService ) { }
  initialService: any;
  infoText: string;
  ngOnInit() {
    this.serviceService.getTotalServiceList("").subscribe(services => {
      this.adminService.getAdminSettings()
      .subscribe(settings => {
        this.infoText = settings.landingPageText
      });
      if (services && services.length>0)
      this.initialService = services[0].id;
    });
  }

}
