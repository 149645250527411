
import {Injectable} from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SharedService } from './sharedService';
import { Observable, of } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';

    
const httpOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/json'})
  };

@Injectable()
export class AdminService {

    constructor(private http: HttpClient, private sharedService: SharedService) {
    }


    public getAdminSettings(){
        let settings$ = this.http.get<any>(this.sharedService.apiUrl('api/app_settings'))
      return settings$;
    }
 

    saveAdminSettings(admin: any): Observable<any> {
        return this.http.post<any>(this.sharedService.apiUrl('api/app_settings/save'), admin, httpOptions).pipe(
          tap((admin: any) => this.log(`saved admin account w/ id=${admin.id}`)),
          catchError(this.handleError<any>('save'))
        );
      }

      private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {
    
          // TODO: send the error to remote logging infrastructure
          console.error(error); // log to console instead
    
          // TODO: better job of transforming error for user consumption
          this.log(`${operation} failed: ${error.message}`);
    
          // Let the app keep running by returning an empty result.
          return of(result as T);
        };
      }
    
      private log(message: string) {
        //this.messageService.add('HeroService: ' + message);
      }

}