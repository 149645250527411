import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { NgSelectComponent } from '@ng-select/ng-select';
import { ControlTeam } from 'src/app/entities/controlTeam';
import { ControlTeamService } from 'src/app/services/controlTeam.service';
import { SharedService } from 'src/app/services/sharedService';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-control-team-add-edit',
  templateUrl: './control-team-add-edit.component.html',
  styleUrls: ['./control-team-add-edit.component.css']
})
export class ControlTeamAddEditComponent implements OnInit, AfterViewInit {
  controlTeam: ControlTeam = new ControlTeam();
  submitted = false;
  controllers: any[] = [];
  constructor(public controlTeamService: ControlTeamService, private router: Router,
              private route: ActivatedRoute, private sharedService: SharedService, private userService: UserService) {

  }
  @ViewChild('controllersListSelect', {static: false}) public controllersListSelect: NgSelectComponent;
  ngOnInit(): void {
    this.userService.getTotalUserListByRole('CONTROLLER').subscribe(users => {
      this.controllers = users;
      this.controlTeam = new ControlTeam();
      const id = this.route.snapshot.params.id;
      if (id) {
        this.controlTeamService.getControlTeam(id).subscribe(
          controlTeam => {
            if (controlTeam !== null) {
              this.controlTeam = controlTeam;
            }
          }
        );
      }
    });

  }

  ngAfterViewInit(): void {
    this.sharedService.loadMenuItems('admin');
  }


  onSubmit() {
    this.submitted = true;
    if (this.controllersListSelect['activeItems'])
    this.controlTeam.controllers = this.controllersListSelect['activeItems'];
    this.controlTeamService.add(this.controlTeam)
      .subscribe(controlTeam => {
        this.goToList();
      });
  }

  goToList() {
    this.router.navigateByUrl('/control-team-list');
  }

}
