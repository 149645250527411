import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-process-card',
  templateUrl: './process-card.component.html',
  styleUrls: ['./process-card.component.css']
})

export class ProcessCardComponent implements OnInit {
  @Input()
  process
  @Input()
  companyId
  @Input()
  onlyProg: boolean
  company_name?: string;
  step?: string;
  task?: string;
  status?: string;
  lastComment?: any;
  tax_id?: string;
  uniqueId?: string;
  lastActionBy: string;
  

  constructor() { }

  ngOnInit() {
    this.company_name = this.process['company']['name']
    this.tax_id = this.process['company']['tax_id']
    this.step = this.process['task']['step']
    this.task = this.process['task']['name']
    this.status = this.process['status']
    this.lastComment = this.process['comment']
    this.lastActionBy = this.process['lastActionBy']
    this.uniqueId = this.process['uniqueId']
  }


}
