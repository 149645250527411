import {Injectable} from '@angular/core';
import {SharedService} from "./sharedService";
import {PagerDataObject} from "../entities/pagerDataObject";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import { Observable } from 'rxjs';
import { Company } from '../entities/company';


const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};

@Injectable()
export class RiskService {

  constructor(private http: HttpClient, private sharedService: SharedService) {
  }

  getData(query: string): Observable<PagerDataObject> {
    let risks$ = this.http.get<PagerDataObject>(this.sharedService.apiUrl('api/risks', "/" + query))
    return risks$;
  }

  getTotalRiskList(): Observable<any[]> {
    let risks$ = this.http.get<any[]>(this.sharedService.apiUrl(this.sharedService.control_api_prf+'/process/risk/list'))
    return risks$;
  }

  getRiskList(query: string): Observable<any> {
    let risks$ = this.http.get<any[]>(this.sharedService.apiUrl('api/risks/list', "?query="+query))
    return risks$;
  }

  getRisk(id: string): Observable<Company> {
    let risks$ = this.http.get<Company>(this.sharedService.apiUrl('api/risks/' + id))
    return risks$;
  }

  getDatasets(id: string): Observable<any> {
    let datasets$ = this.http.get<any>(this.sharedService.apiUrl('api/risks/datasets' )+id)
    return datasets$;
  }

  columns: Array<any> = [
    {key: 'view', style: {'flex': '0 0 100px', 'width': '1%'}, button: true},
    {name: 'Name', tag: 'COMPANY.NAME', key: 'company_name', filtering: true, sorting: true, sortingOrder: 'asc'},
    {name: 'TaxID', tag: 'COMPANY.TAXID', key: 'tax_id', filtering: true, sorting: true},
    {name: 'First Name', tag: 'COMPANY.FIRSTNAME', key: 'first_name', filtering: true, sorting: true},
    {name: 'Last Name', tag: 'COMPANY.LASTNAME', key: 'last_name', filtering: true, sorting: true}
  ];

  getMetaInfo(): string[] {
    return ['company_name','tax_id', 'first_name', 'last_name'];
  }

  getColumns(): string[] {
    return this.columns;
  }
}
