import {Injectable} from '@angular/core';
import {Service} from "../entities/service";
import {SharedService} from "./sharedService";
import {PagerDataObject} from "../entities/pagerDataObject";
import {catchError, tap} from 'rxjs/operators';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import { Subject, Observable } from 'rxjs';
import { Contract } from '../entities/contract';


const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};

@Injectable()
export class ContractService {

  private serviceUpdate = new Subject<Contract>();
  serviceUpdated$ = this.serviceUpdate.asObservable();

  constructor(private http: HttpClient, private sharedService: SharedService) {
  }

  getData(query: string): Observable<PagerDataObject> {
    let contracts$ = this.http.get<PagerDataObject>(this.sharedService.apiUrl('api/contract', "/" + query))
    return contracts$;
  }

  getTotalContractList(query: string): Observable<Contract[]> {
    let contracts$ = this.http.get<Contract[]>(this.sharedService.apiUrl('api/contract'))
    return contracts$;
  }

  getContract(id: string): Observable<Contract> {
    let contract$ = this.http.get<Contract>(this.sharedService.apiUrl('api/contract/' + id))
    return contract$;
  }

  getContractById(id: string): Observable<Contract> {
    let contract$ = this.http.get<Contract>(this.sharedService.apiUrl('api/contract/byId/' + id))
    return contract$;
  }

  add(contract: Contract): Observable<Contract> {
    return this.http.post<Contract>(this.sharedService.apiUrl('api/contract/add'), contract, httpOptions).pipe(
      tap((contract: Contract) => this.sharedService.log(`added contract w/ id=${contract.id}`)),
      catchError(this.sharedService.handleError<Contract>('addContract'))
    );
  }

  delete(contract: Contract): Observable<Service> {
    return this.http.post<Contract>(this.sharedService.apiUrl('api/contract/delete'), contract, httpOptions).pipe(
      tap((contract: Contract) => this.sharedService.log(`deleted contract w/ id=${contract}`)),
      catchError(this.sharedService.handleError<any>('deleteContract'))
    );
  }

  updateService(contract: Contract) {
    this.serviceUpdate.next(contract);
  }

  columns: Array<any> = [
    {key: 'view', style: {'flex': '0 0 100px', 'width': '1%'}, button: true},
    {name: 'Company', tag: 'CONTRACT.COMPANY', key: 'company', filtering: true, sorting: true, sortingOrder: 'asc'},
    {name: 'Description', tag: 'CONTRACT.DESCRIPTION', key: 'description', filtering: true, sorting: true, sortingOrder: 'asc'},
  ];

  getMetaInfo(): string[] {
    return ['company','description'];
  }

  getColumns(): string[] {
    return this.columns;
  }
}
