import {Injectable} from '@angular/core';
import {SharedService} from './sharedService';
import {PagerDataObject} from '../entities/pagerDataObject';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { Observable } from 'rxjs';

const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};

@Injectable()
export class CsvListService {

  constructor(private http: HttpClient, private sharedService: SharedService) {
  }

  columns: Array<any> = [
    {key: 'view', style: {flex: '0 0 100px', width: '1%'}, button: true},
    {name: 'Name', tag: 'SERVICE.NAME', key: 'name', filtering: true, sorting: true, sortingOrder: 'asc'},
    {name: 'Code', tag: 'SERVICE.CODE', key: 'code', filtering: true, sorting: true},
    {name: 'Type', tag: 'SERVICE.TYPE', key: 'type', filtering: true, sorting: true}
  ];

  getData(query: string): Observable<PagerDataObject> {
    const payments$ = this.http.get<PagerDataObject>(this.sharedService.apiUrl('api/csv-list', '/' + query));
    return payments$;
  }

  getMetaInfo(): string[] {
    return ['name', 'code', 'type'];
  }

  getColumns(): string[] {
    return this.columns;
  }
}
