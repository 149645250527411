import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ControlTeam } from 'src/app/entities/controlTeam';
import { PagerDataObject } from 'src/app/entities/pagerDataObject';
import { ControlTeamService } from 'src/app/services/controlTeam.service';
import { SharedService } from 'src/app/services/sharedService';

@Component({
  selector: 'app-control-team-list',
  templateUrl: './control-team-list.component.html',
  styleUrls: ['./control-team-list.component.css']
})
export class ControlTeamListComponent implements OnInit, AfterViewInit, OnDestroy {

  metaInfo: string[];
  columns: Array<any>;
  data: PagerDataObject;
  @Input()
  disableHeader = false;
  subscription: any;
  private dataSub$ = new Subject<any>();
  query: String;

  constructor(private controlTeamService: ControlTeamService, private sharedService: SharedService) {
    this.subscription = this.dataSub$.pipe(switchMap(filter => {
      return this.controlTeamService.getData(filter);
    }))
      .subscribe(data => {
        this.data = data;
        this.data.records.forEach(element => {
          element['id']=element['_id']
        });
      });
    this.metaInfo = this.controlTeamService.getMetaInfo();
    this.columns = this.controlTeamService.getColumns();
  }

  onGridQuery(query) {
    this.query = query
    this.dataSub$.next(query);
  }


  ngOnInit() {
  }

  ngAfterViewInit(): void {
    this.sharedService.loadMenuItems('admin');
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  deleteControlTeam(entity) {
    let controlTeam = new ControlTeam();
    controlTeam.id = entity;
    this.controlTeamService.delete(controlTeam)
      .subscribe(controlTeam => {
        this.dataSub$.next(this.query);
      });
  }


}
