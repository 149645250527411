import { Component, OnInit, ViewChild, ComponentFactoryResolver } from '@angular/core';
import { DashboardTypeDirective } from 'src/app/directives/dashboard-type.directive';
import { Dashboard } from 'src/app/entities/dashboard';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { DashboardService } from 'src/app/services/dashboard.service';
import { switchMap } from 'rxjs/operators';
import { ServiceService } from 'src/app/services/service.service';

@Component({
  selector: 'ntr-dashboard-edit',
  templateUrl: './dashboard-edit.component.html',
  styleUrls: ['./dashboard-edit.component.css']
})
export class DashboardEditComponent implements OnInit {

  @ViewChild(DashboardTypeDirective, { static: false }) serviceTypeHost: DashboardTypeDirective;
  dashboard: Dashboard = new Dashboard();
  dashboardTypes: Array<any> = [
    { name: 'Multi', tag: 'DASHBOARD.MULTI', key: 'MULTI' },
    { name: 'Stacked', tag: 'DASHBOARD.STACKED', key: 'STACKED' },
    { name: 'Bar', tag: 'DASHBOARD.BAR', key: 'BAR' },
    { name: 'Pie', tag: 'DASHBOARD.PIE', key: 'PIE' }
  ];


  datePeriods: Array<any> = [
    { name: ' --- NONE --- ', tag: 'DASHBOARD.NONE', key: '0' },
    { name: 'Last Month', tag: 'DASHBOARD.LAST_MONTH', key: '1' },
    { name: 'Last 3 Months', tag: 'DASHBOARD.LAST_3_MONTH', key: '3' },
    { name: 'Last 6 Months', tag: 'DASHBOARD.LAST_6_MONTH', key: '6' },
    { name: 'Last Year', tag: 'DASHBOARD.LAST_YEAR', key: '12' },
    { name: 'Last 2 Years', tag: 'DASHBOARD.LAST_2_YEARS', key: '24' }
  ];

  services = [];
  fileFormats = [];
  values = [];
  dates = [];
  allCols = [];
  submitted = false;
  _dashboardType: string;
  _dashboardTypeStr: string;

  serviceId: String;
  serviceName: String;
  fileFormatId: String;
  fileFormatName: String;
  valueCol: String;
  dateCol: String;
  datePeriod: String;
  groupCol1: String;
  groupCol2: String;
  groupCol1Val: String;
  groupCol2Val: String;

  constructor(public dashboardService: DashboardService, public serviceService: ServiceService, private router: Router, private route: ActivatedRoute, private componentFactoryResolver: ComponentFactoryResolver) {
    dashboardService.dashboardUpdated$.subscribe(
      dashboard => {
        this.dashboard = dashboard;
      });
  }

  onSubmit() {
    this.dashboard.type = this._dashboardType;
    this.submitted = true;
    if (this.dashboard.periodFilter===false){
      this.dashboard.datePeriod = null
    }
    if (this.dashboard.rangeFilter===false){
      this.dashboard.dateRange = null
    }
   

    this.dashboardService.add(this.dashboard)
      .subscribe(service => {
        this.router.navigateByUrl('/dashboard-config');
      });
  }

  onChange(dashboardType) {
    this._dashboardType = dashboardType;

  }

  onChangeService(serviceId) {
    this.serviceService.getService(serviceId).subscribe(service => {
      this.serviceName = service.name
      if (service.fileFormats && service.fileFormats.length > 0) {
        this.fileFormats = service.fileFormats
        this.values = [];
        this.dates = [];
        this.allCols = [];
      }
    });
  }

  onChangeFileFormat(fileFormatId) {
    var ff = this.fileFormats.filter(ff => ff.id === fileFormatId);
    this.fileFormatName = ff[0]['name']
    this.values = ff[0]['mappingObjects'].filter(mo => mo.type === "3")
    this.dates = ff[0]['mappingObjects'].filter(mo => mo.type === "4")
    this.allCols = ff[0]['mappingObjects']
  }
  dashboardConfigs = []
  addConfig() {

    var dashboardConfigObj = {
      "service": this.serviceName,
      "serviceId": this.serviceId,
      "fileFormat": this.fileFormatName,
      "fileFormatId": this.fileFormatId,
      "valueCol": this.valueCol,
      "dateCol": this.dateCol,
      "groupCol1": this.groupCol1,
      "groupCol2": this.groupCol2,
      "groupCol1Val": this.groupCol1Val,
      "groupCol2Val": this.groupCol2Val
    }
    if (!this.dashboard.dashboardConfigs) {
      this.dashboard.dashboardConfigs = []
    }
    this.dashboard.dashboardConfigs.push(dashboardConfigObj);

  }

  removeDashboardConfig(index) {
    this.dashboard.dashboardConfigs.splice(index, 1);
  }

  goToList() {
    this.router.navigateByUrl('/dashboard-config');
  }

  ngOnInit(): void {
    this.dashboard = new Dashboard();
    this.dashboard.rangeFilter = false
    this.dashboard.periodFilter = false
    this.serviceService.getTotalServiceList("").subscribe(services => {
      this.services = services;
      this.route.paramMap.pipe(
        switchMap((params: ParamMap) => this.dashboardService.getDashboard(params.get('id'))))
        .subscribe(dashboard => {
          if (dashboard !== null) {
            this.dashboard = dashboard;
            this.dashboardTypes.forEach((element) => {
              if (element.key === this.dashboard.type) {
                this._dashboardType = element.key;
                this._dashboardTypeStr = this.dashboard.type;
              }
            });
            if (this.dashboard.dateRange)
            this.dashboard.dateRange = [new Date(this.dashboard.dateRange[0]), new Date(this.dashboard.dateRange[1])]
          }
        });
    });
  }


  setDateFilter(event, filterType) {
    if (event.srcElement.checked) {
      if (filterType === 'period') {
        this.dashboard.periodFilter = true
        this.dashboard.rangeFilter = false
      } else {
        this.dashboard.rangeFilter = true
        this.dashboard.periodFilter = false
      }
    } else {
      this.dashboard.rangeFilter = false
      this.dashboard.periodFilter = false
    }
  }
}
