import { Component, Input, OnInit } from '@angular/core';
import { SharedService } from 'src/app/services/sharedService';

@Component({
  selector: 'app-risk-management',
  templateUrl: './risk-management.component.html',
  styleUrls: ['./risk-management.component.css']
})



export class RiskManagementComponent implements OnInit {
  @Input() indicator
  val = [33,66]
  currentHeader = "Default";

  riskSektors = [{
    header:"Default",
    title:"Default"
  },
  {
    header:"IFU1",
    title:"SECTEUR INDUSTRIE"
  },{
    header:"IFU2",
    title:"SECTEUR COMMERCE GENERAL"
  },{
    header:"IFU3",
    title:"SECTEUR BANQUES, ASSURENCES ET NTIC"
  }
,{
  header:"IFU4",
  title:"FORETS, PETROLE, MINE"
},
{
  header:"IFU5",
  title:"TRANSPORT, HOTELERIE, SERVICE"
}]
  constructor(private sharedService: SharedService) { }

  ngOnInit() {
    if (this.indicator[this.currentHeader]['r1'] &&
    this.indicator[this.currentHeader]['r2']&&
    this.indicator[this.currentHeader]['r3'])
    this.val = [ this.indicator[this.currentHeader]['r2'][0],
                 this.indicator[this.currentHeader]['r3'][0]
                ]
  }

  change(event){
    this.val = event.newValue;
    this.indicator[this.currentHeader]['r1'] = [0,this.val[0]]
    this.indicator[this.currentHeader]['r2'] = [this.val[0]+1,this.val[1]-1]
    this.indicator[this.currentHeader]['r3'] = [this.val[1],100]
  }

  onTabChange(tab){
    this.currentHeader = tab.nextId
    if (this.indicator[this.currentHeader]['r1'] &&
    this.indicator[this.currentHeader]['r2']&&
    this.indicator[this.currentHeader]['r3'])
    this.val = [ this.indicator[this.currentHeader]['r2'][0],
                 this.indicator[this.currentHeader]['r3'][0]
                ]
  }

}
