import {Injectable} from '@angular/core';
import {BankAccount} from "../entities/bankAccount";
import {catchError, tap} from 'rxjs/operators';

import {PagerDataObject} from "../entities/pagerDataObject";

import {HttpClient, HttpHeaders} from "@angular/common/http";
import {SharedService} from "./sharedService";
import { Observable, of } from 'rxjs';

const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};

@Injectable()
export class BankService {


  constructor(private http: HttpClient, private sharedService: SharedService) {
  }


  getData(query: string): Observable<PagerDataObject> {
    let currencies$ = this.http.get<PagerDataObject>(this.sharedService.apiUrl('api/bank_accounts', query !== null ? ("/" + query) : ""))
    return currencies$;
  }

  add(bankAccount: BankAccount): Observable<BankAccount> {
    return this.http.post<BankAccount>(this.sharedService.apiUrl('api/bank_accounts/add'), bankAccount, httpOptions).pipe(
      tap((bankAccount: BankAccount) => this.log(`added bank account w/ id=${bankAccount.id}`)),
      catchError(this.handleError<BankAccount>('add'))
    );
  }

  getBankAccount(id: string): Observable<BankAccount> {
      let bankAccount$ = this.http.get<BankAccount>(this.sharedService.apiUrl('api/bank_accounts/' + id))
      return bankAccount$;
  }

  delete(bankAccount: BankAccount): Observable<BankAccount> {
    return this.http.post<BankAccount>(this.sharedService.apiUrl('api/bank_accounts/delete'), bankAccount, httpOptions).pipe(
      tap((bankAccount: BankAccount) => this.sharedService.log(`deleted bank account w/ id=${bankAccount.id}`)),
      catchError(this.sharedService.handleError<any>('deleteBankAccount'))
    );
  }

  columns: Array<any> = [
    {key: 'view', style: {'flex': '0 0 100px', 'width': '1%'}, button: true},
    {
      name: 'Account Name',
      tag: 'BANK.ACCOUNT.NAME',
      key: 'accountName',
      filtering: true,
      sorting: true,
      sortingOrder: 'asc'
    },
    {name: 'Account Number', tag: 'BANK.ACCOUNT.NUMBER', key: 'accountNumber', filtering: true, sorting: true},
    {name: 'Bank Name', tag: 'BANK.ACCOUNT.BANK.NAME', key: 'bankName', filtering: true, sorting: true},
    {name: 'Currency', tag: 'BANK.ACCOUNT.CURRENCY', key: 'currency', filtering: true, sorting: true}
  ];

  getMetaInfo(): string[] {
    return ['accountName', 'accountNumber', 'bankName', 'currency'];
  }

  getColumns(): string[] {
    return this.columns;
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  private log(message: string) {
    //this.messageService.add('HeroService: ' + message);
  }
}
