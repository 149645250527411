/**
 * Created by aleco on 9/26/2017.
 */

 import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
 import { PagerDataObject } from "../../../entities/pagerDataObject";
 import { IndicatorService } from "../../../services/indicator.service";
 import { switchMap } from 'rxjs/operators';
 import { Subject } from 'rxjs';
import { IndicatorSectorService } from 'src/app/services/indicatorSector.service';
import { SharedService } from 'src/app/services/sharedService';
 
 
 @Component({
   selector: 'risk-results',
   templateUrl: './risk-results.component.html',
   styleUrls: ['./risk-results.component.css'],
 })
 export class RiskResultsComponent implements OnDestroy, OnInit, AfterViewInit {
   metaInfo: string[];
   years=[]
   columns: Array<any>;
   data: PagerDataObject[] = [];
   @Input()
   disableHeader = false;
   subscription = [];
   subscriptionIndSectors: any;
   private dataSub$: Subject<any>[] = [];
   dataSubSectors: PagerDataObject;
   private dataSubSectors$ = new Subject<any>();
   query: String;
   sectors: any;
   yearSectors = {}
   ngOnInit(){

       this.indicatorSectorService.getIndicatorSectors().subscribe(sectors => {
       this.sectors = sectors;
        this.indicatorService.getYears().subscribe(years=>{
          if (years.length>0 && this.sectors.length>0){
         
          this.yearSectors[years[0].year]=[sectors[0].name]
          }
         
          years.forEach(elem=>{
            if (elem['year']!=null){
              
            this.metaInfo = this.indicatorService.getMetaResultsInfo();
            this.columns = this.indicatorService.getColumnsResults();
            this.years.push(elem['year'])
            this.dataSub$[elem['year']] = new Subject<any>();
            this.subscription[elem['year']] = this.dataSub$[elem['year']].pipe(
              switchMap(
                filter => {
                  return this.indicatorService.getDataRisks(filter);
                })
            )
              .subscribe(data => {
                  this.data[elem['year']] = data;
                })
              }
          })
         });
       })
 
 
  
   }

fetchNews(year,sector){
  
  this.yearSectors[year]
  if (sector===null){
    if (!this.yearSectors[year]) {
      this.yearSectors[year]=[this.sectors[0].name]
    }
      
  }
  else{
    var scs = this.yearSectors[year]
    if (scs&&!scs.includes(sector)){
      this.yearSectors[year].push(sector)
    }
  }

  // if (this.years[year]){

  // }else{
  //   this.yearSectors[year]=this.sectors[0].name
  // }
}
   ngOnDestroy(): void {
     if (this.subscription) {
       this.subscription.forEach(sub=>sub.unsubscribe());
     }
   }

   constructor(private indicatorService: IndicatorService, private indicatorSectorService: IndicatorSectorService,
    private sharedService: SharedService) {
 
   }
   ngAfterViewInit(): void {
    this.sharedService.loadMenuItems("indicator");
   }
 
   onGridQuery(query) {
     this.query = query.query+"&year="+query.extraParameter+"&sector="+query.extraParameter2
     this.dataSub$[query.extraParameter].next(this.query);
   }
 
   deleteIndicator(entity) {
     // let indicator = new Indicator();
     // indicator.id = entity;
     // this.indicatorService.delete(indicator)
     //   .subscribe(indicator => {
     //     this.dataSub$.next(this.query);
     //   });
   }
 }
 