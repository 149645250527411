import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-action-element',
  templateUrl: './action-element.component.html',
  styleUrls: ['./action-element.component.css']
})
export class ActionElementComponent implements OnInit {

  @Input() item;
  @Input() itemIndex;
  @Input() taskKey;
  @Input() processId: any;
  @Input() createDate: any;
  @Input() singleSave: false;
  @Output() onTaskContentChange = new EventEmitter<any>();
  @Output() onSingleSaveClick = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

  emitChange(event, idx) {
    const changedFormElement = this.item.elements.find(elem => elem.key === event.key)
    changedFormElement.value = event.value;
    this.onTaskContentChange.emit({taskKey: this.taskKey, item: this.item, element: changedFormElement, itemIndex: this.itemIndex } );
  }

  singleSaveClick(event) {
    this.onSingleSaveClick.emit(event)
  }

}
