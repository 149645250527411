import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.css']
})
export class FormComponent implements OnInit {

  @Output() onRowKeyValue = new EventEmitter<any>();
  @Output() onSingleSaveClick = new EventEmitter<any>();
  @Input() form;
  @Input() processId: any;
  @Input() singleSave: false;
  @Input() taskKey: any
  @Input() createDate: any

  constructor() { }
  enabledReferences = {}
  ngOnInit() {
    this.form.elements.forEach(el => {
      if (el.enabled) {
        var splittedKeys = el.enabled.split(".")
        var toEnable = this.form.elements.find(elem => elem.key === splittedKeys[1])
        
        if (splittedKeys.length===3){
          if ((this.taskKey + "." + toEnable.key + "." + toEnable.value) === el.enabled){
            this.enabledReferences[el.enabled] = true;
          }else{
            this.enabledReferences[el.enabled] = false;
            
            this.setDefaultValues(el)
          }
        }

        else if (toEnable && toEnable.value && toEnable.value.length > 0 && toEnable.value[0].value) {
          this.enabledReferences[el.enabled] = true;
        } else {
          this.enabledReferences[el.enabled] = false;
          this.setDefaultValues(el)
        }
        
      }
      else {
        this.enabledReferences[el.enabled] = true;
      
      }
    })
  }

  onChange(key, value) {
    const obj = { key: key, value: value };
    this.onRowKeyValue.emit(obj);
    if (obj.value && obj.value.length > 0) {
      var elems = this.form.elements.filter(elem => elem.enabled)
      elems.forEach(el => {
        if (obj.value && el.enabled) {
          if ((this.taskKey + "." + key) === el.enabled) {
            if (obj.value.length > 0 && obj.value[0].value) {
              this.enabledReferences[el.enabled] = true
            } else {
              if (el.key !== obj.key){
              this.enabledReferences[el.enabled] = false
              this.setDefaultValues(el);
            }
            }
          }
          else if ((this.taskKey + "." + key + "." + obj.value) === el.enabled) {
            this.enabledReferences[el.enabled] = true
          } else {
            var enKey = el.enabled.split('.')[1]
            if (enKey === key){
              this.enabledReferences[el.enabled] = false;
              this.setDefaultValues(el)
            }
          }
        }
      })
    }
  }


  onFileUpload(key, file) {
    const obj = { key: key, value: file };
    this.onRowKeyValue.emit(obj);

  }

  setDefaultValues(element) {
    if (element.type === 'calendar') {
    }
    else if (element.type === 'file-template') {
    }
    else {
      element.value = null
    }
  }

  saveForm(){
    this.onSingleSaveClick.emit(null);
  }

}
