import { AfterViewInit, Component, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { filter, map } from 'rxjs/operators';
import { AuthService } from './services/auth.service';
import { UserService } from './services/user.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements AfterViewInit {
  isLoggedIn = false;
  title = 'RDFusion';
  activeUser = true;
  isControlModule = false;
  constructor(public translate: TranslateService, private router: Router, public auth: AuthService,
              private userService: UserService, private activatedRoute: ActivatedRoute) {
    translate.setDefaultLang('en');
    // this.auth.loggedIn.subscribe(val => {
    //   this.isLoggedIn = val;
    //   this.auth.getCurrentUser().subscribe(val=>{

    //   })
    // })
  }
  username;

  ngOnInit() {
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map((route) => {
        while (route.firstChild) { route = route.firstChild; }
        return route;
      }),
      filter((route) => route.outlet === 'primary'),
    )
      .subscribe((event) => {
        event.data.subscribe(routeData => {
          this.isControlModule = routeData.app !== undefined && routeData.app === 'cm';
          this.auth.isUserActive.subscribe(valUser => {
            this.activeUser = valUser;
            if (!this.activeUser && this.isLoggedIn) {
              this.userService.getCurrentUser().subscribe(user => {
                if (user !== null) {
                  this.username = user.username;
                }
              });
            }
          });
          this.auth.loggedIn.subscribe(val => {
            this.isLoggedIn = val;
          });
        });
      });
  }

  logout() {
    this.username = '';
    this.auth.logout().subscribe(res => {
      this.router.navigate(['login']);
      this.auth.setLoginStatus(false);
    });
  }

  ngAfterViewInit() {

  }


}
