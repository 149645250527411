/**
 * Created by aleco on 2/14/2017.
 */

import {Component, Input} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: '<ntr-save-button>',
  templateUrl: './save-button.component.html'
})


export class SaveButtonComponent {
  @Input() service;
  @Input() entity: any;

  constructor(private router: Router) {

  }

  add() {

    this.service.add(this.entity)
      .subscribe(service => {
        this.router.navigateByUrl('/service-list');
      });
  }
}
