/**
 * Created by aleco on 2/15/2018.
 */

import {Component, Input} from '@angular/core';
import {ServiceComponent} from "./service.component";
import {Service} from "../../../entities/service";
import {CurrencyService} from "../../../services/currency.service";

import {BankService} from "../../../services/bank.service";
import { Subject } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: '<ntr-service-otp>',
  templateUrl: './service-otp.component.html'
})
export class ServiceOtpComponent implements ServiceComponent {
  subscriptionCurrencies: any;
  subscriptionBankAccounts: any;
  private dataCurrencySub$ = new Subject<any>();
  private dataBankAccountsSub$ = new Subject<any>();
  @Input() service: Service;
  currencies: any[];
  banckAccounts: any[];

  constructor(private currencyService: CurrencyService, private bankService: BankService) {
    this.subscriptionCurrencies = this.dataCurrencySub$.pipe(switchMap(filter => {
      return this.currencyService.getData(filter);
    }))
      .subscribe(data => {
        this.currencies = data.records;
      });

    this.subscriptionBankAccounts = this.dataBankAccountsSub$.pipe(switchMap(filter => {
      return this.bankService.getData(filter);
    }))
      .subscribe(data => {
        this.banckAccounts = data.records;
      });

    this.dataCurrencySub$.next(null);
    this.dataBankAccountsSub$.next(null);
  }


}
