import { AfterViewInit, Component, OnInit } from '@angular/core';
import { AdminService } from 'src/app/services/admin.service';
import { ServiceService } from 'src/app/services/service.service';
import { SharedService } from 'src/app/services/sharedService';

@Component({
  selector: 'app-regional-officer',
  templateUrl: './regional-officer.component.html',
  styleUrls: ['./regional-officer.component.css']
})
export class RegionalOfficerComponent implements OnInit,AfterViewInit {

  constructor(private serviceService: ServiceService, private adminService: AdminService, private sharedService:SharedService) {

  }  
  
  ngAfterViewInit(): void {
    this.sharedService.loadMenuItems('admin');
  }

  services: any[]
  activeItems = {}
  admin: any
  items = {}
  ngOnInit() {
    this.adminService.getAdminSettings()
      .subscribe(settings => {
        if (settings && settings.length > 0)
          this.admin = settings[0];
        else {
          this.admin = {}
        }
        this.serviceService.getTotalServiceList("").subscribe(services => {
          this.services = []
          services.forEach(elem => {
            var serviceObject = {}
            serviceObject['name'] = elem.name
            serviceObject['id'] = elem.id
            serviceObject['fileFormats'] = []
            serviceObject['selectedFileformats'] = []
            if (elem.fileFormats) {
              elem.fileFormats.forEach(ff => {
                serviceObject['fileFormats'].push(ff['name'])
              })
            }
            this.services.push(serviceObject)

            this.items[serviceObject['id']] = serviceObject['fileFormats'] ?
              serviceObject['fileFormats'] : []
              if(this.admin && this.admin['regional_officer'])
              this.activeItems = this.admin['regional_officer']
          }
          )
        });
      });
  }


 
  updateActiveItems(value,id) {
    this.activeItems[id]=value
  }
  saveRegionalOfficerSettings() {
 
     this.admin['regional_officer'] =this.activeItems
      this.adminService.saveAdminSettings(this.admin)
        .subscribe(settings => {
          if (settings && settings.length>0)
          this.admin = settings[0];
        });
  }
}
