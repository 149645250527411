/**
 * Created by aleco on 9/26/2017.
 */

import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { PagerDataObject } from "../../entities/pagerDataObject";
import { IndicatorService } from "../../services/indicator.service";
import { Indicator } from "../../entities/indicator";
import { switchMap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { SharedService } from 'src/app/services/sharedService';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user.service';


@Component({
  selector: 'indicator',
  templateUrl: './indicator-list.component.html',
  styleUrls: ['./indicator-list.component.css'],
})
export class IndicatorListComponent implements OnDestroy, OnInit {
  metaInfo: string[];
  years = []
  columns: Array<any>;
  data: PagerDataObject[] = [];
  @Input()
  disableHeader = false;
  subscription = [];
  private dataSub$: Subject<any>[] = [];
  query: String;
  user

  ngOnInit() {
    this.sharedService.loadMenuItems("indicator");
      this.auth.getCurrentUser().subscribe(user => {
        this.user = user
        this.loadIndicators();
      })
  }
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.forEach(sub => sub.unsubscribe());
    }
  }

  constructor(private indicatorService: IndicatorService, private sharedService: SharedService, private auth: AuthService, private userService: UserService) {

  }

  onGridQuery(query) {
    this.query = query.query + "&year=" + query.extraParameter
    if (this.dataSub$[query.extraParameter] !== undefined)
      this.dataSub$[query.extraParameter].next(this.query);
  }

  deleteIndicator(entity) {
  
    let indicator = new Indicator();
    indicator.id = entity;
    this.indicatorService.delete(indicator)
      .subscribe(indicator => {
        this.loadIndicators()
      });
  }
  loadIndicators(){
    this.years = []
    this.indicatorService.getYears().subscribe(years => {
      years.forEach(elem => {
        this.metaInfo = this.indicatorService.getMetaInfo();
        this.columns = this.indicatorService.getColumns();
        this.years.push(elem['year'])
        this.dataSub$[elem['year']] = new Subject<any>();
        this.subscription[elem['year']] = this.dataSub$[elem['year']].pipe(
          switchMap(
            filter => {
              return this.indicatorService.getData(filter);
            })
        )
          .subscribe(data => {
            this.data[elem['year']] = data;
          })
      })
    });
  }
}
