import {Directive, Input, ViewContainerRef} from '@angular/core';
import { Dashboard } from '../entities/dashboard';


@Directive({
  selector: '[dashboard-type-form-fields]',
})
export class DashboardTypeDirective {
  @Input("dashboard") dashboard: Dashboard;
  constructor(public viewContainerRef: ViewContainerRef) { }
}
