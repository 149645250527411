import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { Role } from 'src/app/entities/role';
import { RoleName } from 'src/app/entities/util';
import { JwtHelperService } from '@auth0/angular-jwt';
import { SharedService } from 'src/app/services/sharedService';
import { ServiceService } from 'src/app/services/service.service';
import { distinctUntilChanged, first } from 'rxjs/operators';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  title = 'app';
  public singleModel: String = '1';
  public version: String = '1.0.0';
  roleNames = RoleName
  public error: string;
  public username: string;
  public password: string;
  loggedIn = false;
  user;

  constructor(private route: ActivatedRoute,
    private router: Router, public auth: AuthService, public sharedService: SharedService, public jwtHelper: JwtHelperService,
    private serviceService: ServiceService) {
  }

  ngOnInit() {
    this.route.queryParams
      .pipe(
        distinctUntilChanged()
      )
      .subscribe(params => {
        var token = params['nonce']
        var urlParam = params['url']
        var retUrlParam = params['returnUrl']
        if (!token) {
          this.auth.getRedirectLink()
            .pipe(first())
            .subscribe(
              result => {
                var uP="";
                if (urlParam!==undefined)
                {if (typeof urlParam === "string"){
                  uP = "/"+ urlParam;
                }else{
                  urlParam.forEach((elem, index)=>{
                    uP+="/"+elem
                  })
                }}
                window.location.href = result['redirectLink'] + (uP !== "" ? uP: "")
              },
              err => {
                this.handleError(err)
              }
            );
        }
        else {
          if (localStorage.getItem('access_token') === 'undefined'
          || this.jwtHelper.isTokenExpired()) {
          this.auth.getTokenFromNonce(token)
            .pipe(first())
            .subscribe(
              result => {
                if (result['statusCode'] === 200) {
                  var tokenLocal = result['usrToken']
                  localStorage.setItem('access_token', tokenLocal);
                  this.loggedIn = true;
                  this.sharedService.updateAccessToken()
                  this.auth.getCurrentUser().subscribe(user => {
                    this.user = user
                    if (user['status'] !== 'DISABLED') {
                      this.auth.setUserEnabled(true);
                      window.location.href = retUrlParam
                    }
                    else {
                      
                      this.auth.setUserEnabled(false);
                      this.auth.setLoginStatus(true)
                    }
                  })
                }
              },
              err => {
                this.handleError(err)
              }
            );
        }else{
          this.auth.getCurrentUser().subscribe(user => {
            this.user = user
            if (user['status'] === 'ENABLED') {
              this.auth.setUserEnabled(true);
              window.location.href = retUrlParam
            }
            else {
              
              this.auth.setUserEnabled(false);
              this.auth.setLoginStatus(true)
            }
          })
        }
        }

      });
  }

  login() {
    this.auth.login(this.username, this.password).subscribe(result => {
      this.router.navigate(['csv-list']);
    },
      err => {
        if (err.status === 0) {
          this.error = 'ERRORS.CONNECTION_FAILED'
        }
        if (err.status === 401) {
          this.error = 'ERROR.WRONG_CREDENTIALS';
        }
      })
  }

  handleError(err) {
    if (err.status !== undefined) {
      if (err.status === 0) {
        // this.toastr.error('Unable to connect to server', `ERROR!!!`, {
        //   toastClass: "toast border-red",
        //   positionClass: 'toast-center-center',
        //   timeOut: 10000,
        // });
      }
      else if (err.status === 401) {
        // this.error = 'ERRORS.' + err.error.message;
      }
    }
  }
}
