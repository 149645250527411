import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { UserService } from '../../../services/user.service';
import { User } from '../../../entities/user';
import { NgSelectComponent } from '@ng-select/ng-select';

@Component({
  selector: 'app-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.css']
})
export class CommentsComponent implements OnInit {
  comment: any;
  @Input()
  comments = [];
  modalRef?: BsModalRef;
  @Output() onAddComment = new EventEmitter<any>();
  @Output() onDeleteComment = new EventEmitter<any>();
  user: User;
  userList: User[];
  constructor(private modalService: BsModalService, private userService: UserService) { }
  @ViewChild('usersListSelect', { static: false }) public usersListSelect: NgSelectComponent;
  ngOnInit() {
    this.userService.getTotalUserFlatList().subscribe(users => {
      this.userList = users;
    });
    this.userService.getCurrentUser().subscribe(
      user => {
        this.user = user;
       
      });
  }

  onSubmit() {
    this.onAddComment.emit({
      text: this.comment.trim(),
      recipients: this.usersListSelect['activeItems']
    });
    this.comment = null;
    this.usersListSelect['activeItems'] = []
  }

  deleteComment(key) {
    this.onDeleteComment.emit({
      key: key
    });
  }
  openModal(template: TemplateRef<any>, key) {
    this.modalRef = this.modalService.show(template, { class: 'modal-sm', initialState: key });
  }
  confirm(): void {
    this.deleteComment(this.modalService.config.initialState);
    this.modalRef.hide();
  }
}
