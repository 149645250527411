/**
 * Created by aleco on 9/26/2017.
 */

import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from "@angular/router";
import { CsvMappingFieldTypes } from "../../entities/csvMappingFieldTypes";
import { Indicator } from "../../entities/indicator";
import { IndicatorService } from "../../services/indicator.service";
import { ServiceService } from "../../services/service.service";
import { Service } from "../../entities/service";
import { Currency } from "../../entities/currency";

import { PagerDataObject } from "../../entities/pagerDataObject";
import { Subject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { NgSelectComponent } from '@ng-select/ng-select';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: '<ntr-indicator-add-edit>',
  templateUrl: './indicator-add-edit.component.html',
  styleUrls: ['./indicator-add-edit.component.css']
})
export class IndicatorAddEditComponent implements OnInit {
  indicator: Indicator = new Indicator();
  submitted = false;
  services: Service[];
  fileFormatsLeft: any[];
  fileFormatsRight: any[];
  mappingObjectsLeft: any[];
  mappingObjectsRight: any[];
  compareLeft: any[] = [];
  compareRight: any[] = [];
  dateLeft: any[];
  dateRight: any[];
  operations = [{ name: 'Descrepancy', type: '-' }, { name: 'Ratio', type: '/' }]
  @ViewChild('compareLeftSelect', { static: false }) public compareLeftSelect: NgSelectComponent;
  @ViewChild('compareRightSelect', { static: false }) public compareRightSelect: NgSelectComponent;
  metaInfo: string[];
  columns: Array<any>;
  data: PagerDataObject;
  subscription: any;
  private dataSub$ = new Subject<any>();
  query: String;
  user;
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  ngOnInit() {
      this.auth.getCurrentUser().subscribe(user => {
        this.user = user
        this.serviceService.getTotalServiceList("").subscribe(services => {
          this.services = services;
          this.route.paramMap.pipe(
            switchMap((params: ParamMap) => this.indicatorService.getIndicator(params.get('id'))))
            .subscribe(indicator => {
              if (indicator !== null) {
                this.indicator = indicator;
                this.onChangeService(this.indicator.serviceLeft, 1)
                this.onChangeService(this.indicator.serviceRight, 2)
                this.onChangeFileFormat(this.indicator.fileFormatLeft, 1)
                this.onChangeFileFormat(this.indicator.fileFormatRight, 2)

                this.compareLeftSelect['value'] = this.indicator.compareLeft;
                this.compareRightSelect['value'] = this.indicator.compareRight;
              }
            });

        });
      });
  }


  constructor(private serviceService: ServiceService, public indicatorService: IndicatorService,
    private auth: AuthService,
    private userService: UserService,
    private router: Router, private route: ActivatedRoute) {
    this.subscription = this.dataSub$.pipe(switchMap(filter => {
      return this.indicatorService.getIndicatorResult(filter);
    }))
      .subscribe(data => {
        this.data = data;
      });
    this.metaInfo = this.indicatorService.getMetaIndicatorRunInfo();
    this.columns = this.indicatorService.getColumnsIndicatorRun();
  }

  onSubmit() {
    if (this.compareLeftSelect['activeItems'])
      this.indicator.compareLeft = this.compareLeftSelect['activeItems'];
    if (this.compareRightSelect['activeItems'])
      this.indicator.compareRight = this.compareRightSelect['activeItems'];
    this.submitted = true;
    this.indicatorService.add(this.indicator)
      .subscribe(indicator => {
        this.goToList();
      });
  }

  onChangeService(value, combo) {
    if (combo === 1) {
      this.fileFormatsLeft = this.services.find(item => item.id === value).fileFormats;
    }
    else {
      this.fileFormatsRight = this.services.find(item => item.id === value).fileFormats;
    }
  }


  onChangeFileFormat(value, combo) {
    if (combo === 1) {
      this.mappingObjectsLeft = this.fileFormatsLeft.find(item => item.name === value).mappingObjects;
      this.compareLeft = this.mappingObjectsLeft.filter(item => item['type'] == CsvMappingFieldTypes.AMOUNT).map(({ name }) => name);
      this.dateLeft = this.mappingObjectsLeft.filter(item => item['type'] == CsvMappingFieldTypes.DATE);
    }
    else {
      this.mappingObjectsRight = this.fileFormatsRight.find(item => item.name === value).mappingObjects;
      this.compareRight = this.mappingObjectsRight.filter(item => item['type'] == CsvMappingFieldTypes.AMOUNT).map(({ name }) => name);
      this.dateRight = this.mappingObjectsRight.filter(item => item['type'] == CsvMappingFieldTypes.DATE);
    }
  }


  goToList() {
    this.router.navigateByUrl('/indicator-list');
  }

  runIndicator() {
    this.indicatorService.runIndicator(this.indicator)
      .subscribe(indicator => {
        this.dataSub$.next(this.query + "&indicator_id=" + this.indicator.id);
      });
  }

  onGridQuery(query) {
    this.query = query
    this.dataSub$.next(query + "&indicator_id=" + this.indicator.id);
  }

  selectionChanged(event) {
  }

  onCheckboxChange(event) {

    if (event.target.checked) {
      this.indicator.riskManagement = true
    }
    else {
      this.indicator.riskManagement = false
    }

  }
}
