import { Component, OnInit, Input } from '@angular/core';
import { Service } from 'src/app/entities/service';
import { ServiceService } from 'src/app/services/service.service';
import { AuthService } from 'src/app/services/auth.service';
import { NavigationStart, Event as NavigationEvent, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { SharedService } from 'src/app/services/sharedService';
import { TagsFill } from 'ngx-bootstrap-icons';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from 'src/app/services/user.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ProcessService } from 'src/app/services/process.service';

@Component({
  selector: 'app-fusion-nav-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})

export class HeaderComponent implements OnInit {
  leftMenuItems = [];
  leftMenuSubscription: any;
  @Input() contextMenu: string[];
  lang: string;
  username: string;
  initials: string;
  menuItems: string[][];
  lanList;
  user;
  services: Service[];
  dropDownNotifications: any[];
  unseenNotifications = 0;
  loginSubscription: any;
  isAssigned = false
  constructor(public translate: TranslateService, public serviceService: ServiceService,
              public userService: UserService, public notificationService: NotificationService,
              public auth: AuthService, public router: Router, public sharedService: SharedService,
              public processService: ProcessService) {
    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang('en');
    // the lang to use, if the lang isn't available, it will use the current loader to get them
    // translate.use('en');
    this.setLang(localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en');
    this.auth.loggedIn.subscribe(val => {
      if (val) {
        this.processService.isAssigned().subscribe(res => {
          if (res.is_assigned){
            this.isAssigned = true;
          }
          this.loadData();
        })
        
      }
    });
  }

  loadData() {
        this.userService.getCurrentUser().subscribe(user => {
          if (user !== null) {
            this.user = user;
            this.getServices();
          }
        });
  }

  ngOnInit() {
    this.leftMenuSubscription = this.sharedService.menuItemsEmitter.subscribe(menuItems => {
      this.setupMenu(menuItems);
    });
    this.router.events
      .subscribe(
        (event: NavigationEvent) => {
          if (event instanceof NavigationStart) {
            // this.setupMenu(this.sharedService.getMenuFromUrl(event.url));
          }
        });
    // this.userService.getCurrentUser().subscribe(user => {
    //   if (user!==null){
    //     this.user = user;
    //   this.getServices()
    //   }

    // })
    this.getUnseenNotifications();
    setInterval(() => this.getUnseenNotifications(), 60000);
  }


  getUnseenNotifications() {
    this.notificationService.getUnseenCount().subscribe(count => {
      this.unseenNotifications = count['count'];
    });
  }
  
  navigateToNotifications() {
    this.router.navigate(['notifications']);
  }
  navigateToNotification(value) {
    const route = value.referenceObject ? '/proposal/' + value.referenceObject.id + '/' + value.referenceObject.key : 'notifications';
    this.router.navigate([route]);
  }

  setSeenAll() {
    this.unseenNotifications = 0;

    this.notificationService.setSeenAll().subscribe(result => {
      this.notificationService.getNotificationList().subscribe(res => {
        this.dropDownNotifications = res;
       });
    });
  }

  getServices() {
    this.serviceService.getTotalServiceList('').subscribe(services => {
      this.services = services;
    });
  }

  logout() {
    this.username = '';
    this.auth.logout().subscribe(res => {
      this.router.navigate(['login']);
      this.auth.setLoginStatus(false);
    });
  }

  userAccount() {
    this.router.navigate(['user-add-edit', this.user.id]);
  }

  setupMenu(menuItems) {
    if (menuItems !== 'services') {
      this.leftMenuItems = menuItems;
    } else {
      this.serviceService.getTotalServiceList('').subscribe(services => {
        this.leftMenuItems = [];
        services.forEach(service => {
          this.leftMenuItems.push({ title: service.name, link: '/csv-list/' + service.id });
        });
      });
    }
  }

  setLang(lang) {
    this.sharedService.setLan(lang);
    this.lang = lang;
    this.translate.use(lang);
  }

  openControlModule() {
    window.open(window.location.origin + '/control-module');
  }

}
