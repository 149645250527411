import { Component, Input, OnInit, EventEmitter, Output, ViewChild, ElementRef, TemplateRef } from '@angular/core';
import { FileUploader } from 'ng2-file-upload';
import { ProcessService } from '../../../services/process.service';
import { saveAs } from 'file-saver';
import { SharedService } from '../../../services/sharedService';
import { NgxSpinnerService } from 'ngx-spinner';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-file-upload-element',
  templateUrl: './file-upload-element.component.html',
  styleUrls: ['./file-upload-element.component.css']
})

export class FileUploadElementComponent implements OnInit {
  @ViewChild('fileInput', { static: false }) uploadElRef: ElementRef;
  @Input() fileupload;
  @Input() processId: any;
  @Input() activeElements;
  @Input() documentSection = false;
  @Input() uploadDisabled = false;
  confirmAction: string;
  actionText: string;
  modalDeleteRef?: BsModalRef;
  constructor(private processService: ProcessService,
    private modalService: BsModalService,
    private sharedService: SharedService, private spinner: NgxSpinnerService) { }
  public uploader: FileUploader;
  downloading: any;
  fileError: any;
  @Output() onUploadDone = new EventEmitter<any>();
  @Output() documentDeleted = new EventEmitter<any>();
  ngOnInit() {
    if (this.fileupload.format) {
      this.downloading = {}
      this.fileError = {}
      this.fileupload.format.forEach(elem => {
        this.downloading[elem] === false
        this.fileError[elem] === false
      })
    }
    else {
      this.downloading = false;
      this.fileError = false;
    }

    // tslint:disable-next-line:no-shadowed-variable
    const URL = this.sharedService.apiUrl('api/control/process/action/upload') + ':id/';
    this.uploader = new FileUploader({
      url: URL.replace(':id', this.processId),
      authToken: "Bearer " + localStorage.getItem('access_token'),
      disableMultipart: false,
      autoUpload: true,
      method: 'post',
      itemAlias: 'file',
      allowedFileType: ['image', 'pdf', 'docx', 'xlsx', 'doc', 'xls'],
      additionalParameter: { processId: 'test' }
    });

    this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      var responsePath = JSON.parse(response);
      if (this.fileupload.title) {
        responsePath.title = this.fileupload.title
      }

      if (this.documentSection) {
        this.uploader.queue = []
      }

      this.onUploadDone.emit(responsePath);
    };
    this.uploader.onAfterAddingFile = (item) => {
      this.uploadElRef.nativeElement.value = ''
    };
  }

  public onFileSelected(event: EventEmitter<File[]>) {
    const file: File = event[0];
  }

  downloadFile(key, filename) {
    this.processService.downloadFile(key).subscribe
      (data => {
        const blob = data;
        const file = new Blob([blob], {});
        saveAs(file, filename);
      });
  }

  downloadAssetFile(type, value, filename, format) {
    if (format === null)
      this.downloading = true;
    else {
      filename = filename.substr(0, filename.lastIndexOf(".")) + "." + format;
      this.downloading[format] = true;
    }
    this.processService.setFileTemplate(this.processId, value, this.fileupload.global, format).subscribe(val => {
      const blob = val;
      const file = new Blob([blob], {});
      saveAs(file, filename);
      if (format === null)
        this.downloading = false;
      else {
        this.downloading[format] = false;
      }
    }, error => {
      if (format === null) {
        this.downloading = false;
        this.fileError = true;
      }
      else {
        this.downloading[format] = false;
        this.fileError[format] = true;
      }
    });
  }


  deleteFile(key) {
    this.processService.deleteFile(key).subscribe
      (data => {
        this.emitDeletion(key)
      }, error => {
        this.emitDeletion(key)
      });
  }

  emitDeletion(key) {
    if (this.documentSection)
      this.documentDeleted.emit(key)
    else
      this.onUploadDone.emit(null);
    if (this.uploader.queue.length > 0)
      this.uploader.queue.length = this.uploader.queue.length - 1
  }

  openModalDelete(template: TemplateRef<any>, key, action) {
    this.confirmAction = action;
    switch (this.confirmAction) {

      case 'DELETE_FILE': {
        this.actionText = 'SURE_TO_DELETE_FILE';
        break;
      }
      default: {
        break;
      }
    }
    this.modalDeleteRef = this.modalService.show(template, { class: 'modal-sm', initialState: key });
  }

  confirm() {
    switch (this.confirmAction) {
      case 'DELETE_FILE': {
        this.deleteFile(this.modalService.config.initialState);
        this.modalDeleteRef.hide();
        break;
      }
      default: {
        break;
      }
    }
  }

}
