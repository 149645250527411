import {Injectable} from '@angular/core';
import {BankAccount} from "../entities/bankAccount";
import {catchError, tap} from 'rxjs/operators';
 
import {PagerDataObject} from "../entities/pagerDataObject";
 
import {HttpClient, HttpHeaders} from "@angular/common/http";
import { SharedService } from './sharedService';
import { Observable, of } from 'rxjs';
import { BatchProcess } from '../entities/batchProcess';
 

const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};

@Injectable()
export class BatchProcessService {


  constructor(private http: HttpClient, private sharedService: SharedService) {
  }


  getData(query: string): Observable<PagerDataObject> {
    let batchProcesses$ = this.http.get<PagerDataObject>(this.sharedService.apiUrl('api/batch_processes', query !== null ? ("/" + query) : ""))
    return batchProcesses$;
  }

  getBatchProcess(id: string): Observable<BatchProcess> {
    let batchProcess$ = this.http.get<BatchProcess>(this.sharedService.apiUrl('api/batch_processes/' + id))
    return batchProcess$;
  }

  delete(batchProcess: BatchProcess): Observable<BatchProcess> {
    return this.http.post<BatchProcess>(this.sharedService.apiUrl('api/batch_processes/delete'), batchProcess, httpOptions).pipe(
      tap((service: BatchProcess) => this.sharedService.log(`deleted batch process w/ id=${batchProcess.id}`)),
      catchError(this.sharedService.handleError<any>('deleteBatchProcess'))
    );
  }

  columns: Array<any> = [
    {key: 'view', style: {'flex': '0 0 100px', 'width': '1%'}, button: true},
    {
      name: 'Service',
      tag: 'BATCH-PROCESS.SERVICE',
      key: 'serviceName',
      filtering: true,
      sorting: true,
      sortingOrder: 'asc',
      type: 'ACTION'
    },
    {name: 'File Format', tag: 'BATCH-PROCESS.FILEFORMAT', key: 'fileFormatName', filtering: true, sorting: true, type: 'TEXT'},
    {name: 'File', tag: 'BATCH-PROCESS.FILENAME', key: 'fileName', filtering: true, sorting: true, type: 'TEXT'},
    {name: 'Number of Records', tag: 'BATCH-PROCESS.COUNT', key: 'numberOfRecords', filtering: true, sorting: true, type: 'NUMBER'},
    {name: 'Start Time', tag: 'BATCH-PROCESS.STARTTIME', key: 'processStartTime', filtering: true, sorting: true, type: 'DATE'},
    {name: 'End Time', tag: 'BATCH-PROCESS.ENDTIME', key: 'processEndTime', filtering: true, sorting: true, type: 'DATE'}
  ];

  getMetaInfo(): string[] {
    return ['serviceName', 'fileFormatName', 'fileName', 'numberOfRecords','processStartTime','processEndTime'];
  }

  getColumns(): string[] {
    return this.columns;
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  private log(message: string) {
    //this.messageService.add('HeroService: ' + message);
  }
}
