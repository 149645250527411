export class Dashboard {
  id: number;
  name: string;
  footer: string;
  type: string;
  dashboardConfigs = [];
  yearly: boolean
  datePeriod: string;
  dateRange:  Date[];
  periodFilter: boolean;
  rangeFilter: boolean;
  description: string;
}
