import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { VerticalTimelineModule } from 'angular-vertical-timeline';
import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { LoginComponent } from './components/login/login.component';
import { HeaderComponent } from './components/header/header.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LeftMenuComponent } from './components/left-menu/left-menu.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { SharedService } from './services/sharedService';
import { NgxBootstrapSliderModule } from 'ngx-bootstrap-slider';
import { BaseService } from './services/base.service';
import { BsModalService, ModalModule } from 'ngx-bootstrap/modal';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { ChartService } from './services/chart.service';
import {ChartsModule, ThemeService} from 'ng2-charts';
import { NgxSpinnerModule } from 'ngx-spinner';
import { BarChartComponent } from './components/charts/bar-chart/bar-chart.component';
import { DoughnutChartComponent } from './components/charts/doughnut-chart/doughnut-chart.component';
import { RadarChartComponent } from './components/charts/radar-chart/radar-chart.component';
import { PieChartComponent } from './components/charts/pie-chart/pie-chart.component';
import { MultiAxisChartComponent } from './components/charts/multi-axis-chart/multi-axis-chart.component';
import { StackedChartComponent } from './components/charts/stacked-chart/stacked-chart.component';
import { ServiceListComponent } from './components/service/service-list.component';
import { ServiceEditComponent } from './components/service/service-edit/service-edit.component';
import { DatatableComponent } from './components/datatable/datatable.component';
import {AddButtonComponent} from './components/function-buttons/add-button.component';
import {SaveButtonComponent} from './components/function-buttons/save-button.component';
import { ServiceService } from './services/service.service';
import {ServiceTypeDirective} from './directives/service-type.directive';
import {DashboardTypeDirective} from './directives/dashboard-type.directive';
import { CurrencyService } from './services/currency.service';
import { BankService } from './services/bank.service';
import { TasService } from './services/tas.service';
import {ServiceCsvComponent} from './components/service/service-type-forms/csv-importer/service-csv.component';
import {ServiceMcasComponent} from './components/service/service-type-forms/service-mcas.component';
import {CsvUploadComponent} from './components/service/service-type-forms/csv-importer/csv-upload.component';
import {ServiceOtpComponent} from './components/service/service-type-forms/service-otp.component';
import {FileUploadModule} from 'ng2-file-upload';
import { CurrencyListComponent } from './components/currency/currency-list.component';
import { CurrencyAddEditComponent } from './components/currency/currency-add-edit.component';
import { CsvListComponent } from './components/csvlist/csvlist.component';
import { CsvListService } from './services/csvList.service';
import { DashboardConfigComponent } from './components/dashboard-config/dashboard-config.component';
import { DashboardEditComponent } from './components/dashboard-config/dashboard-edit/dashboard-edit.component';
import { DashboardService } from './services/dashboard.service';
import { BatchProcessListComponent} from './components/batch-process-manager/batch-process-list.component';
import { BatchProcessViewComponent} from './components/batch-process-manager/batch-process-view.component';
import { BatchProcessService} from './services/batchProcess.service';
import { NgxBootstrapIconsModule, allIcons } from 'ngx-bootstrap-icons';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { AuthService } from './services/auth.service';
import { JwtModule } from '@auth0/angular-jwt';
import { AuthInterceptor } from './util/auth.interceptor';
import { AuthGuard } from './guards/auth.guard';
import { UserListComponent } from './components/user/user-list.component';
import { UserService } from './services/user.service';
import { UserAddEditComponent } from './components/user/user-add-edit/user-add-edit.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PeriodRangeComponent } from './components/charts/period-range/period-range.component';
import { LandingPageComponent } from './components/landing-page/landing-page.component';
import { AdminComponent } from './components/admin/admin.component';
import { AdminService } from './services/admin.service';
import { ContractComponent } from './components/contract/contract.component';
import { ContractService } from './services/contract.service';
import { ExcelService } from './services/excel.Service';
import { IndicatorService} from './services/indicator.service';
import { IndicatorAddEditComponent} from './components/indicator/indicator-add-edit.component';
import { IndicatorListComponent} from './components/indicator/indicator-list.component';
import { MultipleSelectComponent } from './components/custom-ui-elements/multiple-select.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { CompanyComponent } from './components/company/company.component';
import { CompanyService } from './services/company.service';
import { CompanyAddEditComponent } from './components/company/company-add-edit/company-add-edit.component';
import { DatasetsComponent } from './components/company/datasets/datasets.component';
import { TaxDivisionService } from './services/taxDivision.service';
import { RiskManagementComponent } from './components/risk-management/risk-management.component';
import { IndicatorSectorsComponent } from './components/indicator-sectors/indicator-sectors.component';
import { IndicatorSectorAddEditComponent } from './components/indicator-sectors/indicator-sector-add-edit/indicator-sector-add-edit.component';
import { IndicatorSectorService } from './services/indicatorSector.service';
import { PayeeSectorsComponent } from './components/payee-sectors/payee-sectors.component';
import { PayeeSectorService } from './services/payeeSector.service';
import { PayeeSectorAddEditComponent } from './components/payee-sectors/payee-sector-add-edit/payee-sector-add-edit.component';
import { RiskResultsComponent } from './components/indicator/risk-results/risk-results.component';
import { BaseComponentComponent } from './components/base-component/base-component.component';
import { RegionalOfficerComponent } from './components/regional-officer/regional-officer.component';
import { ControlTeamListComponent } from './components/control-team/control-team-list.component';
import { ControlTeamAddEditComponent } from './components/control-team/control-team-add-edit/control-team-add-edit.component';
import { ControlTeamService } from './services/controlTeam.service';
import { ControlModuleComponent } from './components/control-module/control-module.component';
import { ControlModuleHeaderComponent } from './components/control-module/control-module-header/control-module-header.component';
import { NotificationService } from './services/notification.service';
import { NotificationsComponent } from './components/control-module/notifications/notifications.component';
import { StepTabberComponent } from './components/control-module/step-tabber/step-tabber.component';
import { FormComponent } from './components/control-module/form/form.component';
import { StepComponent } from './components/control-module/step/step.component';
import { ActionElementComponent } from './components/control-module/action-element/action-element.component';
import { EditorModule } from '@tinymce/tinymce-angular';
import { RichTextElementComponent } from './components/control-module/rich-text-element/rich-text-element.component';
import { FileUploadElementComponent } from './components/control-module/file-upload-element/file-upload-element.component';
import { NewProcessComponent } from './components/control-module/new-process/new-process.component';
import { ProcessCardComponent } from './components/control-module/process-card/process-card.component';
import { ProcessService } from './services/process.service';
import { ProcessComponent } from './components/control-module/process/process.component';
import { CommentsComponent } from './components/control-module/comments/comments.component';
import {CommentService} from './services/comment.service';
import { TimelineComponent } from './components/control-module/timeline/timeline.component';
import { SelectComponent } from './components/control-module/form/select/select.component';
import { RadioComponent } from './components/control-module/form/radio/radio.component';
import { CheckboxComponent } from './components/control-module/form/checkbox/checkbox.component';
import { TableComponent } from './components/control-module/form/table/table.component';
import {ToastService} from './services/toast-service';
import { ToastContainerComponent } from './components/control-module/toast-container/toast-container.component';
import { DynamicTableComponent } from './components/control-module/form/dynamic-table/dynamic-table.component';
import { CalendarComponent } from './components/control-module/form/calendar/calendar.component';
import {ControlModuleGuard} from './guards/control.module.guard';
import { NewPreprocessComponent } from './components/new-preprocess/new-preprocess.component';
import { RiskService } from './services/riskService';
import { IfuService } from './services/ifuService';
import { ProposalComponent } from './components/proposal/proposal.component';
import { DateRangePickerComponent } from './components/date-range-picker/date-range-picker.component';
import { TimesheetComponent } from './components/control-module/timesheet/timesheet.component';
import { TimesheetTableComponent } from './components/control-module/timesheet-table/timesheet-table.component';
import { ThousandSeparatorDirective } from './directives/thousandSeparator.directive';
import { ThousandSeparatorPipe } from './pipes/thousand-separator.pipe';
import { OnlyNumber } from './directives/onlyNumberDirective';
import { DocumentsComponent } from './components/control-module/documents/documents.component';
import { ActionTimesheetComponent } from './components/control-module/timesheet/action-timesheet/action-timesheet.component';
import { FusionFileFormatComponent } from './components/service/service-type-forms/fusion-file-format/fusion-file-format.component';
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function tokenGetter() {
  return localStorage.getItem('access_token');
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    HeaderComponent,
    LeftMenuComponent,
    BarChartComponent,
    DoughnutChartComponent,
    RadarChartComponent,
    PieChartComponent,
    MultiAxisChartComponent,
    AddButtonComponent,
    SaveButtonComponent,
    StackedChartComponent,
    ServiceListComponent,
    ServiceEditComponent,
    DatatableComponent,
    ServiceTypeDirective,
    DashboardTypeDirective,
    ServiceCsvComponent,
    ServiceMcasComponent,
    CsvUploadComponent,
    ServiceOtpComponent,
    CurrencyListComponent,
    CurrencyAddEditComponent,
    CsvListComponent,
    DashboardConfigComponent,
    DashboardEditComponent,
    IndicatorListComponent,
    OnlyNumber,
    IndicatorAddEditComponent,
    BatchProcessListComponent,
    BatchProcessViewComponent,
    UserListComponent,
    UserAddEditComponent,
    PeriodRangeComponent,
    LandingPageComponent,
    AdminComponent,
    ContractComponent,
    MultipleSelectComponent,
    CompanyComponent,
    CompanyAddEditComponent,
    DatasetsComponent,
    RiskManagementComponent,
    IndicatorSectorsComponent,
    IndicatorSectorAddEditComponent,
    PayeeSectorsComponent,
    PayeeSectorAddEditComponent,
    RiskResultsComponent,
    BaseComponentComponent,
    RegionalOfficerComponent,
    ControlTeamListComponent,
    ControlTeamAddEditComponent,
    ControlModuleComponent,
    ControlModuleHeaderComponent,
    NotificationsComponent,
    StepTabberComponent,
    ThousandSeparatorDirective,
    ThousandSeparatorPipe,
    FormComponent,
    StepComponent,
    ActionElementComponent,
    RichTextElementComponent,
    FileUploadElementComponent,
    NewProcessComponent,
    ProcessCardComponent,
    ProcessComponent,
    CommentsComponent,
    TimelineComponent,
    SelectComponent,
    RadioComponent,
    CheckboxComponent,
    TableComponent,
    ToastContainerComponent,
    DynamicTableComponent,
    CalendarComponent,
    NewPreprocessComponent,
    ProposalComponent,
    DateRangePickerComponent,
    TimesheetComponent,
    TimesheetTableComponent,
    DocumentsComponent,
    ActionTimesheetComponent,
    FusionFileFormatComponent,


  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    NgbModule,
    ChartsModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    EditorModule,
    BrowserAnimationsModule,
    NgxBootstrapSliderModule,
    NgSelectModule,
    NgxBootstrapIconsModule.pick(allIcons),
    BsDatepickerModule.forRoot(),
    AccordionModule.forRoot(),
    VerticalTimelineModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    ModalModule.forRoot(),
    PaginationModule.forRoot(),
    BsDropdownModule.forRoot(),
    ButtonsModule.forRoot(),
    FileUploadModule,
    JwtModule.forRoot({
      config: {
        tokenGetter
      }
    })
  ],
  providers: [
    AdminService, AuthService, AuthGuard, ControlModuleGuard, BankService, BaseService, BatchProcessService, BsModalService,
    ChartService, CommentService, CompanyService, ContractService, ControlTeamService, CsvListService, CurrencyService,
    DashboardService, ExcelService, IndicatorSectorService, IndicatorService, NotificationService, PayeeSectorService,
    ProcessService, RiskService, IfuService, ServiceService, SharedService, TasService, TaxDivisionService, ThemeService, ToastService, UserService,
    ThousandSeparatorPipe,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }],
  bootstrap: [AppComponent]
})
export class AppModule { }
