/**
 * Created by aleco on 9/26/2017.
 */

import {
  AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, TemplateRef,
  ViewChildren
} from '@angular/core';

import { saveAs } from 'file-saver';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { debounceTime, distinctUntilChanged, elementAt, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { PagerDataObject } from 'src/app/entities/pagerDataObject';
import { SharedService } from 'src/app/services/sharedService';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'ntr-datatable',
  templateUrl: './datatable.component.html',
  styleUrls: ['./datatable.component.css']
})
export class DatatableComponent implements OnInit, OnChanges, AfterViewInit {

  constructor(private modalService: BsModalService, private sharedService: SharedService, public auth: AuthService) {
    this.query.pipe(
      debounceTime(500))
      .subscribe(() => {
        this.setPage(1);
      });
  }

  maxSize = 10;
  bigTotalItems = 0;
  bigCurrentPage = 1;
  numPages = 0;
  currentPage = 1;
  origSortColumn = '';
  origSortOrder = '';
  origSortNode = '';
  sortColumn = '';
  sortNode = '';
  sortOrder = 'desc';
  filtered = false;
  loading = true;
  @ViewChildren('filterItem') viewChildren: ElementRef[];
  @Input() public columns: Array<any>;
  @Output() onQuery: EventEmitter<any> = new EventEmitter();
  @Output() onButtonClick: EventEmitter<any> = new EventEmitter(false);
  @Output() onDeleteQuery: EventEmitter<any> = new EventEmitter();
  searchAll = '';
  currentFilter = '';
  @Input() data: PagerDataObject;
  tableData: any;
  entity: any;
  totalCount = 0;
  from = 0;
  to = 0;
  @Input()
  headers = [];
  @Input()
  disableHeader = false;
  @Input()
  dataService;
  @Input()
  modelView;
  @Output()
  fieldChanged = new EventEmitter();
  @Input()
  metaInfo: string[];
  @Input()
  entityTitle: string;
  @Input()
  extraParameter: string;
  @Input()
  extraParameter2: string;
  private query = new Subject<any>();
  public config: any = {
    paging: true,
    filtering: true
  };
  modalRef: BsModalRef;
  @Input()
  actionButtons = true;
  @Input()
  referenced = false;
  @Input()
  downloadButton = false;
  @Input()
  canDelete = true;
  @Input()
  userRoles;
  coloring;

  actions = ['CONFIRM_COMPANY', 'TEAM_ADD', 'TASK_SUBMIT', 'TASK_APPROVE'];

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, {class: 'modal-sm'});
  }



  ngOnInit(): void {
    // set Default Sort Column
    const col = this.columns.find(item => (item.sorting && item.sortingOrder));
    this.sortColumn = col ? col.key : null;
    this.sortOrder = col ? col.sortingOrder : null;
    this.sortNode = col ? col.node : null;

    Object.assign(this.origSortColumn, this.sortColumn);
    Object.assign(this.origSortOrder, this.sortOrder);
    Object.assign(this.origSortNode, this.sortNode);
    this.filter(null, '');
    let coloring = this.columns.find(item => item.coloring !== undefined);
    if (coloring) {
    this.coloring = coloring.coloring;
    }
  }

  getRowColor(score) {
    let temp = 0;
    let ranges = [];

    this.coloring.forEach(element => {
      let key = Number(Object.keys(element)[0]);
      let r = [temp, key];
      ranges.push(r);
      temp = key;
    });

    for (let i = 0; i <= ranges.length; i++) {
      let elem = ranges[i];
      if (score > elem[0] && score <= elem[1]) {
        return this.coloring[i][elem[1]];
      }
    }
  }

  getColors(a) {
    if (this.coloring) {
      let col = this.columns.find(item => item.coloring !== undefined);
      return this.getRowColor(a[col.key]);
    }

    return '';
  }



  pageChanged(event: any): void {
  }

  setPage(pageNo: number): void {
    this.currentPage = pageNo;
    this.fireQueryEvent();
  }

  filter(column, value) {
    // Column set by Client
    if (column) {
      this.getColumnByNodeKey(column.node, column.key).filteringString = value;
    } else {
      this.searchAll = value;
    }

    this.query.next();
  }

  fireQueryEvent() {
    let q = '?q=' + this.searchAll + '&';
    for (let c in this.columns) {
      let column = this.columns[c];
      if (column.filtering) {
        if (column.filteringString) {
          q += encodeURIComponent(this.getColumnForQuery(column)) + '=' + column.filteringString + '&';
        } else {
          q += encodeURIComponent(this.getColumnForQuery(column)) + '=&';
        }
      }
    }
    if (this.sortColumn) {
      // MongoDB stuff -1 DESC, 1 ASC
      const sort = this.getColumnByNodeKey(this.sortNode, this.sortColumn);
      q += 'sort=' + this.getColumnForQuery(sort) + ':' + ((this.sortOrder === 'desc') ? -1 : 1) + '&';
    }
    q += 'from=' + ((this.currentPage - 1) * this.maxSize) + '&size=' + this.maxSize;

    let filtered = false;
    this.viewChildren.forEach(item => {
      if (item.nativeElement.value != '') {
        filtered = true;
        return filtered;
      }
    });
    this.filtered = filtered;
    this.currentFilter = q;
    this.loading = true;

    let emittedObject = {};
    emittedObject['query'] = q;
    if (this.extraParameter) {
    emittedObject['extraParameter'] = this.extraParameter;
    }
    if (this.extraParameter2) {
    emittedObject['extraParameter2'] = this.extraParameter2;
    }
    if
    (emittedObject['extraParameter'] || emittedObject['extraParameter2']) {
    this.onQuery.emit(emittedObject);
    } else {
    this.onQuery.emit(q);
    }
  }
  navigateToFile(location) {
    if (location.includes('http') || location.includes('http') ) {
      window.open(location, '_blank');
    } else {
      window.open('http://' + location, '_blank');
    }

  }

  getColumnByNodeKey(node: string, key: string) {
    if (node) {
      return this.columns.find(item => (item.key === key && item.node === node));
    }
    return this.columns.find(item => (item.key === key));
  }

  private getColumnForQuery(col) {
    if (col.node) {
      return col.node + '.' + col.key;
    }
    return col.key;
  }

  sortBy(column) {
    // Avoid "sorting" action columns, such as 'View'
    if (!column.sorting) { return; }

    // Remove sorting sign from currently sorted column
    if (this.sortColumn) {
      let oldColumn = this.getColumnByNodeKey(this.sortNode, this.sortColumn);
      oldColumn.sortingOrder = '';
    }

    if (this.sortColumn === column.key && this.sortNode === column.node) {
      if (this.sortOrder == 'desc') {
        this.sortOrder = 'asc';
      } else {
        this.sortOrder = 'desc';
      }
    } else {
      this.sortColumn = column.key;
      this.sortNode = column.node;
      this.sortOrder = 'asc';
    }

    // Add sorting sign from currently sorted column
    if (this.sortColumn) {
      let newColumn = this.getColumnByNodeKey(this.sortNode, this.sortColumn);
      newColumn.sortingOrder = this.sortOrder;
    }
    this.fireQueryEvent();
  }


  buttonClick(row, cell) {
    this.onButtonClick.emit({row, cell});
  }

  changePage(event) {
    this.from = ((event.page - 1) * event.itemsPerPage) + 1;
    this.to = ((event.page - 1) * event.itemsPerPage) + event.itemsPerPage;
    this.setPage(event.page);
  }

  ngAfterViewInit(): void {
    // this.searchTrigger.next()
  }

  ngOnChanges(changes: any): void {
    if (this.data && Object.keys(this.data).length != 0 && this.data.constructor === Object) {
      this.tableData = this.data.records;
      this.entity = this.data.entity;
      this.bigTotalItems = this.totalCount = this.data.total;
      if (this.currentPage === 1) {
        if (this.data.total > 0) {
        this.from = 1;
        }
        this.to = this.data.total < 11 ? this.data.total : 10;
      }
    }
  }

  confirmDeletion(entityId, entity): void {
    this.onDeleteQuery.emit(entityId);
    this.modalRef.hide();
  }

  decline(): void {
    this.modalRef.hide();
  }

  download(entity) {
    this.sharedService.downloadFromS3(entity.key).subscribe
    (data => {

      const blob = data;
      const file = new Blob([blob], {});
      const filename = entity.key.substring(entity.key.indexOf('/') + 1, entity.key.length);
      saveAs(file, filename);
    });
  }

  findNotificationType(columns) {
    const col = columns.find(elem => elem.type === 'NOTIFICATION');
    return col.key;
  }

  setupLink(type, reference) {
    if (this.actions.includes(type)) {
    return type + '/' + reference;
    }
    return '';
  }


}

