import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { ProcessService } from 'src/app/services/process.service';
import { SharedService } from 'src/app/services/sharedService';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.css']
})
export class DocumentsComponent implements OnInit {
  executionSteps: any[];

  uploadElement = {
    "type": "upload",
    "key": "file_upload",
    "title": null
  }
  process: any;
  constructor(private route: ActivatedRoute, private processService: ProcessService, private sharedService: SharedService) {
  }
  ngOnInit() {
    if (this.route.snapshot.paramMap.get('id')) {
      this.route.paramMap.pipe(
        switchMap((params: ParamMap) => this.processService.getProcess(params.get('id'))))
        .subscribe(process => {
          setTimeout(() => {
            this.process = process;
            this.executionSteps = process.tasks.filter(elem => elem.step === 'EXECUTION')
          }, 200);
        });
    }
  }

  onFileUpload(key, file) {
    if (!this.process.documents)
      this.process.documents = []
    this.process.documents.push({ key: key, value: file, type: "upload", title: this.uploadElement.title })
    this.uploadElement = {
      "type": "upload",
      "key": "file_upload",
      "title": null
    }
    this.saveDocs()

  }

  onStepFileUpload(elementKey, stepKey, file) {
    let task = this.process.tasks.find(elem => elem.key === stepKey)
    if (task)
      task.items.forEach(item => {
        let el = item.elements.find(el => el.key === elementKey)
        if (el) {
          el.value = file
          this.sharedService.reloadProcess(this.process.id)
          this.processService.saveTask(this.process.id, stepKey, task).subscribe(result => {
            this.initializePage()
          });
        }
      })

  }
  deleteFromProcess(key) {
    const documents = this.process.documents
    this.process.documents = documents.filter(doc => doc.value.key != key)
    this.saveDocs()
  }

  saveDocs() {
    this.processService.saveDocuments( this.process.id, this.process.documents).subscribe(res => {
      this.initializePage()
    })
  }

  initializePage(){
    if (this.route.snapshot.paramMap.get('id')) {
      this.route.paramMap.pipe(
        switchMap((params: ParamMap) => this.processService.getProcess(params.get('id'))))
        .subscribe(process => {
          setTimeout(() => {
            this.process = process;
            this.executionSteps = process.tasks.filter(elem => elem.step === 'EXECUTION')
          }, 200);
        });
    }
  }

}
