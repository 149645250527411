import { Component, OnInit } from '@angular/core';
import { DashboardService } from 'src/app/services/dashboard.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  chart = [];
  dashboards = [];

  constructor(public dashboardService: DashboardService) { }

  ngOnInit() {
    this.dashboardService.getTotalDashboardList("").subscribe(dashboards => {
      dashboards.forEach(d => {
        d.dashboardConfigs.forEach(dd => {
          dd['yearly'] = d.yearly
          dd['periodFilter'] = d['periodFilter']
          dd['rangeFilter'] = d['rangeFilter']
          dd['datePeriod'] = d['datePeriod']
          dd['dateRange'] = d['dateRange']
        })
      })
      this.dashboards = dashboards;
    })
  }
}
