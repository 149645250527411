export class Indicator {
  id: number;
  name: string;
  serviceLeft: number;
  serviceRight: number;
  fileFormatLeft: number;
  fileFormatRight: number;
  matchLeft: string;
  matchRight: string;
  compareLeft: string[];
  compareRight: string[];
  dateLeft: string;
  dateRight: string;
  filterDateFrom: Date;
  filterDateTo: Date;
  percentageLeft: number;
  percentageRight: number;
  riskManagement: boolean;
  operation: string;
  year: number;
  Default = {
    ponderation:0,
    r1 : [1,33],
    r2 : [34,66],
    r3 : [67,100],
  }
  IFU1 = {
    ponderation:0,
    r1 : [1,33],
    r2 : [34,66],
    r3 : [67,100],
  }
  IFU2 = {
    ponderation:0,
    r1 : [1,33],
    r2 : [34,66],
    r3 : [67,100],
  }
  IFU3 = {
    ponderation:0,
    r1 : [1,33],
    r2 : [34,66],
    r3 : [67,100],
  }
  IFU4 = {
    ponderation:0,
    r1 : [1,33],
    r2 : [34,66],
    r3 : [67,100],
  }
  IFU5 = {
    ponderation:0,
    r1 : [1,33],
    r2 : [34,66],
    r3 : [67,100],
  }
}
