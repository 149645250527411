import {Injectable} from '@angular/core';
import {Service} from "../entities/service";
import {SharedService} from "./sharedService";
import {PagerDataObject} from "../entities/pagerDataObject";
import {catchError, map, tap} from 'rxjs/operators';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Currency} from "../entities/currency";
import { Observable, of } from 'rxjs';

const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};

@Injectable()
export class IndicatorSectorService {

  constructor(private http: HttpClient, private sharedService: SharedService) {
  }

  getData(query: string): Observable<PagerDataObject> {
    if (query !== null)
    var indSectors$ = this.http.get<PagerDataObject>(this.sharedService.apiUrl('api/ind_sectors', query !== null?("/" + query):""))
    return indSectors$;
  }

  getIndicatorSectors(): Observable<any> {
    let indicators$ = this.http.get<any>(this.sharedService.apiUrl('api/ind_sectors/plain',""))
    return indicators$;
  }

  add(currency: Currency): Observable<Currency> {
    return this.http.post<Currency>(this.sharedService.apiUrl('api/ind_sectors/add'), currency, httpOptions).pipe(
      tap((currency: Currency) => this.log(`added currency w/ id=${currency.id}`)),
      catchError(this.handleError<Currency>('add'))
    );
  }

  getCurrency(id: string): Observable<Currency> {
    let currency$ = this.http.get<Currency>(this.sharedService.apiUrl('api/ind_sectors/' + id))
    return currency$;
  }

  delete(currency: Currency): Observable<Currency> {
    return this.http.post<Currency>(this.sharedService.apiUrl('api/ind_sectors/delete'), currency, httpOptions).pipe(
      tap((currency: Currency) => this.sharedService.log(`deleted batch process w/ id=${currency.id}`)),
      catchError(this.sharedService.handleError<any>('deleteCurrency'))
    );
  }

  columns: Array<any> = [
    // {key: 'view', style: {'flex': '0 0 100px', 'width': '1%'}, button: false},
    {name: 'Name', tag: 'INDICATOR-SECTOR.NAME', key: 'name', filtering: true, sorting: true, sortingOrder: 'asc'},
    {name: 'Description', tag: 'INDICATOR-SECTOR.DESCRIPTION', key: 'description', filtering: true, sorting: true}
  ];

  getMetaInfo(): string[] {
    return ['name', 'description'];
  }

  getColumns(): string[] {
    return this.columns;
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  private log(message: string) {
    //this.messageService.add('HeroService: ' + message);
  }
}
