/**
 * Created by aleco on 2/14/2017.
 */

import {Component, Input} from '@angular/core';

@Component({
  selector: '<ntr-add-button>',
  templateUrl: './add-button.component.html'
})


export class AddButtonComponent {
  @Input() openView;


}
