import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, ParamMap} from '@angular/router';
import {ProcessService} from '../../../services/process.service';
import {switchMap} from 'rxjs/operators';

@Component({
    selector: 'app-timeline',
    templateUrl: './timeline.component.html',
    styleUrls: ['./timeline.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class TimelineComponent implements OnInit {
    process: any;
    constructor(private route: ActivatedRoute, private processService: ProcessService) {
    }
    ngOnInit() {
        if (this.route.snapshot.paramMap.get('id')) {
            this.route.paramMap.pipe(
                switchMap((params: ParamMap) => this.processService.getProcess(params.get('id'))))
                .subscribe(process => {
                    setTimeout(() => {
                    this.process = process; }, 200);
                });
        }
    }

    getColor(taskStatus) {
        if (taskStatus === 'ONGOING') {
            return '#337AB7F2';
        }
        if (taskStatus === 'DRAFT') {
            return '#8f8f91';
        }
        if (taskStatus === 'APPROVED') {
            return '#18a718';
        }
        if (taskStatus === 'SUBMITTED') {
            return '#beb340';
        }
        if (taskStatus === 'ASSIGNED') {
            return '#b53fa5';
        }
        return '#000000';

    }

}



