/**
 * Created by aleco on 9/26/2017.
 */

import { Component, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { ServiceComponent } from "../service.component";
import { Service } from "../../../../entities/service";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { ServiceService } from "../../../../services/service.service";
import { FileItem, FileUploader, ParsedResponseHeaders } from "ng2-file-upload";
import { Router } from "@angular/router";
import { SharedService } from 'src/app/services/sharedService';
import { TasService } from 'src/app/services/tas.service';
import { UserService } from 'src/app/services/user.service';
import { User } from 'src/app/entities/user';

@Component({
  selector: '<ntr-service-csv>',
  templateUrl: './service-csv.component.html'
})
export class ServiceCsvComponent implements ServiceComponent, OnInit {
  ngOnInit(): void {
    this.userService.getCurrentUser().subscribe(user => {
      this.user = user;
  });
  }
  @ViewChild('fileUpload', { static: false }) selectedFile: any;
  
  @Input() currencies: any[];
  @Input() service: Service;
  modalRef: BsModalRef;
  modalRefErrorMessage: BsModalRef;
  selectedFileFormat = {};
  errorMessage: string;
  @ViewChild('informationTemplate', { static: false })
  private informationTemplate: TemplateRef<any>;
  public uploader: FileUploader;
  public hasBaseDropZoneOver: boolean = false;
  public hasAnotherDropZoneOver: boolean = false;
  fileFormatId: any;
  user: User;
  
  constructor(private modalService: BsModalService, private userService: UserService, private serviceService: ServiceService, private router: Router, 
    public tasService: TasService, public sharedService: SharedService) {
    this.uploader = new FileUploader({url: sharedService.apiUrl('api/services')+'csv_file_upload',
    authToken: "Bearer " + localStorage.getItem('access_token'),
  });
    this.uploader.onBuildItemForm = (item, form) => {
      form.append("serviceId", this.service.id);
      form.append("fileFormatId", this.fileFormatId);
      form.append("serviceName", this.service.name);
      form.append("fileFormatName", this.service.fileFormats.find(fileFormat => fileFormat['id'] === this.fileFormatId)['name']);
    };
    this.uploader.onSuccessItem = (item, response, status, headers) => this.onSuccessItem(item, response, status, headers);
  }

  onSuccessItem(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any {
    let data = JSON.parse(response); //success server response
    this.router.navigateByUrl('/batch-process-view/'+data);
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, {class: 'modal-sm'});
  }

  openModalMessage(template: TemplateRef<any>) {
    this.modalRefErrorMessage = this.modalService.show(template, {class: 'modal-sm'});
  }


  confirmDeletion(serviceId, id): void {
    this.deleteFileFormat(serviceId, id);
    this.modalRef.hide();
  }
 

  confirmDuplication(id, name): void {
    var formatToDuplicate = this.service.fileFormats.find(fileFormat => fileFormat['id'] === id);
    formatToDuplicate['id'] = null;
    formatToDuplicate['name'] = name;
    formatToDuplicate['serviceId'] = this.service.id
    this.serviceService.addFileFormat(formatToDuplicate).subscribe(service => {
      this.serviceService.updateService(service);
      this.modalRef.hide();
    }, error => {
      this.errorMessage = 'ERRORS.' + error.code;
      this.openModalMessage(this.informationTemplate);
    });
  }
  fetch(fileFormat){
    let fileformatId = fileFormat.id
    let declarationId = fileFormat.declarationId
    let stageKey = fileFormat.stageKey
    let blockKey = fileFormat.blockKey
    this.tasService.getTasTableData(declarationId, stageKey, blockKey, fileformatId, fileFormat.name, this.service.id, this.service.name).subscribe(data => {
      
    })
  }


  deleteFileFormat(serviceId, id) {
    var fileFormat = {serviceId: serviceId, id: id}
    this.serviceService.deleteFileFormat(fileFormat)
      .subscribe(service => {
        this.serviceService.updateService(service);
      });
  }

  decline(): void {
    this.modalRef.hide();
  }

  closeModalMessage(): void {
    this.modalRefErrorMessage.hide();
  }

  showFileFormat(value: Object) {
      this.selectedFileFormat = value;
      this.sharedService.changeFileFormatEmit(this.selectedFileFormat)
  }

  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }

  public fileOverAnother(e: any): void {
    this.hasAnotherDropZoneOver = e;
  }

  clearSelectedFile(){
    this.selectedFile.nativeElement.value = '';
  }
}
