import { Component, Input, OnChanges, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { StepperEnum } from 'src/app/entities/stepperEnum';
import { ProcessService } from 'src/app/services/process.service';
import { SharedService } from '../../../services/sharedService';
import { FormControl, FormGroup } from '@angular/forms';
import { merge, Subject, Subscription } from 'rxjs';
import { debounce, debounceTime, share, startWith, switchMap } from 'rxjs/operators';
import { saveAs } from 'file-saver';
import { NgSelectComponent } from '@ng-select/ng-select';
import { RiskService } from 'src/app/services/riskService';
import { yearList } from 'src/app/util/yearPicker';
import { UserService } from 'src/app/services/user.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastService } from 'src/app/services/toast-service';
import { TranslateService } from '@ngx-translate/core';
import { IfuService } from 'src/app/services/ifuService';
@Component({
  selector: 'app-step-tabber',
  templateUrl: './step-tabber.component.html',
  styleUrls: ['./step-tabber.component.css']
})
export class StepTabberComponent implements OnInit {
  paramQuery: string;
  filterForm: FormGroup;
  modalRef?: BsModalRef;
  obs: Subscription;
  executionTasks = []
  constructor(private processService: ProcessService, private sharedService: SharedService, private riskService: RiskService,
    private ifuService: IfuService,
    private modalService: BsModalService,
    public toastService: ToastService,
    private translateService: TranslateService,
    private userService: UserService) {

    this.filterForm = new FormGroup({
      search: new FormControl(),
      year: new FormControl(),
      verifyPeriod: new FormControl(),
      submitPeriod: new FormControl(),
      semester: new FormControl(),
      executionTask: new FormControl(),
    });
    // /this.filterForm.get('semester').setValue(this.getSemester());
  }
  risks$: any[] = [];
  risksLoading = false;
  risksInput$ = new Subject<string>();
  ifus$: any[] = [];
  ifusLoading = false;
  ifusInput$ = new Subject<string>();
  years = []

  @ViewChild('riskMulti', { static: false }) public riskMulti: NgSelectComponent;
  @Input() steps: any[];
  @Input() type: any;
  @Input() processes: any;
  numPages = 0;
  maxSize = 10;
  bigTotalItems = 0;
  bigCurrentPage = 1;
  from = 0;
  to = 0;
  currentPage = 1;
  totalCount = 0;
  respProcesses: any;
  tab: any;
  searchText: any;
  editor = false;
  risks = []
  ifus = []
  printValue(event, valType) {
    if (valType === 'risk')
      this.risks = event.map(function (item) {
        return item['key'];
      })
    if (valType === 'ifu')
      this.ifus = event.map(function (item) {
        return item['key'];
      })
    this.processService.getProcesses(this.tab === 'ALL' ? null : this.tab, null,
      localStorage.getItem('currentControlType'), this.from, this.maxSize,
      this.tab === 'DONE' ? 'COMPLETED' : null, this.filterForm.value.search, this.risks,
      this.filterForm.value.verifyPeriod, this.filterForm.value.submitPeriod,
      this.filterForm.value.semester, this.filterForm.value.year, this.editor, this.ifus, this.filterForm.value.executionTask
    ).subscribe(processes => {
      this.paramQuery = processes.query;
      this.to = processes.records.length;
      this.respProcesses = processes;
      this.setRecordsAndFilters(processes, false)
      this.setupPaginator();
    }
    );
  }
  ngOnInit() {
    this.years = yearList()
    this.riskService.getTotalRiskList().subscribe(risks => {
      this.risks$ = risks;
    })
    this.ifuService.getTotalIfuList().subscribe(ifus => {
      this.ifus$ = ifus;
    })
    this.userService.getCurrentUser().subscribe(user => {

      const roles = ['CONTROLLER', 'DIRECTOR_APPROVER', 'DIRECTOR_GENERAL', 'COORDINATOR', 'INSPECTOR', 'CHEF'];
      if (!roles.some(item => user.roles.includes(item))) {
        this.editor = true
      }
      if (this.type === StepperEnum.List) {
        this.obs = this.filterForm.valueChanges
          .pipe(
            debounceTime(500),
            switchMap(id => {
              return this.processService.getProcesses(this.tab === 'ALL' ? null : this.tab, null,
                localStorage.getItem('currentControlType'), this.from, this.maxSize,
                this.tab === 'DONE' ? 'COMPLETED' : null, this.filterForm.value.search, this.risks,
                this.filterForm.value.verifyPeriod, this.filterForm.value.submitPeriod, this.filterForm.value.semester, this.filterForm.value.year, this.editor, this.ifus, this.filterForm.value.executionTask);
            })
          )
          .subscribe(processes => {
            this.paramQuery = processes.query;
            this.to = processes.records.length;
            this.respProcesses = processes;
            this.setRecordsAndFilters(processes, false)
            this.setupPaginator();
          }
          );
        this.processService.getProcesses(this.tab === 'ALL' ? null : this.tab, null,
          localStorage.getItem('currentControlType'), this.from, this.maxSize, null, this.filterForm.value.search, this.risks, this.filterForm.value.verifyPeriod, this.filterForm.value.submitPeriod, 
          this.filterForm.value.semester, this.filterForm.value.year, this.editor, this.ifus, this.filterForm.value.executionTask).subscribe(processes => {
            this.paramQuery = processes.query;
            this.to = processes.records.length;

            this.respProcesses = processes
            this.setRecordsAndFilters(processes, false)
            this.setupPaginator();
          });
      }
      this.sharedService.teamChangedEventEmitter.subscribe(teamId => {
        this.processService.getProcesses(this.tab === 'ALL' ? null : this.tab, teamId, localStorage.getItem('currentControlType'), this.from, this.maxSize, null, this.filterForm.value.search, this.risks, this.filterForm.value.verifyPeriod, this.filterForm.value.submitPeriod, this.filterForm.value.semester, 
        this.filterForm.value.year, this.editor, this.ifus, this.filterForm.value.executionTask).subscribe(processes => {
          this.paramQuery = processes.query;
          this.setRecordsAndFilters(processes, false)
        });
      });
      this.sharedService.controlTypeChangedEventEmitter.subscribe(controlType => {
  
        this.processService.getProcesses(this.tab === 'ALL' ? null : this.tab, null, controlType, this.from, this.maxSize, null, this.filterForm.value.search,
          this.risks, this.filterForm.value.verifyPeriod, this.filterForm.value.submitPeriod, 
          this.filterForm.value.semester, this.filterForm.value.year, this.editor, this.ifus, this.filterForm.value.executionTask).subscribe(processes => {
            this.paramQuery = processes.query;
            this.setRecordsAndFilters(processes, true)
          });
      });
      this.sharedService.addProcessEventEmitter.subscribe(teamId => {
        this.processService.getProcesses(this.tab === 'ALL' ? null : this.tab, null,
          localStorage.getItem('currentControlType'), this.from, this.maxSize, null, this.filterForm.value.search,
          this.risks, this.filterForm.value.verifyPeriod, this.filterForm.value.submitPeriod, this.filterForm.value.semester,
          this.filterForm.value.year, this.editor, this.ifus, this.filterForm.value.executionTask).subscribe(processes => {
            this.paramQuery = processes.query;
            this.respProcesses = processes;
            this.setRecordsAndFilters(processes, false)
            this.setupPaginator();
          });
      });
    });
  }

  downloadList(apiUrl) {
    this.processService.getProcessesFile(apiUrl, null, null,
      localStorage.getItem('currentControlType'), this.from, this.maxSize, null,
      this.filterForm.value.search, this.risks, this.filterForm.value.verifyPeriod,
      this.filterForm.value.submitPeriod, this.filterForm.value.semester, this.filterForm.value.year, this.editor, this.ifus, this.filterForm.value.executionTask).subscribe(processes => {
        const blob = processes;
        const file = new Blob([blob], {});
        saveAs(file, localStorage.getItem('currentControlTypeName') + '.xlsx');
      });
  }
  changePage(event) {
    this.from = (event - 1) * this.maxSize;
    this.to = this.maxSize
    this.setPage(event);
  }

  setPage(pageNo: number): void {

    this.currentPage = pageNo;
    this.fireQueryEvent();
  }

  fireQueryEvent() {
    if (this.tab || this.currentPage > 1) {
      this.processService.getProcesses(this.tab === 'ALL' ? null : this.tab, null,
        localStorage.getItem('currentControlType'), this.from, this.maxSize, this.tab === 'DONE' ? 'COMPLETED' : null,
        this.filterForm.value.search, this.risks, this.filterForm.value.verifyPeriod,
        this.filterForm.value.submitPeriod, this.filterForm.value.semester, this.filterForm.value.year, this.editor, this.ifus, this.filterForm.value.executionTask).subscribe(processes => {
          this.paramQuery = processes.query;
          this.to = processes.records.length;
          this.respProcesses = processes;
          this.setRecordsAndFilters(processes, false)
          this.setupPaginator();
        });
    }
    if (this.currentPage > 1 && !this.tab) {
      this.tab = 'ALL'
    }

  }

  isSingle() {
    return this.type === StepperEnum.Single;
  }

  getProcessesByStep(step) {
    return this.processes.filter(elem => elem.step === step);
  }

  getTab(tab) {
    this.tab = tab;
    this.processService.getProcesses(tab === 'ALL' ? null : tab, null,
      localStorage.getItem('currentControlType'), this.from, this.maxSize, tab === 'DONE' ? 'COMPLETED' : null,
      this.filterForm.value.search, this.risks, this.filterForm.value.verifyPeriod,
      this.filterForm.value.submitPeriod,
      this.filterForm.value.semester, this.filterForm.value.year, this.editor, this.ifus, this.filterForm.value.executionTask)
      .subscribe(processes => {
        this.paramQuery = processes.query;
        this.respProcesses = processes;
        this.setRecordsAndFilters(processes, false)
        this.setupPaginator();
      });
  }

  setupPaginator(): void {
    this.bigTotalItems = this.totalCount = this.respProcesses.total;
    if (this.currentPage === 1) {
      if (this.respProcesses.total > 0) {

      }
      this.to = this.respProcesses.total < 11 ? this.respProcesses.total : 10;
    }
  }

  getSemester() {
    const d = new Date();
    let month = d.getMonth();
    if (month < 6)
      return 2;
    return 1;
  }
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { class: 'modal-sm' });
  }
  confirmApproveAll() {
    this.processService.approveAll(this.paramQuery).subscribe(res => {
      this.currentPage = 1;
      this.showSuccess('CONTROL-MODULE.APPROVED_ALL');
      this.fireQueryEvent()
      this.modalRef.hide()
    })
  }

  showSuccess(text) {
    this.toastService.show(this.translateService.instant(text), { classname: 'bg-success text-light', delay: 2000 });
  }

  setRecordsAndFilters(resp, reloadExecTasks){
    this.processes = resp.records;
    this.totalCount = resp.total;
    if ((reloadExecTasks || this.executionTasks.length===0) && resp.total && resp.total>0 && localStorage.getItem('currentControlType')!==null && localStorage.getItem('currentControlType')!=='null'){
      this.executionTasks = []
      let containsExec = false;
      let ind = 0
      while (!containsExec){
        var st = this.processes[ind].tasks.filter(tt => tt.step==='EXECUTION')
        if (st.length>0){
          containsExec = true
          this.executionTasks = st.map(elem => {
            const s = {}
            s['name'] = elem.name
            s['key'] = elem.key
            return s
          })
        }
        ind++;
      }
    }
  }
}


