import {CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {Injectable} from '@angular/core';
import {JwtHelperService} from '@auth0/angular-jwt';
import {AuthService} from '../services/auth.service';
import {SharedService} from '../services/sharedService';
import {Observable, of} from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class AuthGuard implements CanActivate {
    constructor(private router: Router, public jwtHelper: JwtHelperService,
                private auth: AuthService, public authService: AuthService, public sharedService: SharedService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        let jwtRenewal = false;
        if (route.queryParams.nonce) {
            this.router.navigate(['login'],
                {queryParams: {nonce: route.queryParams.nonce, returnUrl: route.queryParams.returnUrl}});
            return of(true);
        }
        if (!localStorage.getItem('access_token')) {
            this.router.navigate(['login'], {queryParams: {url: route.url}});
            return of(true);
        }

        if (localStorage.getItem('access_token') && localStorage.getItem('access_token') !== 'undefined') {
            if (this.jwtHelper.isTokenExpired()) {
                this.authService.requestToken(localStorage.getItem('access_token')).then(res => {
                    if (res === undefined) {
                        this.authService.logout().subscribe(data => {
                            const promise = new Promise((resolve, reject) => {
                                localStorage.removeItem('access_token');
                                resolve(null);
                            });
                            promise.then(res => {
                                this.router.navigate(['login']);
                            });
                        });
                        return of(false);
                    } else {
                        localStorage.setItem('access_token', res);
                        this.auth.setLoginStatus(true);
                        location.reload()
                    }
                });
            } else {
                this.auth.getCurrentUser().subscribe(user => {
                    this.auth.setLoginStatus(true);
                    if (user['status'] === 'ENABLED') {
                        this.auth.setUserEnabled(true);
                    } else {
                        this.auth.setUserEnabled(false);
                    }
                });
            }
            // Here we should get Current User information (we need here User roles)
            //   if (route.data['roles']) {
            //     return this.userservice.currenuser$().pipe(
            //       first(),
            //       tap((user: User) => {
            //         if (!(user && user.role && route.data['roles'].find(item => item == user.role))){
            //           this.router.navigate(['/notFound'], {queryParams: {returnUrl: state.url}});
            //         }else{
            //         }

            //       }),
            //       map((user: User) =>
            //         !!(user && user.role && route.data['roles'].find(item => item == user.role))
            //       ));
            //   }
            return of(true);
        }

        // not logged in so redirect to login page with the return url
        // this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
        if (!jwtRenewal) {
            // this.router.navigate([state.url]);
            return of(true);
        }
    }
}
