import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { User } from 'src/app/entities/user';
import { ProcessService } from 'src/app/services/process.service';
import { SharedService } from 'src/app/services/sharedService';

@Component({
  selector: 'app-action-timesheet',
  templateUrl: './action-timesheet.component.html',
  styleUrls: ['./action-timesheet.component.css']
})
export class ActionTimesheetComponent implements OnInit {
  @Input() timesheet;
  @Input() type;
  @Input() processId;
  @Output() timesheetApproved = new EventEmitter<any>();
  @Input() user: User;
  timesheets = []
  
  constructor(private processService: ProcessService, private sharedService: SharedService) { }

  ngOnInit() {
   this.setupTimesheets()
   this.sharedService.submitTimesheetEmitter.subscribe(tm => {
    this.timesheet = tm;
    this.setupTimesheets()
   })
  }

  setupTimesheets(){
    if (this.timesheet.done){
      this.timesheets = []
      var doneTs = this.timesheet.done.filter(d=>d.status===this.type)
      doneTs.forEach(d => {
        this.timesheets.push({ timesheet: {rows: d.rows, columns: this.timesheet.columns, id:d.id},  user:d.user, submitDate:d.submitDate, status:d.status})
      })
    }
  }

  approveSheet(timesheetId){
      this.processService.approveTimesheet(this.processId, timesheetId).subscribe(res => {
        this.timesheetApproved.emit();
        this.sharedService.submitTimesheet(res.timesheet)
      })
  }

}
