 
//import { UserStatus } from './states/userStatus';
import { Role } from './role';
 
// const UserStatusName = new Map<number, string>([
//   [UserStatus.ACTIVE, 'ACTIVE'],
//   [UserStatus.SUSPENDED, 'SUSPENDED']
// ]);

const RoleName = new Map<number, string>([
  [Role.ADMIN, 'ADMIN']
]);

var replaceValues = (obj1: any, obj2: any) => {
  for (var key in obj1) {
    delete obj1[key]
  }
  for (var key in obj2) {
    obj1[key] = obj2[key]
  }
}

export {  RoleName, replaceValues }