import {DashboardService} from 'src/app/services/dashboard.service';

export class FusionChart {

    public barChartLabels = [];
    public barChartLabelsDate = [];
    public barChartType = '';
    public barChartLegend = true;
    public dashboardConfigs;
    public barChartData = null;

    constructor(public dashboardService: DashboardService) {

    }

    loadData(dashboardConfigs) {
        this.barChartLabels = [];
        this.barChartLabelsDate = [];
        this.dashboardConfigs = dashboardConfigs;
        const dataMap = new Map();

        this.dashboardService.getDashboardData(this.dashboardConfigs).subscribe(dd => {
            dd.forEach(res => {
                const data = res.result;
                const resValueCol = res.valueCol;
                if (data === null) {
                    return;
                }
                data.forEach(element => {
                    const res = element._id;
                    if (res.month === null && res.year === null) {
                        if (!this.barChartLabels.includes(resValueCol)) {
                            this.barChartLabels.push(resValueCol);
                            this.barChartLabelsDate.push(resValueCol);
                        }
                    } else {
                        if (!this.barChartLabels.includes(res.month ? res.month + '/' + res.year : res.year)) {
                            this.barChartLabels.push(res.month ? res.month + '/' + res.year : res.year);
                            this.barChartLabelsDate.push(res.month ? new Date(res.year + '-' + res.month + '-01') : res.year);
                        }
                    }
                });
                this.barChartLabelsDate.sort((a, b) => a - b);
                this.barChartLabels = [];
                this.barChartLabelsDate.forEach(d => {
                    if (d !== null) {
                        if (typeof d.getMonth === 'function') {
                            this.barChartLabels.push((d.getMonth() + 1) + '/' + d.getFullYear());
                        } else {
                            this.barChartLabels.push(d);
                        }
                    }
                });
                data.forEach(element => {
                    const res = element._id;
                    let indexOfDate;
                    if (res.month === null && res.year === null) {
                        indexOfDate = this.barChartLabels.indexOf(resValueCol);
                    } else {
                        indexOfDate = this.barChartLabels.indexOf(res.month ? res.month + '/' + res.year : res.year);
                    }
                    let elem = dataMap.get((res.company !== undefined ? res.company + ' ' : '') + (res.mineral !== undefined ?
                        res.mineral + ' ' : '') + resValueCol);
                    if (elem) {
                        while (indexOfDate > elem.data.length) {
                            elem.data.push(0);
                        }

                        if (res.month === null && res.year === null) {
                            elem[data] = elem.data.push(this.barChartLabels.includes(resValueCol) ? (element.result * 1) : 0);
                        } else {
                            elem[data] = elem.data.push(this.barChartLabels.includes(res.month ? res.month + '/' + res.year : res.year) ?
                                (element.result * 1) : 0);
                        }
                    } else {
                        let dataArr: Array<number>;
                        dataArr = [];
                        if (res.month !== null || res.year !== null) {
                            while (indexOfDate > dataArr.length) {
                                dataArr.push(0);
                            }
                        }

                        dataArr.push(element.result);
                        elem = {
                            data: dataArr, label: (res.company !== null ? res.company + ' ' : '') + (res.mineral !== null ?
                                res.mineral + ' ' : '') + resValueCol
                        };
                        dataMap.set((res.company !== null ? res.company + ' ' : '') + (res.mineral !== null ?
                            res.mineral + ' ' : '') + resValueCol, elem);
                    }
                });
                this.barChartData = [];
                dataMap.forEach(elem => {
                    if (elem.data[0] != null) {
                        if (this.dashboardConfigs.chart === 'stacked') {
                            this.barChartData.push({data: elem.data as Array<number>, label: elem.label + '', stack: 'a'});
                        } else if (this.dashboardConfigs.chart === 'pie' && this.barChartLabelsDate[0] === null &&
                            (res.month !== null || res.year !== null)) {
                            if (this.barChartData.length === 0) {
                                const x = {};
                                x['data'] = [];
                                this.barChartData.push(x);
                            }
                            this.barChartData[0].data.push(elem.data[0]);
                            if (this.barChartLabels.includes(elem.label + '')) {
                            }
                        } else {
                            this.barChartData.push({data: elem.data as Array<number>, label: elem.label + ''});
                        }
                    }
                });
            });
        });
    }

    async asyncForEach(array, callback) {
        for (const item of array) {
            await callback(item);
        }
    }

}
