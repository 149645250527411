import { Component, OnInit, Input } from '@angular/core';
import { DashboardService } from 'src/app/services/dashboard.service';
import { FusionChart } from 'src/app/components/charts/fusion-chart';
import { ExportToCsv } from 'export-to-csv';
import { ExcelService } from 'src/app/services/excel.Service';
@Component({
  selector: 'app-stacked-chart',
  templateUrl: './stacked-chart.component.html',
  styleUrls: ['./stacked-chart.component.css']
})
export class StackedChartComponent extends FusionChart implements OnInit {
  @Input() title;
  @Input() footer;
  @Input() dashboardConfigs;
  @Input() description;
  @Input() index;

  constructor(public dashboardService: DashboardService, private excelService: ExcelService) {
    super(dashboardService);
  }
  public barChartOptions = {
    scaleShowVerticalLines: false,
    responsive: true,
    fill: false
  };

  ngOnInit() {
    this.barChartData = [{ data: [] }];
    this.barChartType = 'bar';
    this.dashboardConfigs['chart'] = 'stacked';
    super.loadData(this.dashboardConfigs);

  }

  downloadCanvas(event) {
    // get the `<a>` element from click event
    const anchor = event.target;
    // get the canvas, I'm getting it by tag name, you can do by id
    // and set the href of the anchor to the canvas dataUrl
    const canvas = <HTMLCanvasElement> document.getElementById('stacked_' + this.index);
    anchor.href = canvas.toDataURL();
    // set the anchors 'download' attribute (name of the file to be downloaded)
    anchor.download = this.title + '.png';
  }

  downloadCSV() {
    const options = {
      filename: this.title,
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: true,
      title: this.title,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
    };
    const csvExporter = new ExportToCsv(options);
    csvExporter.generateCsv(this.getData());
  }

  downloadExcel(): void {
    this.excelService.exportAsExcelFile(this.getData(), this.title);
  }
  getData() {
    const data = [];
    this.barChartData.forEach(elem => {
      const el = {};
      el['-'] = elem.label;
      this.barChartLabels.forEach((elemLabel, index) => {
        el[elemLabel] = index < elem.data.length ? elem.data[index] : 0;
      });
      data.push(el);
    });
    return data;
  }

}
