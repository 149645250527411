/**
 * Created by aleco on 9/26/2017.
 */

import { Component, Input, OnInit, AfterViewInit, Output, OnDestroy } from '@angular/core';
import { ServiceService } from "../../services/service.service";
import { saveAs } from 'file-saver';
import { PagerDataObject } from "../../entities/pagerDataObject";

import { Service } from '../../entities/service';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { of, Subject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { CsvListService } from 'src/app/services/csvList.service';
import { SharedService } from 'src/app/services/sharedService';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'ntr-csv-list',
  templateUrl: './csvlist.component.html',
  styleUrls: ['./csvlist.component.css']
})
export class CsvListComponent implements OnInit, AfterViewInit, OnDestroy {

  service: Service = new Service();
  metaInfo: string[];
  columns: Array<any>;
  data: PagerDataObject;
  @Input()
  headers = [' ', 'Name', 'Code', 'Type'];
  @Input()
  disableHeader = false;
  subscription: any;
  currentFileFormatId: any;
  startLoading: boolean = false;
  query: string;
  private dataSub$ = new Subject<any>();

  constructor(private serviceService: ServiceService, private sharedService: SharedService,
              private csvListService: CsvListService, private route: ActivatedRoute,
              private spinner: NgxSpinnerService) {
    this.metaInfo = [];
    this.columns = [];
  }

  ngOnInit(): void {
    if (this.route.snapshot.paramMap.get('id')) {
      this.route.paramMap.pipe(
        switchMap((params: ParamMap) => this.serviceService.getService(params.get('id'))))
        .subscribe(service => {
          this.getServiceData(service);
        });
    } else {
      this.serviceService.getTotalServiceList("").subscribe(services => {
        if (services.length > 0)
          this.getServiceData(services[0]);
      })
    }
  }

  ngAfterViewInit(): void {
    this.sharedService.loadMenuItems('services');
  }


  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  onGridQuery(query) {
    this.query = query;
    this.dataSub$.next(query);
  }

  downloadExcel() {
    this.spinner.show();
    this.serviceService.downloadExcel(this.service.id, this.currentFileFormatId, this.query).subscribe
      (data => {
        const blob = data;
        const file = new Blob([blob], {});
        const filename = 'document-' + Date.now() + '.xlsx';
        this.spinner.hide();
        saveAs(file, filename);
      });

  }

  onChange(selectedFileFormat) {
    this.startLoading = true;
    this.currentFileFormatId = selectedFileFormat;
    this.columns = [];
    this.service.fileFormats.find(item => item['id'] === this.currentFileFormatId)['mappingObjects'].forEach((mo) => {
      if (mo.type && mo.type !== 1) {
        this.columns.push({
          name: mo.name,
          tag: mo.name,
          key: mo.name,
          description: mo.description,
          filtering: true,
          sorting: true,
          sortingOrder: 'asc',
          type: mo.type
        });
      }

    });
    this.dataSub$.next(this.query);
  }


  getServiceData(service) {
    this.columns = [];
    this.service = service;
    if (this.service.fileFormats && this.service.fileFormats.length > 0) {
      this.currentFileFormatId = this.service.defaultFileFormat ? this.service.defaultFileFormat : this.service.fileFormats[0]['id'];
      var currentFileFormat = this.service.fileFormats.find(item => item['id'] == this.currentFileFormatId)
      currentFileFormat['mappingObjects'].forEach((mo) => {
        if (mo.type && mo.type !== 1)
          this.columns.push({
            name: mo.name,
            tag: mo.name,
            key: mo.name,
            description: mo.description,
            filtering: true,
            sorting: true,
            sortingOrder: 'asc',
            type: mo.type,
            isNumber: mo.type === "3"
          });
      })
    }

    if (this.subscription) {
      this.dataSub$.next(this.query);
      this.subscription.unsubscribe();
    }
    this.subscription = this.dataSub$.pipe(switchMap(filter => {
      return this.csvListService.getData(filter + '&serviceId=' + this.service.id + ((this.currentFileFormatId === undefined) ? "" : '&fileFormatId=' + this.currentFileFormatId));
    }))
      .subscribe(data => {
        this.data = data;
        this.startLoading = false;
        if (this.service.fileFormats) {
          if (data.records.length > 0)
            this.metaInfo = Object.keys(data.records[0])

        }


      });
  }


}
