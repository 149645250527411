/**
 * Created by aleco on 9/26/2017.
 */

import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, ParamMap, Router} from "@angular/router";

import {Currency} from "../../entities/currency";
import {CurrencyService} from "../../services/currency.service";
import { switchMap } from 'rxjs/operators';

@Component({
  selector: '<ntr-currency-add-edit>',
  templateUrl: './currency-add-edit.component.html'
})
export class CurrencyAddEditComponent implements OnInit {
  currency: Currency = new Currency();
  submitted = false;

  ngOnInit(): void {
    this.currency = new Currency();
    this.route.paramMap.pipe(
      switchMap((params: ParamMap) => this.currencyService.getCurrency(params.get('id'))))
      .subscribe(currency => {
        if (currency !== null) {
          this.currency = currency;
        }
      });
  }


  constructor(public currencyService: CurrencyService, private router: Router, private route: ActivatedRoute) {

  }

  onSubmit() {
    this.submitted = true;
    this.currencyService.add(this.currency)
      .subscribe(currency => {
        this.goToList();
      });
  }

  goToList() {
    this.router.navigateByUrl('/currency-list');
  }

}
