import {Injectable} from '@angular/core';
import {SharedService} from "./sharedService";
import {PagerDataObject} from "../entities/pagerDataObject";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};

@Injectable()
export class NotificationService {

  constructor(private http: HttpClient, private sharedService: SharedService) {
  }


  getData(query: string): Observable<PagerDataObject> {
    if (query !== null)
    var notifications$ = this.http.get<PagerDataObject>(this.sharedService.apiUrl('api/notification', query !== null?("/" + query):""))
    return notifications$; 
  }

  getNotificationList(): Observable<any[]> {
    let notifications$ = this.http.get<any[]>(this.sharedService.apiUrl('api/notification/list'))
    return notifications$;
  }

  getUnseenCount(): Observable<any[]> {
    let count$ = this.http.get<any[]>(this.sharedService.apiUrl('api/notification/unseen'))
    return count$;
  }

  setSeenAll(): Observable<any[]> {
    return this.http.patch<any>(this.sharedService.apiUrl('api/notification/seen'), null, httpOptions).pipe(
      tap((resource: any) => this.log(`set all notifications to seen`)),
      catchError(this.handleError<any>('notifications set seen'))
    );
  }

  columns: Array<any> = [
    {name: 'Type', tag: 'NOTIFICATIONS.TYPE', key: 'type', filtering: true, sorting: true, sortingOrder: 'asc', type: 'NOTIFICATION'},
    {name: 'Created By', tag: 'NOTIFICATIONS.CREATOR', key: 'createdBy', filtering: true, sorting: true},
    {name: 'Date', tag: 'NOTIFICATIONS.CREATE_DATE', key: 'createDate', filtering: true, sorting: true, type: 'DATE'},
    {name: 'Link', tag: 'NOTIFICATIONS.ACTION', key: 'referenceObject', filtering: true, sorting: true, type: 'REFERENCE'},
  ];

  getMetaInfo(): string[] {
    return ['type', 'createdBy', 'createDate', 'referenceObject'];
  }

  getColumns(): string[] {
    return this.columns;
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  private log(message: string) {
    //this.messageService.add('HeroService: ' + message);
  }
}
