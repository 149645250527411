import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SharedService } from 'src/app/services/sharedService';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.css']
})
export class SelectComponent implements OnInit {
  @Input() select;
  selectValue: any;
  @Output() onValueSelected = new EventEmitter<any>();
  @Input() inTable = false;
  @Input() targets = [];
  allTargets = []
  constructor(private sharedService: SharedService) {

  }

  ngOnInit() {
    this.selectValue = this.select.value;
    if (this.targets.length === 0) {
      this.targets = this.select.targets;
      this.allTargets = this.select.targets;
      if (this.targets[0].reference_key) {
        this.sharedService.childSelectPopulateEventEmitter.subscribe(value => {
          if (this.select.key === value.child) {
            this.targets = []
            this.targets = this.allTargets.filter(elem => elem.reference_key.includes(value.value))
            this.selectValue = null
          }
        })
      }
    }
  }

  onChange(event) {
    this.select.value = [event.target.value];
    this.onValueSelected.emit([event.target.value]);
    if (this.select.target_key) {
      this.sharedService.parentSelectValueChanged({ child: this.select.target_key, value: event.target.value })
    }
  }
}
