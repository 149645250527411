/**
 * Created by aleco on 9/26/2017.
 */

 import {Component, OnInit} from '@angular/core';
 import {ActivatedRoute, ParamMap, Router} from "@angular/router";
 
 import { switchMap } from 'rxjs/operators';
import { PayeeSector } from 'src/app/entities/payeeSector';
import { PayeeSectorService } from 'src/app/services/payeeSector.service';
 
 @Component({
   selector: '<payee-sector-add-edit>',
   templateUrl: './payee-sector-add-edit.component.html',
   styleUrls: ['./payee-sector-add-edit.component.css']
 })
 export class PayeeSectorAddEditComponent implements OnInit {
   payeeSector: PayeeSector = new PayeeSector();
   submitted = false;
 
   ngOnInit(): void {
     this.payeeSector = new PayeeSector();
     this.route.paramMap.pipe(
       switchMap((params: ParamMap) => this.payeeSectorService.getPayeeSector(params.get('id'))))
       .subscribe(payeeSector => {
         if (payeeSector !== null) {
           this.payeeSector = payeeSector;
         }
       });
   }
 
 
   constructor(public payeeSectorService: PayeeSectorService, private router: Router, private route: ActivatedRoute) {
 
   }
 
   onSubmit() {
     this.submitted = true;
     this.payeeSectorService.add(this.payeeSector)
       .subscribe(payeeSector => {
         this.goToList();
       });
   }
 
   goToList() {
     this.router.navigateByUrl('/payee-sectors');
   }
 
 }
 