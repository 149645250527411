import { Component, EventEmitter, Input, OnChanges, OnInit, Output,  SimpleChanges } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import { TasService } from "src/app/services/tas.service";
import { CsvMappingFields } from "../../../../entities/csvMappingFields";
import { ServiceService } from "src/app/services/service.service";
import { SharedService } from "src/app/services/sharedService";
@Component({
  selector: "app-fusion-file-format",
  templateUrl: "./fusion-file-format.component.html",
  styleUrls: ["./fusion-file-format.component.css"],
})
export class FusionFileFormatComponent implements OnInit, OnChanges {
  @Input() modal: BsModalRef;
  @Input() serviceId;
  @Input() fileFormat;
  @Output() dataFormatsAdded = new EventEmitter();
  formatName;
  declaration;
  declarations: any[];
  stage;
  stages: any[];
  block;
  blocks: any[];
  mappingFields = new CsvMappingFields();
  constructor(public tasService: TasService, public serviceService: ServiceService, private sharedService: SharedService) {
    this.sharedService.changeFileFormat.subscribe(async (fileFormat) => {
      this.declaration = fileFormat.declarationId;
      await this.onDeclarationChange(this.declaration);
      if (this.stages) {
        this.stage = this.stages.find((st) => st.key === fileFormat.stageKey).key;
        await this.onStageChange(this.stage);
        this.block = this.blocks.find((bl) => bl.key === fileFormat.blockKey);
        this.formatName = fileFormat.name;
      }
    });
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes.fileFormat && changes.fileFormat.currentValue===null){
      this.clearData()
      this.ngOnInit()
    }
  }

  ngOnInit() {
    this.tasService.getData().subscribe((data) => {
      this.declarations = data;
    });
  }

  apply() {
    if (!this.fileFormat) {
      this.fileFormat = {
        mappingObjects: [
          {
            column: 0,
            name: "Raison Sociale",
            alias: "Raison Sociale",
            description: "",
            type: this.mappingFields.COMPANY_NAME,
          },
          {
            column: 1,
            name: "Tax Id",
            alias: "Tax Id",
            description: "",
            type: this.mappingFields.TAX_ID,
          },
          {
            column: 1,
            name: "Year",
            alias: "Year",
            description: "",
            type: this.mappingFields.CUSTOM,
          },
          {
            column: 1,
            name: "Submission Date",
            alias: "Submission Date",
            description: "",
            type: this.mappingFields.DATE,
          },
        ],

        hasHeader: true,
        separator: ",",
        sample: [],
      };
    } else {
      this.fileFormat["mappingObjects"].length = 2;
    }

    this.block.headers.forEach((header) => {
      let cIndex = 2;
      let column = {
        column: cIndex,
        name: header.name,
        alias: header.name,
        description: "",
        type: this.mapType(header.type),
      };
      this.fileFormat["mappingObjects"].push(column);
    });
    (this.fileFormat["name"] = this.formatName), (this.fileFormat["declarationId"] = this.declaration);
    this.fileFormat["blockKey"] = this.block.key;
    this.fileFormat["stageKey"] = this.stage;
    this.fileFormat["fusionImport"] = true;
    this.fileFormat["serviceId"] = this.serviceId;
    this.serviceService.addFileFormat(this.fileFormat).subscribe(
      (service) => {
        this.dataFormatsAdded.emit(this.fileFormat);
        this.formatName = null;

        this.serviceService.updateService(service);
        this.closeWindow();
      },
      (error) => {
        // this.dataFormatsAdded.emit(fileFormat);
        // this.errorMessage = 'ERRORS.' + error.code;
        // this.openModal(this.informationTemplate);
      }
    );
  }
  mapType(type) {
    switch (type) {
      case "number":
        return this.mappingFields.AMOUNT;
      case "date":
        return this.mappingFields.DATE;
      default:
        return this.mappingFields.CUSTOM;
    }
  }
  closeWindow() {
    this.clearData();
    this.modal.hide();
  }
  clearData() {
    this.declarations = null;
    this.stages = null;
    this.blocks = null;
    this.declaration = null;
    this.stage = null;
    this.block = null;
    this.formatName = null;
  }

  async onDeclarationChange(ev) {
    this.stages = null;
    this.stage = null;
    this.blocks = null;
    this.block = null;
    this.stages = await this.tasService.getStages(ev).pipe().toPromise();
  }

  async onStageChange(ev) {
    this.blocks = null;
    this.block = null;
    this.blocks = await this.tasService.getBlocks(this.declaration, ev).pipe().toPromise();
  }
  onBlockChange(ev) {
    console.log(this.block);
  }
}
