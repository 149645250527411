import {Component, OnInit, TemplateRef} from '@angular/core';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {switchMap} from 'rxjs/operators';
import {StepperEnum} from 'src/app/entities/stepperEnum';
import {ProcessService} from 'src/app/services/process.service';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {UserService} from '../../../services/user.service';
import {User} from '../../../entities/user';

@Component({
    selector: 'app-process',
    templateUrl: './process.component.html',
    styleUrls: ['./process.component.css']
})
export class ProcessComponent implements OnInit {
    steps = [];
    active: any;
    stepperType = StepperEnum.Single;
    process: any;
    modalRef?: BsModalRef;
    activeTaskKey: any;
    user: User;
    companyId: any

    constructor(private route: ActivatedRoute, private processService: ProcessService,
                private modalService: BsModalService, private router: Router, private userService: UserService) {
    }

    ngOnInit() {


        this.userService.getCurrentUser().subscribe(user => {
            if (this.route.snapshot.paramMap.get('id')) {
                this.route.paramMap.pipe(
                    switchMap((params: ParamMap) => this.processService.getProcess(params.get('id'))))
                    .subscribe(process => {
                        this.process = process;
                        this.processService.getSteps(localStorage.getItem('currentControlType')).subscribe(steps => {
                            this.steps = steps;
                            this.companyId = this.route.snapshot.paramMap.get('companyId');
                            this.activeTaskKey = this.route.snapshot.paramMap.get('task');
                            let notifTask;
                            if (this.activeTaskKey) {
                                notifTask = this.process.tasks.find(ts => ts.key === this.route.snapshot.paramMap.get('task'));
                            }
                            if (!notifTask && this.steps.length > 0) {
                                this.active = this.steps[0].key;
                            } else {
                                this.active = this.steps.find(st => st.key === notifTask.step).key;
                            }
                        });
                    });
            }
            this.user = user;
        });


    }

    reloadProcess(processId) {
        this.processService.getProcess(processId).subscribe(result => {
            this.process = result;
        });
    }

    getTasks(key) {
        return this.process.tasks.filter(elem => elem.step === key);
    }

    changeTab(event) {
        this.activeTaskKey = null;
    }

    openModal(template: TemplateRef<any>, key) {
        this.modalRef = this.modalService.show(template, {class: 'modal-sm', initialState: key});
    }

    confirm(): void {
        this.deleteControl(this.modalService.config.initialState);
        this.modalRef.hide();
    }

    deleteControl(id) {
        this.processService.deleteControl(id).subscribe(res => {
            this.router.navigateByUrl('/control-module');
        });
    }
}
