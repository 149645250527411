import {Directive, Input, ViewContainerRef} from '@angular/core';
import {Service} from "../entities/service";

@Directive({
  selector: '[service-type-form-fields]',
})
export class ServiceTypeDirective {
  @Input("service") service: Service;
  constructor(public viewContainerRef: ViewContainerRef) { }
}
