import { Component, Input, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { PagerDataObject } from 'src/app/entities/pagerDataObject';
import { NotificationService } from 'src/app/services/notification.service';
import { SharedService } from 'src/app/services/sharedService';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css']
})
export class NotificationsComponent implements OnInit {
  metaInfo: string[];
  columns: Array<any>;
  data: PagerDataObject;
  @Input()
  disableHeader = false;
  subscription: any;
  private dataSub$ = new Subject<any>();
  query: String;

  constructor(private notificationsService: NotificationService, private sharedService: SharedService) {
    
  }

  ngOnInit() {
    this.subscription = this.dataSub$.pipe(switchMap(filter => {
      return this.notificationsService.getData(filter);
    }))
      .subscribe(data => {
        this.data = data;
        this.data.records.forEach(element => {
          element['id']=element['_id']
        });
      });
    this.metaInfo = this.notificationsService.getMetaInfo();
    this.columns = this.notificationsService.getColumns();
  }

  onGridQuery(query) {
    this.query = query
    this.dataSub$.next(query);
  }

}
