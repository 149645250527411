import {Injectable} from '@angular/core';
import {Service} from "../entities/service";
import {SharedService} from "./sharedService";


import {PagerDataObject} from "../entities/pagerDataObject";
import {catchError, tap} from 'rxjs/operators';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import { Subject, Observable } from 'rxjs';
import { User } from '../entities/user';


const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};

@Injectable()
export class UserService {

  private serviceUpdate = new Subject<User>();
  serviceUpdated$ = this.serviceUpdate.asObservable();

  constructor(private http: HttpClient, private sharedService: SharedService) {
  }

  getData(query: string): Observable<PagerDataObject> {
    let users$ = this.http.get<PagerDataObject>(this.sharedService.apiUrl('api/user', "/" + query))
    return users$;
  }

  getTotalUserList(): Observable<User[]> {
    const users$ = this.http.get<User[]>(this.sharedService.apiUrl('api/user'))
    return users$;
  }

  getTotalUserFlatList(): Observable<User[]> {
    const users$ = this.http.get<User[]>(this.sharedService.apiUrl('api/user/list'));
    return users$;
  }

  getTotalUserListByRole(query: string): Observable<User[]> {
    let users$ = this.http.get<User[]>(this.sharedService.apiUrl('api/user','list?role='+query))
    return users$;
  }

  getUser(id: string): Observable<User> {
    let user$ = this.http.get<User>(this.sharedService.apiUrl('api/user/' + id))
    return user$;
  }

  getUserByUsername(username: string): Observable<User> {
    let user$ = this.http.get<User>(this.sharedService.apiUrl('api/user/byUsername')+username)
    return user$;
  }

  getCurrentUser(): Observable<User> {
    let user$ = this.http.get<User>(this.sharedService.apiUrl('api/user/current'))
    return user$;
  }

  add(user: User): Observable<User> {
    return this.http.post<User>(this.sharedService.apiUrl('api/user/add'), user, httpOptions).pipe(
      tap((user: User) => this.sharedService.log(`added user w/ id=${user.id}`)),
      catchError(this.sharedService.handleError<User>('addUser'))
    );
  }

  delete(user: User): Observable<Service> {
    return this.http.delete<any>(this.sharedService.apiUrl('api/user/' + user.id)).pipe(
      tap((resource: any) => this.sharedService.log(`deactivated ${user} w/ id=${resource.id}`)),
      catchError(this.sharedService.handleError<any>('deactivate'))
    );
  }

  

  updateService(User: User) {
    this.serviceUpdate.next(User);
  }

  columns: Array<any> = [
    {key: 'view', style: {'flex': '0 0 100px', 'width': '1%'}, button: true},
    {name: 'Username', tag: 'USER.USERNAME', key: 'username', filtering: true, sorting: true, sortingOrder: 'asc'},
    {name: 'Name', tag: 'USER.FIRSTNAME', key: 'name', filtering: true, sorting: true},
    {name: 'Surname', tag: 'USER.LASTNAME', key: 'surname', filtering: true, sorting: true},
    {name: 'Status', tag: 'USER.STATUS', key: 'status', filtering: true, sorting: true, values: ['ENABLED','DISABLED']},
    {name: 'Roles', tag: 'USER.ROLES', key: 'roles', filtering: true, sorting: true}
  ];

  getMetaInfo(): string[] {
    return ['username', 'name', 'surname','status', 'roles'];
  }

  getColumns(): string[] {
    return this.columns;
  }
}
