import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgbCalendar, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-calendar',
    templateUrl: './calendar.component.html',
    styleUrls: ['./calendar.component.css']
})
export class CalendarComponent implements OnInit {
    @Input() elements;
    @Input() activeElements;
    @Input() calendarElem;
    model: NgbDateStruct;
    @Output() onDateSet = new EventEmitter<any>();
    @Input() inTable = false;
    constructor(private calendar: NgbCalendar) {

    }

    ngOnInit() {
        if (this.calendarElem.value){
            const date = new Date(this.calendarElem.value);
            if (date) {
                this.model = {year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate()};
            }
        }
        
    }
    onChange() {

        if ((typeof this.model === 'object' || typeof this.model === 'function') && (this.model !== null)) {
            const obj =  new Date(this.model.year, this.model.month - 1, this.model.day);
            this.model = {year: obj.getFullYear(), month: obj.getMonth() + 1, day: obj.getDate()};
            this.onDateSet.emit(obj.toISOString());
        }
    }
}
