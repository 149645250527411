export class User {
    id: number;
    username: string;
    name: string;
    surname: string;
    roles = [];
    status: string;
    rattachementList = [];
    controlTypesList = [];
  }
  